

<script>
import { useAuthStore } from '@/store/auth';
import { onMounted, onUnmounted, watch } from 'vue';

export default {
    name: 'TokenRefresher',

    setup() {
        const authStore = useAuthStore();
        let refreshTimer = null;

        const REFRESH_MARGIN = 5 * 60 * 1000;

        const scheduleTokenRefresh = () => {
            if (!authStore.token || !authStore.expiresIn) return;

            if (refreshTimer) clearTimeout(refreshTimer);

            const tokenExpiresAt = authStore.expiresIn * 1000;
            const refreshAt = tokenExpiresAt - REFRESH_MARGIN;

            refreshTimer = setTimeout(async () => {
                try {
                    const success = await authStore.refreshToken();
                    if (success) {
                        scheduleTokenRefresh();
                    }
                } catch (error) {
                    console.error('Failed to refresh token:', error);
                }
            }, refreshAt);
        };

        onMounted(() => {
            scheduleTokenRefresh();
        });

        watch(
            [() => authStore.token, () => authStore.expiresIn],
            () => {
                scheduleTokenRefresh();
            }
        );

        // Очистка при размонтировании
        onUnmounted(() => {
            if (refreshTimer) {
                clearTimeout(refreshTimer);
            }
        });
    }
};
</script>