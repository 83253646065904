<template>
  <div class="section-advantages">
    <div class="site-width">
      <div class="title-section">
        <h2>Наши преимущества</h2>
        Мы стараемся предоставлять наивысшее качество сервиса, чтобы наши клиенты смогли быстро получать услугу и
        остались довольны!
      </div>

      <div class="flex">
        <div class="item">
          <div class="icon icon-fast"><img alt="Быстрое пополнение" /></div>
          <div class="name">Быстрое пополнение</div>
          <div class="desc">
            <p>Среднее время зачисления платежа: 7 секунд</p>
            <p>Система автоматически пополняет ваш аккаунт</p>
          </div>
        </div>

        <div class="item">
          <div class="icon icon-commission"><img alt="Низкая комиссия" /></div>
          <div class="name">Низкая комиссия</div>
          <div class="desc">
            <p>Наш сервис предлагает одну из самых низких комиссий без скрытых платежей</p>
          </div>
        </div>

        <div class="item">
          <div class="icon icon-happy"><img alt="Довольные клиенты" /></div>
          <div class="name">Довольные клиенты</div>
          <div class="desc">
            <p>Вы можете прочитать реальные отзывы наших клиентов. Мы гордимся своим сервисом!</p>
          </div>
        </div>

        <div class="item">
          <div class="icon icon-support"><img  alt="Техническая поддержка 24/7" /></div>
          <div class="name">Техническая поддержка 24/7</div>
          <div class="desc">
            <p>Наши операторы всегда на связи!</p>
            <p>Возник вопрос? Вы можете написать нам в <a href="javascript:jivo_api.open()">чате на сайте</a> или в <a
                href="https://vk.com/steammoney" target="_blank">группе ВК</a></p>
          </div>
        </div>
      </div>

      <a href="#" class="btn">Пополнить Steam</a>
    </div>
  </div>
</template>
