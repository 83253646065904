<template>
    <div class="flex-discount">
        <div class="line-green" :style="{ width: discountLineWidth + '%' }"></div>
        <div v-for="(level, index) in discountLevels" :key="index" class="col-discount"
            :class="{ active: currentDiscountLevel >= index, step: index === 0 }"
            @click="handleAmountChange(level.amount)">
            <span>{{ level.percent > 0 ? `-${level.percent}%` : '0%' }}</span>
            <div class="line"></div>
            <span>от {{ level.amount }} {{ currency }}</span>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        amount: {
            type: Number,
            default: 0
        },
        currency: {
            type: String,
            default: '₽'
        },
        settings: {
            type: Object,
            required: false,
        }
    },

    computed: {
        currencyType() {
            return this.currency === '₽' ? 'rub' : 'kzt';
        },

        discountLevels() {
            const limits = this.settings?.discountLimits?.[this.currencyType];
            if (!limits) return this.getDefaultLevels();

            return [
                { amount: limits.min, percent: 0 },
                { amount: limits.middle, percent: limits.middle_percent },
                { amount: limits.max, percent: limits.max_percent }
            ];
        },

        currentDiscountLevel() {
            const amount = this.amount || 0;
            const levels = this.discountLevels;

            for (let i = levels.length - 1; i >= 0; i--) {
                if (amount >= levels[i].amount) return i;
            }
            return -1;
        },

        discountLineWidth() {
            const amount = this.amount || 0;
            const maxAmount = this.settings?.discountLimits?.[this.currencyType]?.max || 15000;
            const levels = this.discountLevels;

            if (amount < levels[0].amount) return 0;

            const widthLevels = levels.map((level, index) => ({
                amount: level.amount,
                width: index * 33
            }));

            for (let i = 0; i < widthLevels.length; i++) {
                if (amount === widthLevels[i].amount) {
                    return widthLevels[i].width;
                }
            }

            if (amount >= levels[levels.length - 1].amount) {
                const extraProgress = Math.min(
                    (amount - levels[levels.length - 1].amount) /
                    (maxAmount - levels[levels.length - 1].amount),
                    1
                );
                return Math.min(66 + (extraProgress * 34), 100); // Ограничиваем значение 100%
            }

            for (let i = 0; i < widthLevels.length - 1; i++) {
                if (amount > widthLevels[i].amount && amount < widthLevels[i + 1].amount) {
                    const progress = (amount - widthLevels[i].amount) /
                        (widthLevels[i + 1].amount - widthLevels[i].amount);
                    return widthLevels[i].width + (progress * 33);
                }
            }

            return 0;
        }
    },

    methods: {
        getDefaultLevels() {
            return [
                { amount: 100, percent: 0 },
                { amount: 2000, percent: 1 },
                { amount: 6000, percent: 3 }
            ];
        },

        handleAmountChange(newAmount) {
            this.$emit('update:amount', newAmount);
            this.$parent.setAmount?.(newAmount);
            this.$parent.calculate?.();
        }
    }
};
</script>
