import { defineStore } from 'pinia';

export const useUIStore = defineStore('ui', {
    state: () => ({
        theme: localStorage.getItem('theme') || 'dark',
        menuOpen: false,
        userMenuOpen: false,
        loginModalOpen: false,
    }),

    getters: {
        isLightTheme: (state) => state.theme === 'light',
    },

    actions: {
        toggleTheme() {
            this.theme = this.theme === 'dark' ? 'light' : 'dark';
            localStorage.setItem('theme', this.theme);
            this.applyTheme();
        },

        initTheme() {
            const savedTheme = localStorage.getItem('theme');
            if (savedTheme) {
                this.theme = savedTheme;
            }
            this.applyTheme();
        },

        applyTheme() {
            if (this.theme === 'light') {
                document.body.classList.add('light');
            } else {
                document.body.classList.remove('light');
            }
        }
    },
});