<template>
    <div v-if="show" class="modal-overlay" @click.self="onClose" @keydown.esc="onClose" tabindex="-1">
        <section class="success-payment">
            <div>
                <h1 class="payment-title">Ваш заказ успешно оплачен!</h1>
                <button class="close-button" @click="onClose">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M18 6L6 18M6 6L18 18" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                            stroke-linejoin="round" />
                    </svg>
                </button>
            </div>

            <p class="payment-description">
                В ближайшее время платеж будет обработан и зачислен на ваш STEAM аккаунт.
            </p>

            <div class="payment-code-container" v-if="displayOrderId">
                <div class="payment-code-wrapper">
                    <label class="payment-code-label">Код оплаты</label>
                    <div class="payment-code-display">
                        <span class="payment-code-text">{{ displayOrderId }}</span>
                        <button class="copy-button" @click="copyOrderId">
                            {{ copyButtonText }}
                        </button>
                    </div>
                </div>
            </div>

            <div class="payment-instructions">
                <ul>
                    <li>
                        Если по каким-то причинам зачисление не произошло, напишите в нашу поддержку и укажите
                        детали платежа.
                    </li>
                    <li>
                        Если платеж успешно зачислен, оставьте пожалуйста
                        <a href="https://vk.com/steammoney" target="_blank" class="highlight-text">
                            отзыв в нашей группе ВКонтакте
                        </a>
                    </li>
                </ul>
            </div>
        </section>
    </div>
</template>

<script>
export default {
    props: {
        show: {
            type: Boolean,
            required: true
        },
        orderId: {
            type: String,
            required: true
        }
    },

    data() {
        return {
            copyButtonText: 'Скопировать',
            copyTimeout: null
        }
    },

    computed: {
        displayOrderId() {
            return this.orderId || localStorage.getItem('currentOrderId')
        }
    },

    watch: {
        show(newVal) {
            if (newVal && this.orderId) {
                localStorage.setItem('currentOrderId', this.orderId)
            }
        }
    },

    mounted() {
        document.addEventListener('keydown', this.handleKeyDown)
        if (this.orderId) {
            localStorage.setItem('currentOrderId', this.orderId)
        }
    },

    beforeUnmount() {
        document.removeEventListener('keydown', this.handleKeyDown)
        if (this.copyTimeout) {
            clearTimeout(this.copyTimeout)
        }
    },

    methods: {
        async copyOrderId() {
            try {
                await navigator.clipboard.writeText(this.displayOrderId)
                this.copyButtonText = 'Скопировано'

                if (this.copyTimeout) {
                    clearTimeout(this.copyTimeout)
                }

                this.copyTimeout = setTimeout(() => {
                    this.copyButtonText = 'Скопировать'
                }, 2000)
            } catch (error) {
                console.error('Failed to copy:', error)
            }
        },

        onClose() {
            if (this.copyTimeout) {
                clearTimeout(this.copyTimeout)
            }
            localStorage.removeItem('currentOrderId')
            this.$emit('close')
        },

        handleKeyDown(e) {
            if (this.show && e.key === 'Escape') {
                this.onClose()
            }
        }
    }
}
</script>

<style scoped>
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(43, 43, 43, 0.75);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
}

.success-payment {
    border-radius: 16px;
    background-color: #131313;
    position: relative;
    display: flex;
    max-width: 596px;
    width: 100%;
    flex-direction: column;
    font-family: Inter, sans-serif;
    font-weight: 400;
    justify-content: start;
    padding: 40px 32px;
    margin: 20px;
}

.body.dark .success-payment{
    background-color: #131313;
}

.payment-title {
    color: #edeeee;
        font-size: 24px;
        font-weight: 500;
        line-height: 1;
        text-align: center;
        margin: 0 0 20px;
}

.close-button {
    position: absolute;
    right: 16px;
    top: 16px;
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;
    width: 24px;
    height: 24px;
    /* mask-image: url('/new/images/close_MD.svg'); */
    mask-size: cover;
    /* background-color: #fff; */
}

.close-button svg {
    color: #fff;
}

body.light .close-button svg {
    color: #131313;
}
body.light .close-button {
    /* background-color: #131313; */
}

.close-button img {
    width: 100%;
    height: 100%;
    top: 16px;
}

.payment-description {
color: var(--Text-Secondary, rgba(237, 238, 238, 0.48));        font-size: 14px;
        line-height: 20px;
        text-align: center;
        z-index: 0;
        margin-top: 20px;
}

.payment-code-container {
    border-radius: 12px;
    background-color: rgba(36, 255, 111, 0.04);
    width: 100%;
    padding: 16px;
    margin-bottom: 20px;
}

.payment-code-wrapper {
    display: flex;
    flex-direction: column;
}

.payment-code-label {
    color: rgba(237, 238, 238, 0.48);
    font-size: 12px;
    line-height: 2;
    margin-bottom: 4px;
}

.payment-code-display {
    border-radius: 8px;
    background-color: rgba(237, 238, 238, 0.04);
    display: flex;
    min-height: 40px;
    align-items: center;
    color: #edeeee;
    justify-content: space-between;
    padding: 6px 6px 6px 16px;
    border: 1px solid rgba(237, 238, 238, 0.04);
}

.payment-code-text {
    font-size: 14px;
    line-height: 24px;
    flex: 1;
}

.copy-button {
    border-radius: 6px;
    background-color: rgba(237, 238, 238, 0.04);
    font-size: 12px;
    line-height: 2;
    padding: 4px 8px;
    border: 1px solid rgba(237, 238, 238, 0.04);
    color: #edeeee;
    cursor: pointer;
}

.copy-button:hover {
    background-color: rgba(19, 19, 19, 0.1);
}

.payment-instructions {
color: var(--Text-Secondary, rgba(237, 238, 238, 0.48));    font-size: 14px;
    line-height: 20px;
    margin: 20px 0 0;
}

.payment-instructions ul {
    padding: 0;
    margin: 0;
}

.payment-instructions li {
    margin-bottom: 16px;
}

.payment-instructions li:last-child {
    margin-bottom: 0;
}

.highlight-text {
    color: #0db740;
    text-decoration: none;
}

.highlight-text:hover {
    text-decoration: underline;
}

.auth-buttons {
    margin: 20px 0 0;
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.auth-button {
    border-radius: 8px;
    background-color: rgba(19, 19, 19, 0.06);
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
    padding: 12px 16px;
    border: none;
    cursor: pointer;
    font-size: 16px;
    color: #131313;
    transition: all 0.2s;
}

.auth-button:hover {
    background-color: rgba(19, 19, 19, 0.1);
}

.auth-icon {
    width: 28px;
    height: 28px;
}

.auth-benefits {
    color: #0db740;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    margin: 20px 0 0;
}

/* light */

body.light .success-payment{
    background-color: #ffffff;
}

body.light .payment-title{
    color: #131313;
}

body.light .payment-description{
    color: rgba(19, 19, 19, 0.72);
}

body.light .payment-code-container{
    background-color: rgba(0, 190, 58, 0.12);
}

body.light .payment-code-label{
    color: rgba(19, 19, 19, 0.48);
}

body.light .payment-code-display{
    background-color: rgba(19, 19, 19, 0.06);
    border: 1px solid rgba(19, 19, 19, 0.06);
}

body.light .payment-code-text{
    color: #131313;
}

body.light .copy-button{
    background-color: rgba(19, 19, 19, 0.06);
    border: 1px solid rgba(19, 19, 19, 0.06);
    color: #131313;
}

body.light .payment-instructions{
    color: rgba(19, 19, 19, 0.72);
}

body.light .highlight-text{
    color: #0db740;
}

@media (max-width: 640px) {
    .success-payment {
        padding: 32px 20px;
        margin: 16px;
    }

    .payment-title {
        font-size: 20px;
    }

    .payment-code-display {
        flex-direction: column;
        align-items: stretch;
        gap: 8px;
    }

    .payment-code-text {
        word-break: break-all;
    }

    .auth-button {
        font-size: 14px;
    }
}
</style>