<template>
    <div id="top" v-show="showButton" @click="scrollToTop" class="scroll-to-top">
    </div>
</template>

<script>
export default {
    name: 'ScrollToTop',
    data() {
        return {
            showButton: false
        }
    },
    mounted() {
        window.addEventListener('scroll', this.toggleVisibility)
    },
    beforeUnmount() {
        window.removeEventListener('scroll', this.toggleVisibility)
    },
    methods: {
        toggleVisibility() {
            this.showButton = window.scrollY > 300
        },
        scrollToTop() {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            })
        }
    }
}
</script>

<style scoped>


.scroll-to-top:hover {
    opacity: 0.8;
}

.scroll-to-top[style*="display: block"] {
    opacity: 1;
    pointer-events: auto;
}

</style>