<template>
  <div class="section-main">
    <div class="site-width">
      <PaymentForm />
      <div class="sidebar">
        <div class="fire">
          {{ settings?.commission_rate }}% — самая низкая комиссия по рынку
          <div class="question">
            <div class="text">
              Самая низкая комиссия <br />
              доступна при применении промокода
            </div>
          </div>
        </div>

        <div class="tabs">
          <ul class="tabs__caption">
            <li class="active">Инструкция</li>
            <li>Вопрос / Ответ</li>
          </ul>

          <div class="tabs__content active">
            <div class="name">Как пополнить кошелек Steam?</div>
            <ol>
              <li>Введите свой логин STEAM <a href="" class="lost" @click.prevent="openModal('login-help')">Узнать
                  логин</a></li>
              <li>Укажите сумму зачисления на аккаунт</li>
              <li>Оплатите удобным для вас способом</li>
              <li>Поздравляем, вы пополнили баланс STEAM!</li>
            </ol>
          </div>

          <div class="tabs__content">
            <div class="item-question">
              <div class="question-name">Как узнать логин Аккаунта?</div>
              <div class=" desc">
                <div class="desc active" style="display: block;">
                  <a href="https://store.steampowered.com/account/" target="_blank">Перейдите по ссылке</a> и
                  вы увидите ваш логин (см. скриншот) <br>
                  <img src="/images/nickSteam.webp"><br>
                  Проще говоря нужен логин, по которому вы авторизуетесь в своём STEAM аккаунте. Это одно и
                  тоже :)<br>
                  Внимание! Не спутайте логин и никнейм, это очень важно. Никнейм профиля можно менять в
                  STEAM, логин
                  нет!
                </div>
              </div>
            </div>

            <div class=" item-question">
              <div class="question-name">Я неправильно указал свой логин STEAM. Что делать?</div>
              <div class=" desc answer">
                Жаль, что так получилось, но обязательно напишите в нашу поддержку, укажите детали платежа (дату,
                время,
                сумму, неправильно указанный логин и ваш правильный логин)

                Если "неправильного" логина не существует в STEAM, то специалисты нашей поддержки помогут вам!

                Чат с поддержкой в правом нижнем углу нашего сайта :)
              </div>
            </div>

            <div class="item-question">
              <div class="question-name">Можно ли пополнить баланс аккаунта с красной табличкой?</div>
              <div class=" desc answer">
                К сожалению, баланс ограниченных красной табличкой STEAM аккаунтов пополнить нельзя.
              </div>
            </div>

            <div class="item-question">
              <div class="question-name">Какие аккаунты нельзя пополнить?</div>
              <div class=" desc answer">
                Пополнение по логину работает на ТОЛЬКО на steam аккаунты, где валюта: рубли и регион Россия,
                Казахстанский тенге, Белорусский рубль, доллары США, Узбекские сумы. Это обязательное требование и
                Steam
                аккаунты с другими валютами пополнить нельзя.

                Нельзя пополнить баланс ограниченных красной табличкой аккаунтов, а также аккаунты, которые
                зарегистрированы на территории Республики Крым, ДНР и ЛНР.
              </div>
            </div>

            <div class="item-question">
              <div class="question-name">Как связаться с поддержкой?</div>
              <div class=" desc answer">
                Если у вас остались вопросы или есть предложения, напишите нам в поддержку! <br />
                Чат с поддержкой в правом нижнем углу нашего сайта или можете нажать <a
                  href="javascript:jivo_api.open()">
                  сюда для открытия чата</a>
              </div>
            </div>
            <div class="item-question">
              <div class="question-name">Как пополнить деньги на <br /> новый steam аккаунт чтобы была валюта рубли?
              </div>
              <div class=" desc answer">
                Чтобы осуществить пополнение нового стим аккаунта и оставить валюту кошелька - рубли ₽, пользователю
                нужно
                добавить в steam библиотеку бесплатную игру, например PUBG, подождать в течение 10 минут и
                осуществить
                пополнение аккаунта. Таким способом поступит баланс в рублях. Это работает при учете того, что ваш
                текущий
                регион Россия и не используется VPN других стран.

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <Advantages />
  <div class="section-3" id="faq">
    <div class="site-width">
      <div class="col-left">
        <div class="name">Вопрос / Ответ</div>
        <div class="desc">
          Ознакомьтесь с ответами на самые частые вопросы, которые возникают у пользователей нашего сервиса. Если
          вашего
          вопроса нет в списке, свяжитесь с оператором в чате или любым другим удобным вам способом!
        </div>

        <div class="item-question">
          <div class="question-name">Как узнать логин Аккаунта?</div>
          <div class="answer">
            <div class="desc active" style="display: block;">
              <a href="https://store.steampowered.com/account/" target="_blank">Перейдите по ссылке</a> и
              вы увидите ваш логин (см. скриншот) <br>
              <img src="/images/nickSteam.webp"><br>
              Проще говоря нужен логин, по которому вы авторизуетесь в своём STEAM аккаунте. Это одно и
              тоже :)<br>
              Внимание! Не спутайте логин и никнейм, это очень важно. Никнейм профиля можно менять в
              STEAM, логин
              нет!
            </div>
          </div>
        </div>

        <div class=" item-question">
          <div class="question-name">Я неправильно указал свой логин STEAM. Что делать?</div>
          <div class=" answer desc">
            Жаль, что так получилось, но обязательно напишите в нашу поддержку, укажите детали платежа (дату, время,
            сумму, неправильно указанный логин и ваш правильный логин) <br />

            Если "неправильного" логина не существует в STEAM, то специалисты нашей поддержки помогут вам!<br />

            Чат с поддержкой в правом нижнем углу нашего сайта :)
          </div>
        </div>

        <div class="item-question">
          <div class="question-name">Можно ли пополнить баланс аккаунта с красной табличкой?</div>
          <div class="answer desc">
            К сожалению, баланс ограниченных красной табличкой STEAM аккаунтов пополнить нельзя.
          </div>
        </div>

        <div class="item-question">
          <div class="question-name">Какие аккаунты нельзя пополнить?</div>
          <div class="answer desc">
            Пополнение по логину работает на ТОЛЬКО на steam аккаунты, где валюта: рубли и регион Россия,
            Казахстанский тенге, Белорусский рубль, доллары США, Узбекские сумы. Это обязательное требование и Steam
            аккаунты с другими валютами пополнить нельзя. <br />
            <br>
            Нельзя пополнить баланс ограниченных красной табличкой аккаунтов, а также аккаунты, которые
            зарегистрированы на территории Республики Крым, ДНР и ЛНР.
          </div>
        </div>

        <div class="item-question">
          <div class="question-name">Как связаться с поддержкой?</div>
          <div class="answer desc">
            Если у вас остались вопросы или есть предложения, напишите нам в поддержку! <br />
            Чат с поддержкой в правом нижнем углу нашего сайта или можете нажать <a href="javascript:jivo_api.open()">
              сюда для открытия чата</a>
          </div>
        </div>
        <div class="item-question">
          <div class="question-name">Как пополнить деньги на новый steam аккаунт, чтобы была валюта рубли?</div>
          <div class="answer desc">
            Чтобы осуществить пополнение нового стим аккаунта и оставить валюту кошелька - рубли ₽, пользователю
            нужно
            добавить в steam библиотеку бесплатную игру, например PUBG, подождать в течение 10 минут и осуществить
            пополнение аккаунта. Таким способом поступит баланс в рублях. Это работает при учете того, что ваш
            текущий
            регион Россия и не используется VPN других стран.

          </div>
        </div>
      </div>

      <div class="col-right">
        <div class="name">Как это работает</div>
        <ol>
          <li>Введите свой логин STEAM <a href="" class="lost" @click.prevent="openModal('login-help')">Узнать
              логин</a></li>
          <li>Укажите сумму зачисления на аккаунт</li>
          <li>Оплатите удобным для вас способом</li>
          <li>Поздравляем, вы пополнили баланс STEAM!</li>
        </ol>
      </div>
    </div>
  </div>
  <UniversalPopup :isVisible="modals['login-help']" title="Где взять логин" @close="closeModal('login-help')">
    <div class="block-popup">
      <div class="text">
        <a href="https://store.steampowered.com/account/" target="_blank">Перейдите по ссылке</a>
        и вы увидите ваш логин<br>

        <div class="screenshot">
          Скриншот:<br>
          <img src="/images/nickSteam.webp" alt=" Как узнать логин"><br>
        </div>

        <p>
          Проще говоря нужен логин, по которому вы авторизуетесь в своём STEAM аккаунте.
          Это одно и тоже :)
        </p>
      </div>
    </div>
  </UniversalPopup>

  <div class="section-4" id="clients">
    <div class="site-width">
      <div class="title-section" id="reviews">
        <h2>Отзывы о нашем сервисе</h2>
        Мы ценим, что наши клиенты оставляют отзывы о нашем сервисе и в большинстве случаев довольны нашей работой!
      </div>

      <div class="flex">
        <div class="item">
          <div class="rating">
            <img src="/images/star.svg" alt="" />
            <img src="/images/star-black.svg" alt="" />
          </div>
          <div class="photo"><img src="/new/images/max.webp" /></div>
          <div class="name"><a class="vk" target="_blank"></a> Мaксим Мaлoмyж</div>
          <div class="desc">Все четко и быстро. <br> Клиентоориентированность на 5 баллов</div>
        </div>

        <div class="item">
          <div class="rating">
            <img src="/images/star.svg" alt="" />
            <img src="/images/star-black.svg" alt="" />
          </div>
          <div class="photo"><img src="/new/images/ruslan.webp" /></div>
          <div class="name"><a class="vk" target="_blank"></a>Руслан Кaутaрoв</div>
          <div class="desc">Несколько раз пополнял, без проблем все пришло!</div>
        </div>

        <div class="item">
          <div class="rating">
            <img src="/images/star.svg" alt="" />
            <img src="/images/star-black.svg" alt="" />
          </div>
          <div class="photo"><img src="/new/images/vsevolod.webp" /></div>
          <div class="name"><a class="vk" target="_blank"></a>Всеволод Николенко</div>
          <div class="desc">Все как всегда быстро и четко</div>
        </div>
      </div>

      <a href="https://vk.com/topic-17007770_48941290" target="_blank" class="all">Смотреть еще отзывы</a>
      <a href="#" class="order">Пополнить Steam</a>
    </div>
  </div>


  <div class="section-5">
    <div class="site-width">
      <div class="item">
        <div class="icon"><img src="/images/section5-icon1.svg" alt="" /></div>
        <div class="name"><span>301 402</span>Пополнений</div>
      </div>

      <a href="https://vk.com/topic-17007770_48941290" target="_blank" class="item">
        <div class="icon"><img src="/images/section5-icon2.svg" alt="" /></div>
        <div class="name"><span>100+</span>Отзывов</div>
      </a>

      <div class="item">
        <div class="icon"><img src="/images/section5-icon3.svg" alt="" /></div>
        <div class="name"><span>4 сек</span>Время пополнения</div>
      </div>
    </div>
  </div>
  <ScrollToTop />
</template>

<script>
import PaymentForm from '@/components/PaymentForm/PaymentForm.vue';
import Advantages from '@/components/Advantages.vue';
import UniversalPopup from '@/components/Popup/PopupDefault.vue';
import settingsService from '@/service/api/service';
import ScrollToTop from '@/components/ScrollTop/ScrollTop.vue';



export default {
  components: { Advantages, PaymentForm, UniversalPopup, ScrollToTop },
  data() {
    return {
      settings: null,
      modals: {
        'login-help': false  // добавляем новый модальный попап
      }
    };
  },
  async mounted() {
    try {
      await settingsService.fetch();
      this.settings = settingsService.get();
      // console.log('Settings loaded:', this.settings);
    } catch (error) {
      console.error('Failed to load settings:', error);
      this.settings = null;
    }


    $('.tabs__caption').on('click', 'li:not(.active)', function () {
      $(this)
        .addClass('active')
        .siblings()
        .removeClass('active')
        .closest('.tabs')
        .find('.tabs__content')
        .removeClass('active')
        .eq($(this).index())
        .addClass('active');
    });

    $('.section-main .question .text').each(function (i) {
      let width = $(this).width();
      let widthContainer = $('.site-width').width();
      let position = $(this).parent().position();
      if (widthContainer - position.left - width < 0) $(this).addClass('align_right');
    });

    // $('.section-main .label.summ input').on('click', function () {
    //   $(this).val('');
    // });

    // $('.section-main .label.summ input').on('change', function () {
    //   let $me = $(this);
    //   $me.val($me.val() + ' ₽');
    // });


    const questionNames = document.querySelectorAll('.question-name');
    questionNames.forEach((questionName) => {
      questionName.addEventListener('click', () => {
        const parentQuestion = questionName.closest('.item-question');
        const answer = questionName.nextElementSibling;

        document.querySelectorAll('.item-question.active').forEach(openQuestion => {
          if (openQuestion !== parentQuestion) {
            openQuestion.classList.remove('active');
            openQuestion.querySelector('.desc').style.display = 'none';
          }
        });

        parentQuestion.classList.toggle('active');

        if (parentQuestion.classList.contains('active')) {
          answer.style.display = 'block';
        } else {
          answer.style.display = 'none';
        }
      });
    });

    $('.promowindow').click(function () {
      $('#promo-popup').show();
    });

    $('.header .enter, .section-main .enter-buy').click(function () {
      $('#login-popup').show();
    });

    $('.code-pay .copy').click(function () {
      var $temp = $('<input>');
      $('body').append($temp);
      $temp.val($('.code-pay .code-input').val()).select();
      document.execCommand('copy');
      $temp.remove();
      alert('Скопировано');
    });

    $('.topline .exit').click(function () {
      $(this).parents('.topline').slideToggle();
    });


    $('.select-mobile-country .select').click(function () {
      $('.section-main .container .tabs__caption').toggleClass('active');
    });

    $('.header .light').on('click', function () {
      $('.header .light').hide();
      $('.header .dark').show();
      $('body').addClass('light');
      sessionStorage.setItem('class', 'light');
    });

    $('.header .dark').on('click', function () {
      $('.header .light').show();
      $('.header .dark').hide();
      $('body').removeClass('light');
      sessionStorage.removeItem('class', 'light');
    });

    if (sessionStorage.getItem('class') !== null) {
      $('.header .light').hide();
      $('.header .dark').show();
      $('body').addClass('light');
    }

  },
  methods: {
    openModal(name) {

      this.modals[name] = true;
      // console.log(name);
    },
    closeModal(name) {
      this.modals[name] = false;
    }
  },
};
</script>
