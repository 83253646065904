<template>
  <div class="header" :key="key">
    <div class="site-width">
      <div class="logo">
        <router-link to="/"></router-link>
      </div>

      <div class="info">
        <div class="item">
          <img :src="`/images/header-icon1${$theme.isLightTheme ? '-light' : ''}.svg`" alt="" />
          <div>
            <div><span class="counter">{{ formatNumber(settings?.order_count) }}</span>Пополнений</div>
          </div>
        </div>
        <a href="https://vk.com/topic-17007770_48941290" target="_blank" class="item">
          <img :src="`/images/header-icon2${$theme.isLightTheme ? '-light' : ''}.svg`" alt="" />
          <div><span class="counter">100+</span>Отзывов</div>
        </a>
        <div class="item">
          <img :src="`/images/header-icon3${$theme.isLightTheme ? '-light' : ''}.svg`" alt="" />
          <div><span class="counter">4 сек</span>Время пополнения</div>
        </div>
      </div>

      <div class="menu" ref="menu" :class="{ active: isMenuOpen }">
        <div class="top">
          <div class="light" v-show="!$theme.isLightTheme"></div>
          <div class="dark" v-show="$theme.isLightTheme"></div>
          <div class="close" @click="closeMenu"></div>
        </div>
        <ul>
          <li><a href="#reviews" @click.prevent="scrollToSection('reviews')">Отзывы</a></li>
          <li><a href="#faq" @click.prevent="scrollToSection('faq')">Вопрос / Ответ</a></li>
          <li><a href="javascript:jivo_api.open()" @click="closeMenu">Поддержка</a></li>
        </ul>
      </div>

      <SocialLinks />

      <div class="setting light" v-show="!$theme.isLightTheme" @click="$theme.toggleTheme"></div>
      <div class="setting dark" v-show="$theme.isLightTheme" @click="$theme.toggleTheme"></div>

      <div v-if="!isAuthenticated" class="enter" @click="openLoginModal" :class="{ 'disabled': isLoading }">
        Войти и получить скидку
      </div>


      <template v-else>
        <div class="login-panel">
          <div class="icon">
            <img src="/images/stm-coin.svg" class="theme-icon" alt="Монета" />
          </div>
          <div class="balance">{{ formatNumber(userBalance) }}</div>
          <div class="refresh" @click="openGeneratePromo">
            <img class="refresh-img" alt="">
          </div>
          <div class="photo" v-if="userAvatar">
            <img :src="userAvatar" :alt="userName" class="user-avatar" @error.prevent="handleAvatarError" />
          </div>
          <div class="open" @click="authStore.toggleMenu()" :class="{ active: authStore.isMenuOpen }">
            <img class="mobile arrow-img" src="/images/open-arrow.svg" alt="">
          </div>

          <div class="panel-block" v-show="authStore.isMenuOpen" ref="panelBlock"
            :style="{ display: authStore.isMenuOpen ? 'block' : 'none' }">
            <div class="top">
              <div class="light"></div>
              <div class="dark"></div>
              <div class="close" @click="closeUserMenu" @click.stop="closeUserMenu"></div>
            </div>
            <div class="photo" v-if="userAvatar">
              <img :src="userAvatar" :alt="userName" @error.prevent="handleAvatarError" />
            </div>
            <div class="name">{{ userName }}</div>
            <div class="id">ID: {{ userId }}</div>
            <div class="panel-balance">
              <img src="/images/stm-coin.svg" class="theme-icon" alt="Монета" />
              <div class="price">{{ formatNumber(userBalance) }}</div>
              <div class="refresh" @click="openGeneratePromo">
                <img class="refresh-img" src="/images/refresh.svg" alt="">
              </div>
              <div class="refreshs" @click="openGeneratePromo">
                <img class="refresh-img" src="/images/refresh.svg" alt="">
              </div>
            </div>

            <div class="menulogin">
              <ul>
                <li :class="{ active: $route.name === 'profile-overview' }">
                  <router-link to="/profile" class="menu-item" active-class="active">
                    <img src="/images/profile-menu.svg" alt="" class="menulogin"
                      :class="{ active: $route.name === 'profile-overview' }">
                    Мой профиль
                  </router-link>
                </li>
                <li :class="{ active: $route.name === 'profile-referrals' }">
                  <router-link to="/profile/referrals" class="menu-item" active-class="active">
                    <img src="/images/menu-ref-icon.svg" alt="" class="menulogin"
                      :class="{ active: $route.name === 'profile-referrals' }">
                    Реферальная программа
                  </router-link>
                </li>

                <li :class="{ active: $route.name === 'profile-purchases' }">
                  <router-link to="/profile/purchases" class="menu-item" active-class="active">
                    <img src="/images/payment-icon-menu.svg" alt="" class="menulogin"
                      :class="{ active: $route.name === 'profile-purchases' }">
                    Мои покупки
                  </router-link>
                </li>

                <li :class="{ active: $route.name === 'profile-bonuses' }">
                  <router-link to="/profile/bonuses" class="menu-item" active-class="active">
                    <img src="/images/menu-bonus.svg" alt="" class="menulogin"
                      :class="{ active: $route.name === 'profile-bonuses' }">
                    SM бонусы и промокоды
                  </router-link>
                </li>

                <li>
                  <a href="#" @click.prevent="handleLogout" class="menu-item">
                    <img src="/images/menu-exit.svg" alt="" class="menulogin">
                    Выйти
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </template>

      <LoginModal :show="isLoginModalOpen" @close="closeLoginModal" @auth-success="handleAuthSuccess"
        v-model:show="isLoginModalOpen" />
      <div class="mobile-menu" ref="mobileMenu" @click="toggleMenu" @touchstart.prevent="toggleMenu">
      </div>
    </div>
  </div>
  <GeneratePromo ref="promoModal" />

</template>
<script>
import settingsService from '@/service/api/service';
import LoginModal from "@/auth/Components/AuthPopup.vue";
import { useAuthStore } from '@/store/auth';
import { eventBus } from '@/service/eventBus';
import GeneratePromo from './ProFile/Components/GeneratePromo.vue';
import SocialLinks from '@/components/Soclinks.vue';





export default {
  name: 'Header',

  components: {
    LoginModal,
    GeneratePromo,
    SocialLinks
  },

  setup() {
    const authStore = useAuthStore();
    return { authStore };
  },

  data() {
    return {
      isMenuOpen: false,
      settings: null,
      isLoginModalOpen: false,
      isUserMenuOpen: false,
      key: 0,
      isLoading: false, 
      isRefreshing: false,
    };
  },
  async created() {
    eventBus.on('forceUpdateHeader', () => {
      this.key += 1; 
      this.$forceUpdate();
    });
  },
  async mounted() {
    document.addEventListener('keydown', this.handleKeyDown);
    document.addEventListener('click', this.handleOutsideClick);
    window.addEventListener('click', this.handleGlobalClick);


    try {
      await settingsService.fetch();
      this.settings = settingsService.get();
    } catch (error) {
      console.error('Failed to load settings:', error);
    }
  },

  beforeUnmount() {
    document.removeEventListener('keydown', this.handleKeyDown);
    document.removeEventListener('click', this.handleOutsideClick);
    document.removeEventListener('click', this.handleUserMenuOutsideClick);
    window.removeEventListener('click', this.handleGlobalClick);

    eventBus.off('forceUpdateHeader');


  },

  computed: {
    isAuthenticated() {
      return this.authStore.isAuthenticated;
    },
    userBalance() {
      return this.authStore.userBalance;
    },
    userAvatar() {
      const avatar = this.authStore.userAvatar;
      return avatar ?? "../images/ruslan.webp"; 
    },
    userName() {
      return this.authStore.userName || this.authStore.user?.name;
    },
    userId() {
      return this.authStore.user?.id;
    }
  },

  methods: {
    handleGlobalClick(event) {
      // Get references to necessary elements
      const panelBlock = this.$refs.panelBlock;
      const openButton = event.target.closest('.open');

      // If panel is open and click is outside panel and not on open button
      if (this.authStore.isMenuOpen &&
        panelBlock &&
        !panelBlock.contains(event.target) &&
        !openButton) {
        this.closeUserMenu();
      }
    },
    async openGeneratePromo() {
      try {
        if (this.$refs.promoModal) {
          await this.$refs.promoModal.open();
        }
      } catch (error) {
        console.error('Error opening modal:', error);
      }
    },
    handleAvatarError(e) {
      const failedUrl = e.target.src;
      if (failedUrl.includes('t.me/i/userpic/320/')) {
        // console.log('Telegram avatar 404 error detected');
        e.target.onerror = null; // Предотвращаем рекурсию
        e.target.src = '../images/ruslan.webp'; // Используем путь от корня
      } else {
        e.target.src = '../images/vsevolod.webp'; // Fallback для других случаев
      }
    },

    async handleAuthSuccess() {
      this.isLoginModalOpen = false;
      await this.$nextTick();
      this.isMenuOpen = false;
    },
    handleUserMenuOutsideClick(e) {
      const panel = this.$el.querySelector('.login-panel');
      if (this.isUserMenuOpen && panel && !panel.contains(e.target)) {
        this.closeUserMenu();
      }
    },

    async refreshBalance() {
      await this.authStore.fetchUserData();
    },

    toggleUserMenu(event) {
      event.stopPropagation();
      this.authStore.toggleMenu();
    },

    closeUserMenu() {
      this.authStore.closeMenu();
      this.isUserMenuOpen = !this.isUserMenuOpen;
    },

    async handleLogout() {
      try {
        this.isLoading = true;
        await this.authStore.logout();
        this.closeUserMenu();
        eventBus.emit('forceUpdateHeader');
        await this.$router.push('/');
        window.location.reload(); 
        await this.$nextTick();

        this.isMenuOpen = false;
        this.isUserMenuOpen = false;
        this.isLoginModalOpen = false;

      } catch (error) {
        console.error('Logout error:', error);
      } finally {
        this.isLoading = false;
      }
    },
    handleClickOutside(event) {
      const panel = this.$el.querySelector('.panel-block');
      if (panel && !panel.contains(event.target) &&
        !event.target.classList.contains('open')) {
        this.closeUserMenu();
      }
    },

    openLoginModal() {
      if (!this.isLoading) {
        this.isLoginModalOpen = true;
      }
    },

    closeLoginModal() {
      this.isLoginModalOpen = false;
    },

    formatNumber(value) {
      if (value === undefined || value === null) return '';
      const numberValue = typeof value === 'string' ? parseInt(value, 10) : value;
      if (isNaN(numberValue)) return '';

      try {
        return new Intl.NumberFormat('ru-RU').format(numberValue);
      } catch (error) {
        return numberValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
      }
    },

    scrollToSection(sectionId) {
      if (this.$route.path !== '/') {
        this.$router.push('/').then(() => {
          this.$nextTick(() => {
            const element = document.getElementById(sectionId);
            if (element) {
              element.scrollIntoView({ behavior: 'smooth' });
              this.closeMenu();
            }
          });
        });
      } else {
        const element = document.getElementById(sectionId);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
          this.closeMenu();
        }
      }
    },

    toggleMenu(event) {
      event.stopPropagation();
      this.isMenuOpen = !this.isMenuOpen;
    },

    closeMenu() {
      this.isMenuOpen = false;
    },

    handleKeyDown(e) {
      if (e.key === 'Escape' && this.isMenuOpen) {
        this.closeMenu();
      }
    },

    handleOutsideClick(e) {
      if (!this.isMenuOpen) return;
      const menuEl = this.$refs.menu;
      const burgerEl = this.$refs.mobileMenu;
      if (menuEl && burgerEl && !menuEl.contains(e.target) && !burgerEl.contains(e.target)) {
        this.closeMenu();
      }
    }
  },

  watch: {
    '$route'() {
      this.closeUserMenu();
    },
    isAuthenticated() {
      console.log('Auth state changed:');
    },

    isMenuOpen(newValue) {
      document.body.style.overflow = newValue ? 'hidden' : '';
    }
  }
};
</script>
<style>
.enter {
  cursor: pointer;
}

.enter.disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.menulogin ul li a {
  white-space: nowrap;
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  border-radius: 8px;
  transition: background-color 0.3s ease;
}
.refresh-img{
  width: 18px;
}
.header .login-panel .panel-block .panel-balance .refresh .refresh-img{
  width: 18px;
}
</style>