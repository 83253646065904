<template>
    <UniversalPopup :isVisible="showModal" @close="handleClose">
        <div class="block-popup">
            <div class="text">
                <div class="title">
                    А может быть ещё?
                </div>
                <div class="desc">
                    Вы авторизовались на сайте уже получили<span class="sum-text">+{{ settings?.[authType === 'vk' ?
                        'vk_sub_bonus' : 'tg_sub_bonus']}}</span>
                    <img src="/images/stm-monets.svg" alt="" class="bonus-icon">
                    бонусов и сразу можете получить ещё, просто выполните это легкое задание
                </div>
                <div class="item-block section-profile-two welcomesubscribe">
                    <div class="items-connect welcomesubscribe">
                        <template v-if="authType === 'vk' && !authStore.user?.vk_bonus_done">
                            <div class="wlcm-connect" :style="{ order: 0 }">
                                <div class="icon">
                                    <img src="/images/section-profile-vk.svg" alt="">
                                </div>
                                <div class="right">
                                    <div class="name">Подписка на VK</div>
                                    <button class="btn" @click="handleVkButtonClick"
                                        :disabled="isVkChecking || vkButtonState === null">
                                        <LoadingIndicator v-if="isVkChecking" />
                                        <template v-else>
                                            {{ vkButtonState === 'check' ? 'Проверить' : 'Подписаться' }}
                                            <template v-if="settings && vkButtonState !== 'check'">
                                                <span class="sum-plus">+{{ settings.vk_sub_bonus }}</span>
                                                <img src="/images/stm-monets.svg" alt="">
                                            </template>
                                        </template>
                                    </button>
                                    <div v-if="vkSuccess" class="success-message-mobile">{{ vkSuccess }}</div>
                                    <div v-if="vkError" class="error-message-mobile" v-html="vkError"></div>
                                </div>
                            </div>
                        </template>

                        <template v-if="authType === 'telegram' && !authStore.user?.tg_bonus_done">
                            <div class="wlcm-connect" :style="{ order: 0 }">
                                <div class="icon">
                                    <img src="/images/section-profile-tl.svg" alt="">
                                </div>
                                <div class="right">
                                    <div class="name">Подписка на Telegram</div>
                                    <button class="btn" @click="handleTgButtonClick" :disabled="isTgChecking">
                                        <LoadingIndicator v-if="isTgChecking" />
                                        <template v-else>
                                            {{ tgButtonState === 'check' ? 'Проверить' : 'Подписаться' }}
                                            <template v-if="settings && tgButtonState !== 'check'">
                                                <span class="sum-plus">+{{ settings.tg_sub_bonus }}</span>
                                                <img src="/images/stm-monets.svg" alt="">
                                            </template>
                                        </template>
                                    </button>
                                    <div v-if="tgSuccess" class="success-message-mobile">{{ tgSuccess }}</div>
                                    <div v-if="tgError" class="error-message-mobile" v-html="tgError"></div>
                                </div>
                            </div>
                        </template>
                    </div>
                </div>
            </div>
        </div>
    </UniversalPopup>
</template>

<script>
import { useAuthStore } from '@/store/auth';
import { onMounted, ref, computed } from 'vue';
import UniversalPopup from '@/components/Popup/PopupDefault.vue';
import LoadingIndicator from '@/components/LoadingIndicator/Loadingindicator.vue';
import { authAPI } from '@/service/api/auth_api';
import settingsService from '@/service/api/service';

export default {
    name: 'WelcomeSubscribeModal',

    components: {
        UniversalPopup,
        LoadingIndicator
    },

    props: {
        show: {
            type: Boolean,
            default: false
        },
        authType: {
            type: String,
            required: true,
            validator: (value) => ['vk', 'telegram'].includes(value)
        }
    },

    emits: ['close', 'subscribe-success'],

    setup(props) {
        const authStore = useAuthStore();
        const settings = ref(null);

        // Вычисляемое свойство для определения показа модалки
        const showModal = computed(() => {
            if (!props.show) return false;
            if (props.authType === 'telegram' && authStore.user?.tg_bonus_done) return false;
            if (props.authType === 'vk' && authStore.user?.vk_bonus_done) return false;
            return true;
        });

        return {
            authStore,
            settings,
            showModal
        };
    },

    data() {
        return {
            isVkChecking: false,
            isTgChecking: false,
            vkError: null,
            vkSuccess: null,
            tgError: null,
            tgSuccess: null,
            vkButtonState: 'connect',
            tgButtonState: 'connect'
        };
    },

    async mounted() {
        try {
            await settingsService.fetch();
            this.settings = settingsService.get();
        } catch (error) {
            console.error('Failed to load settings:', error);
        }
    },

    methods: {
        detectDeviceType() {
            return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
        },

        async handleVkButtonClick() {
            const isMobile = this.detectDeviceType();
            const vkLink = isMobile ? 'vk://vk.com/steammoney' : 'https://vk.com/steammoney';

            if (this.isVkChecking) return;

            if (this.vkButtonState === 'connect') {
                this.vkError = `Подпишитесь на наш <a href="${vkLink}" target="_blank" style="color: #24FF6F; text-decoration: underline;">VK</a>`;
                this.vkButtonState = 'check';

                setTimeout(() => {
                    if (this.vkButtonState === 'check') {
                        this.vkButtonState = 'connect';
                    }
                }, 60000);
            } else {
                this.vkError = null;
                await this.checkVkStatus();
            }
        },

        async handleTgButtonClick() {
            const isMobile = this.detectDeviceType();
            const tgLink = isMobile ? 'tg://resolve?domain=steam_money_pay' : 'https://t.me/steam_money_pay';

            if (this.isTgChecking) return;

            if (this.tgButtonState === 'connect') {
                this.tgError = `Подпишитесь на наш <a href="${tgLink}" target="_blank" style="color: #24FF6F; text-decoration: underline;">Telegram</a>`;
                this.tgButtonState = 'check';

                setTimeout(() => {
                    if (this.tgButtonState === 'check') {
                        this.tgButtonState = 'connect';
                    }
                }, 60000);
            } else {
                this.tgError = null;
                await this.checkTelegramStatus();
            }
        },

        async checkVkStatus() {
            if (this.isVkChecking) return;

            this.isVkChecking = true;
            try {
                const { data } = await authAPI.checkVkUserSubscribe();
                if (data.status) {
                    await this.authStore.fetchUserData();
                    this.vkSuccess = `VK успешно подключен! +${this.settings?.vk_sub_bonus} бонусов`;
                    this.vkButtonState = null;
                    this.$emit('subscribe-success');
                    setTimeout(() => {
                        this.handleClose();
                    }, 2000);
                } else {
                    this.vkError = `Подпишитесь на наш <a href="https://vk.com/steammoney" target="_blank" style="color: #24FF6F; text-decoration: underline;">VK</a>`;
                    this.vkButtonState = 'connect';
                }
            } catch (error) {
                console.error('VK check error:', error);
                this.vkError = `Подпишитесь на наш <a href="https://vk.com/steammoney" target="_blank" style="color: #24FF6F; text-decoration: underline;">VK</a>`;
                this.vkButtonState = 'connect';
            } finally {
                this.isVkChecking = false;
            }
        },

        async checkTelegramStatus() {
            if (this.isTgChecking) return;

            this.isTgChecking = true;
            try {
                const { data } = await authAPI.checkTgUserSubscribe();
                if (data.status) {
                    await this.authStore.fetchUserData();
                    this.tgSuccess = `Telegram успешно подключен! +${this.settings?.tg_sub_bonus} бонусов`;
                    this.tgButtonState = null;
                    this.$emit('subscribe-success');
                    setTimeout(() => {
                        this.handleClose();
                    }, 2000);
                } else {
                    this.tgError = `Подпишитесь на наш <a href="https://t.me/steam_money_pay" target="_blank" style="color: #24FF6F; text-decoration: underline;">Telegram</a>`;
                    this.tgButtonState = 'connect';
                }
            } catch (error) {
                console.error('Telegram check error:', error);
                this.tgError = `Подпишитесь на наш <a href="https://t.me/steam_money_pay" target="_blank" style="color: #24FF6F; text-decoration: underline;">Telegram</a>`;
                this.tgButtonState = 'connect';
            } finally {
                this.isTgChecking = false;
            }
        },

        handleClose() {
            this.$emit('close');
        }
    }
};
</script>

<style scoped>
.btn{
            margin: 0 0 0 4px;
            color: #FFD703;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
}
.sum-text{
            margin: 0 0 0 4px;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            color: #ffd703;
}
body.light .sum-text{
    color: #F9AF11;
}

body.light .sum-plus {
    color: #F9AF11;
}
body.light .btn {
    color: #0DB740;
background: rgba(0, 190, 58, 0.12);
    border: 1px solid rgba(0, 190, 58, 0.24);
}

.title {
    color: var(--Text-Primary, #EDEEEE);
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    margin-bottom: 20px;
}

.desc {
    text-align: center;
    padding-bottom: 5%;
    padding-top: 5%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
    flex-wrap: wrap;
}

.desc span {
    display: inline-flex;
    align-items: center;
}

.bonus-icon {
    width: 24px;
    height: 24px;
    vertical-align: middle;
    display: inline-block;
}
.title {
        color: var(--Text-Primary, #EDEEEE);
            text-align: center;
            font-size: 24px;
            font-style: normal;
            font-weight: 500;
            line-height: 24px;
    text-align: center;
}
.desc{
    text-align: center;
    padding-bottom: 10% ;
    padding-top: 5%;
}
.welcomesubscribe {
    justify-content: center;
}

.wlcm-connect {
    width: calc(60% - 4px);
    padding: 12px 16px;
    border-radius: 8px;
    border: 1px solid var(--Border-Primary-Default, rgb(237 238 238 / 4%));
    display: flex;
    gap: 12px;
    transition: .5s;
    cursor: pointer;
}
.bonus-icon {
    width: 24px;
    height: 24px;
    vertical-align: middle;
    display: inline-block;
    content: url('/images/stm-monets.svg');
}

/* Светлая тема */
body.light .bonus-icon {
    content: url('/images/stm-coin-orange.svg');
}

.wlcm-connect button img {
    width: 24px;
    height: 24px;
    vertical-align: middle;
    content: url('/images/stm-monets.svg');
}

/* Светлая тема для иконки в кнопке */
body.light .wlcm-connect button img {
    content: url('/images/stm-coin-orange.svg');
}
body.light .wlcm-connect {
    border: 1px solid var(--Border-Primary-Default, rgb(237 238 238 / 4%));
    background: var(--Surface-Primary-Default, rgba(19, 19, 19, .06));

}
body.light .title{
        color: var(--Text-Primary, #131313)
}

.success-message-mobile,
.error-message-mobile {
    margin-top: 8px;
    font-size: 12px;
}

.success-message-mobile {
    color: #24FF6F;
}

.error-message-mobile {
    color: #FF3B30;
}

.connect button:disabled {
    opacity: 0.7;
    cursor: not-allowed;
}
@media screen and (max-width: 780px) {
    .wlcm-connect {
        width: calc(70% - 4px);
    }

    .section-profile-two .items-connect {
        flex-direction: inherit;
        justify-content: center;
    }
        .desc {
            display: block;
        }
}
</style>