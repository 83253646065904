import { siteConfig } from '@/config/site-config.js';

export const authService = {
    async handleVkAuth() {
        const urlParams = new URLSearchParams(window.location.search);
        const refCode = urlParams.get('ref');
        if (refCode) {
            localStorage.setItem('referral_code', refCode);
            console.log('Сохранен ref_code перед VK Auth:', refCode);
        }
        try {
            // Генерируем PKCE параметры
            const code_verifier = this.generateCodeVerifier();
            const code_challenge = await this.generateCodeChallenge(code_verifier);

            // Генерируем state
            const state = this.generateState();
            const authState = {
                state,
                ref_code: refCode
            };
            // Сохраняем данные в localStorage
            const authData = {
                code_verifier,
                state,
                ref_code: refCode || localStorage.getItem('referral_code') || ''
 
            };
            localStorage.setItem('vk_auth_state', JSON.stringify(authState));

            localStorage.setItem('vk_auth_data', JSON.stringify(authData));

            // Формируем параметры для URL
            const params = new URLSearchParams({
                response_type: 'code',
                client_id: siteConfig.vkClientId,
                redirect_uri: `${siteConfig.baseUrl}/auth/vk-callback`,
                state: state,
                scope: 'email',
                code_challenge: code_challenge,
                code_challenge_method: 'S256'
            });

            // Возвращаем URL для редиректа
            return `https://id.vk.com/authorize?${params.toString()}`;
        } catch (error) {
            console.error('Error preparing VK auth:', error);
            throw error;
        }
    },
    generateState() {
        const charset = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789_-';
        const length = 32;
        let result = '';
        const array = new Uint8Array(length);
        crypto.getRandomValues(array);
        for (let i = 0; i < length; i++) {
            result += charset[array[i] % charset.length];
        }
        return result;
    },

    generateCodeVerifier() {
        const charset = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-._~';
        const length = 64;
        let result = '';
        const array = new Uint8Array(length);
        crypto.getRandomValues(array);
        for (let i = 0; i < length; i++) {
            result += charset[array[i] % charset.length];
        }
        return result;
    },

    async generateCodeChallenge(verifier) {
        const encoder = new TextEncoder();
        const data = encoder.encode(verifier);
        const hash = await crypto.subtle.digest('SHA-256', data);
        return btoa(String.fromCharCode(...new Uint8Array(hash)))
            .replace(/\+/g, '-')
            .replace(/\//g, '_')
            .replace(/=+$/, '');
    },

    async handleTelegramAuth() {
        const botId = siteConfig.tgBotId;
        const returnUrl = `${siteConfig.baseUrl}/auth/telegram-callback`;

        const authUrl = 'https://oauth.telegram.org/auth?' + new URLSearchParams({
            bot_id: botId,
            origin: siteConfig.baseUrl,
            return_to: returnUrl,
            request_access: 'write',
            embed: 0,
        }).toString();

        return {
            url: authUrl,
            width: 550,
            height: 470
        };
    },

    createAuthWindow(url, width, height) {
        const left = window.screen.width / 2 - width / 2;
        const top = window.screen.height / 2 - height / 2;

        return window.open(
            url,
            'auth-window',
            `width=${width},height=${height},left=${left},top=${top}`
        );
    },

    async generateCodeVerifierAndChallenge() {
        const verifier = this.generateRandomString(64);
        const encoder = new TextEncoder();
        const data = encoder.encode(verifier);
        const digest = await crypto.subtle.digest('SHA-256', data);
        const base64Digest = btoa(String.fromCharCode(...new Uint8Array(digest)))
            .replace(/=/g, '')
            .replace(/\+/g, '-')
            .replace(/\//g, '_');

        return { verifier, challenge: base64Digest };
    },

    generateRandomString(length) {
        const array = new Uint8Array(length);
        crypto.getRandomValues(array);
        const validChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-._~';
        return Array.from(array)
            .map(x => validChars.charAt(x % validChars.length))
            .join('');
    }
};
