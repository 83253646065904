import { defineStore } from 'pinia';
import { referralsAPI } from '../service/api/referrals.api';

export const useReferralStore = defineStore('referralStore', {
    state: () => ({
        refCount: 0,
        refTotalAmount: 0,
        referralList: [],
        loading: false,
        error: null,
        currentPage: 0,
        hasMore: true
    }),

    actions: {
        async fetchReferralStats() {
            this.loading = true;
            try {
                const data = await referralsAPI.fetchReferralStats();
                if (data.status) {
                    this.refCount = data.ref_count;
                    this.refTotalAmount = data.ref_total_amount;
                }
            } catch (error) {
                this.error = error;
            } finally {
                this.loading = false;
            }
        },

        async fetchReferralList(page = 0) {
            if (this.loading) return;

            this.loading = true;
            this.error = null;

            try {
                const data = await referralsAPI.fetchReferralList(page);

                if (data && data.list && Array.isArray(data.list)) {
                    const newReferrals = data.list.map(ref => ({
                        userId: ref.user_id,
                        login: ref.login,
                        amount: ref.amount,
                        bonus: ref.bonus,
                        createdAt: new Date(ref.created_at)
                    }));

                    // Если это первая страница, заменяем список
                    if (page === 0) {
                        this.referralList = newReferrals;
                    } else {
                        // Иначе добавляем к существующему списку
                        this.referralList = [...this.referralList, ...newReferrals];
                    }

                    // Проверяем, есть ли ещё страницы
                    this.hasMore = newReferrals.length > 0;
                    this.currentPage = page;
                } else {
                    if (page === 0) {
                        this.referralList = [];
                    }
                    this.hasMore = false;
                }
            } catch (error) {
                this.error = error;
            } finally {
                this.loading = false;
            }
        },

        async loadMore() {
            if (!this.hasMore || this.loading) return;
            await this.fetchReferralList(this.currentPage + 1);
        }
    }
});