<template>
    <div class="telegram-callback">
        <div v-if="error" style="color: red;">{{ error }}</div>
        <pre>{{ debugInfo }}</pre>
    </div>
</template>

<script>
export default {
    name: 'TelegramCallback',

    data() {
        return {
            debugInfo: '',
            error: null
        }
    },

    mounted() {
        try {
            const queryParams = this.$route.query;
            // console.log('TelegramCallback mounted with params:', queryParams);
            this.debugInfo = JSON.stringify({
                query: queryParams,
                location: window.location.href,
                time: new Date().toISOString()
            }, null, 2);

            if (!queryParams.id || !queryParams.hash) {
                throw new Error('Missing required Telegram data');
            }

            const userData = {
                id: queryParams.id,
                first_name: queryParams.first_name,
                username: queryParams.username,
                photo_url: queryParams.photo_url,
                auth_date: queryParams.auth_date,
                hash: queryParams.hash
            };

            // console.log('Prepared user data:', userData);

            if (!window.opener) {
                throw new Error('No parent window found');
            }

            // console.log('Sending data to parent window');
            window.opener.postMessage({
                type: 'telegram-auth',
                user: userData
            }, '*'); 

            setTimeout(() => {
                // console.log('Closing callback window');
                window.close();
            }, 2000);

        } catch (err) {
            console.error('Telegram callback error:', err);
            this.error = err.message;
            this.debugInfo += '\nError: ' + err.message;
        }
    }
};
</script>