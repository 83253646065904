<template>
    <div class="btn" @click="handleRefresh" :class="{ 'disabled': isLoading }">
        <img :class="{ 'rotating': isLoading, 'light-icon': isLightIcon }" class="refresh-icon"
            src="/images/refresh.svg" alt="refresh">
        {{ isLoading ? loadingText : buttonText }}
    </div>
</template>

<script>
export default {
    name: 'RefreshButton',

    props: {
        buttonText: {
            type: String,
            default: 'Обменять'
        },
        loadingText: {
            type: String,
            default: 'Обновление...'
        },
        isLoading: {
            type: Boolean,
            default: false
        },
        isLightIcon: {
            type: Boolean,
            default: false
        }
    },

    emits: ['refresh'],

    methods: {
        handleRefresh() {
            if (!this.isLoading) {
                this.$emit('refresh');
            }
        }
    }
}
</script>

<style scoped>
.btn {
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
    transition: all 0.3s ease;
}

.refresh-icon {
    transition: all 0.3s ease;
    content: url('/images/refresh.svg');
}

.refresh-icon.light-icon {
    content: url('/images/refresh-light.svg');
}

.btn:hover {
    color: #131313;
}

.btn:hover .refresh-icon {
    transform: rotate(180deg);
}

.btn.disabled {
    opacity: 0.7;
    cursor: not-allowed;
}

.rotating {
    animation: rotate 1s linear infinite;
}

@keyframes rotate {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}
</style>