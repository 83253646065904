import './assets/css/main.css';
import $ from 'jquery';
import { createApp, ref } from 'vue';
import { createPinia } from 'pinia';
import axios from 'axios';
import VueAxios from 'vue-axios';
import App from './App.vue';
import router from './router/router';
import mitt from 'mitt';
import metrika from './service/metrica/metrica';
import themePlugin from './theme/theme';
import { useUIStore } from '@/store/uistore';
import { useAuthStore } from '@/store/auth';
import i18n from './i18n';
import { initSentry } from './utils/sentry';
import * as Sentry from "@sentry/vue";
import { siteConfig } from './config/site-config';
import { refCodeStore } from './store/referralCodeStore';



window.$ = $;

const app = createApp(App);

initSentry(app, router);

const pinia = createPinia();

app.use(pinia);

axios.defaults.baseURL = siteConfig.apiUrl;

axios.interceptors.response.use(
  response => response,
  async error => {
    const originalRequest = error.config;

    if (error.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      const authStore = useAuthStore();
      try {
        const refreshed = await authStore.refreshToken();
        if (refreshed) {
          originalRequest.headers['Authorization'] = `${authStore.tokenType} ${authStore.token}`;
          return axios(originalRequest);
        }
      } catch (refreshError) {
        console.error('Token refresh failed:', refreshError);
        if (import.meta.env.PROD) {
          Sentry.captureException(refreshError);
        }
      }
    }
    return Promise.reject(error);
  }
);

const emitter = mitt();

app.config.globalProperties.$metrika = metrika;

app.config.errorHandler = (error, vm, info) => {
  console.error('Global error:', error);
  if (import.meta.env.PROD) {
    Sentry.captureException(error, {
      extra: {
        componentName: vm.$options.name,
        info,
        routePath: router.currentRoute.value.path
      }
    });
  }
};

app.use(VueAxios, axios);
app.use(themePlugin);
app.use(i18n);

app.use({
  install: (app) => {
    app.config.globalProperties.$events = {
      emit: (...args) => emitter.emit(...args),
      on: (...args) => emitter.on(...args),
      off: (...args) => emitter.off(...args),
    };
  },
});

const initApp = async () => {
  try {
    const uiStore = useUIStore();
    uiStore.initTheme();

    const referralStore = refCodeStore();
    referralStore.getCurrentReferralCode();

    const authStore = useAuthStore();
    await authStore.initAuth();

  } catch (error) {
    console.error('Failed to initialize app:', error);
    if (import.meta.env.PROD) {
      Sentry.captureException(error);
    }
  } finally {
    app.use(router);
    app.mount('body');
  }
};

initApp().catch(error => {
  console.error('Critical error during app initialization:', error);
  if (import.meta.env.PROD) {
    Sentry.captureException(error);
  }
});

export default app;