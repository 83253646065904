<template>
    <div class="section-profile">
        <div class="item-block section-profile-one">
            <div class="photo" v-if="userAvatar">
                <img :src="userAvatar" alt="User avatar" @error="handleAvatarError">
            </div>
            <div class="info">
                <div class="user">{{ userName ?? 'User#02826623' }}</div>
                <div class="id">ID: <input :value="userId" readonly>
                    <div class="copy" @click="copyId">
                        <img class="copy-img" alt="">
                    </div>
                </div>
            </div>
            <div class="total">
                <div class="logo">
                    <img src="/images/stm-coin.svg" class="theme-icon" alt="Монета" />
                </div>
                <template v-if="isBalanceLoading">
                    <div class="skeleton-animation" style="width: 80px; height: 28px;"></div>
                </template>
                <template v-else>
                    <div class="summ">{{ userBalance }}</div>
                </template>
                <RefreshButton :isLightIcon="true" 
                @click="openGeneratePromo" />
            </div>
        </div>
        <GeneratePromo ref="promoModal" />

        <div class="item-block section-profile-two">
            <div class="title">Подключить аккаунты
                <div class="question">
                    <div class="icon"></div>
                    <div class="text align_right">Получайте дополнительные бонусы за подключение соцсетей</div>
                </div>
            </div>

            <div class="items-connect">
                <div v-if="!authStore.user?.is_vk_user" class="connect" :style="{ order: 0 }">
                    <div class="icon">
                        <img src="/images/section-profile-vk.svg" alt="">
                    </div>
                    <div class="right">
                        <div class="name">Вконтакте</div>
                        <button class="btn" @click="linkVkAccount" :disabled="isVkLinking">
                            <LoadingIndicator v-if="isVkLinking" />
                            <template v-else>
                                Подключить
                                <template v-if="settings">
                                    <span>+{{ settings.vk_link_bonus }}</span>
                                </template>
                                <template v-else>
                                    <span class="skeleton-animation"
                                        style="width: 32px; height: 16px; display: inline-block; margin: 0 4px;">
                                    </span>
                                </template>
                                <img src="/images/stm-coin.svg" class="theme-icon" alt="Монета" />
                            </template>
                        </button>
                    </div>
                </div>
                <div v-else class="connect succes" :style="{ order: 1 }">
                    <div class="icon">
                        <img src="/images/section-profile-vk.svg" alt="">
                    </div>
                    <div class="right">
                        <div class="name">Вконтакте</div>
                        <button class="btn">
                            Подключено
                            <img src="/images/accept-bonus.svg" alt="" srcset="">
                        </button>
                        <div v-if="linkSuccess" class="success-message">
                            <span>{{ acceptVkSuccess }}</span>
                            <img v-if="linkSuccess" src="/images/stm-coin.svg" class="theme-icon" alt="Монета" />
                        </div>
                    </div>
                </div>

                <div v-if="!authStore.user?.is_tg_user" class="connect" :style="{ order: 0 }">
                    <div class="icon">
                        <img src="/images/section-profile-tl.svg" alt="">
                    </div>
                    <div class="right">
                        <div class="name">Телеграм</div>
                        <button class="btn" @click="linkTelegramAccount" :disabled="isTgLinking">
                            <LoadingIndicator v-if="isTgLinking" />
                            <template v-else>
                                Подключить
                                <template v-if="settings">
                                    <span>+{{ settings.tg_link_bonus }}</span>
                                </template>
                                <template v-else>
                                    <span class="skeleton-animation"
                                        style="width: 32px; height: 16px; display: inline-block; margin: 0 4px;">
                                    </span>
                                </template>
                                <img src="/images/stm-coin.svg" class="theme-icon" alt="Монета" />
                            </template>
                        </button>
                    </div>
                </div>
                <div v-else class="connect succes" :style="{ order: 1 }">
                    <div class="icon">
                        <img src="/images/section-profile-tl.svg" alt="">
                    </div>
                    <div class="right">
                        <div class="name">Телеграм</div>
                        <button class="btn">
                            Подключено
                            <img src="/images/accept-bonus.svg" alt="" srcset="">
                        </button>
                        <div v-if="tglinkSuccess" class="success-message">
                            <span>{{ acceptTgSuccess }}</span>
                            <img v-if="tglinkSuccess" src="/images/stm-coin.svg" class="theme-icon" alt="Монета" />
                        </div>
                    </div>
                </div>
            </div>

            <div v-if="linkError" class="error-message">{{ linkError }}</div>
        </div>

        <div class="item-block section-profile-two">
            <div class="title">Забрать бонусы
                <div class="question">
                    <div class="icon"></div>
                    <div class="text align_right">Выполняйте доступные задания и получайте дополнительные бонусы</div>
                </div>
            </div>
            <div class="items-connect">
                <template v-if="!authStore.user?.is_vk_user">
                    <div class="connect" :style="{ order: 0 }">
                        <div class="icon">
                            <img src="/images/section-profile-vk.svg" alt="">
                        </div>
                        <div class="right">
                            <div class="name">Вконтакте</div>
                            <button class="btn" @click="handleVkButtonClick">
                                Подписаться
                                <template v-if="settings">
                                    <span>+{{ settings.vk_sub_bonus }}</span>
                                    <img src="/images/stm-coin.svg" class="theme-icon" alt="Монета" />
                                </template>
                            </button>
                            <div v-if="vkError" class="error-message-mobile" v-html="vkError"></div>
                        </div>
                    </div>
                </template>
                <template v-else>
                    <div v-if="!authStore.user?.vk_bonus_done" class="connect" :style="{ order: 0 }">
                        <div class="icon">
                            <img src="/images/section-profile-vk.svg" alt="">
                        </div>
                        <div class="right">
                            <div class="name">Вконтакте</div>
                            <button class="btn" @click="handleVkButtonClick"
                                :disabled="isVkChecking || vkButtonState === null">
                                <LoadingIndicator v-if="isVkChecking" />
                                <template v-else>
                                    {{ vkButtonState === 'check' ? 'Проверить' : 'Подписаться' }}
                                    <template v-if="settings && vkButtonState !== 'check'">
                                        <span>+{{ settings.vk_sub_bonus }}</span>
                                        <img src="/images/stm-coin.svg" class="theme-icon" alt="Монета" />
                                    </template>
                                </template>
                            </button>
                            <div v-if="vkError" class="error-message-mobile" v-html="vkError"></div>
                            <div v-if="vkSuccess" class="success-message-inline">
                                <span>{{ vkSuccess }}</span>
                                <img src="/images/stm-coin.svg" class="theme-icon" alt="Монета" />
                            </div>
                        </div>
                    </div>

                    <div v-else class="connect succes" :style="{ order: 1 }">
                        <div class="icon">
                            <img src="/images/section-profile-vk.svg" alt="">
                        </div>
                        <div class="right">
                            <div class="name">Вконтакте</div>
                            <button class="btn">
                                Выполнено
                                <img src="/images/accept-bonus.svg" alt="">
                            </button>
                            <div v-if="formattedVkSuccess" class="success-message">
                                <span>{{ formattedVkSuccess }}</span>
                                <img v-if="formattedVkSuccess" src="/images/stm-coin.svg" class="theme-icon"
                                    alt="Монета" />
                            </div>
                        </div>
                    </div>
                </template>

                <template v-if="!authStore.user?.is_tg_user">
                    <div class="connect" :style="{ order: 0 }">
                        <div class="icon">
                            <img src="/images/section-profile-tl.svg" alt="">
                        </div>
                        <div class="right">
                            <div class="name">Телеграм</div>
                            <button class="btn" @click="handleTgButtonClick">
                                <LoadingIndicator v-if="isTgChecking" />
                                <template v-else>
                                    {{ tgButtonState === 'check' ? 'Проверить' : 'Подписаться' }}
                                    <template v-if="settings && tgButtonState !== 'check'">
                                        <span>+{{ settings.tg_sub_bonus }}</span>
                                        <img src="/images/stm-coin.svg" class="theme-icon" alt="Монета" />
                                    </template>
                                </template>
                            </button>
                            <div v-if="tgError" class="error-message-mobile" v-html="tgError"></div>
                            <div v-if="tgSuccess" class="success-message-inline" v-html="tgSuccess"></div>
                        </div>
                    </div>
                </template>
                <template v-else>
                    <div v-if="!authStore.user?.tg_bonus_done" class="connect" :style="{ order: 0 }">
                        <div class="icon">
                            <img src="/images/section-profile-tl.svg" alt="">
                        </div>
                        <div class="right">
                            <div class="name">Телеграм</div>
                            <button class="btn" @click="handleTgButtonClick" :disabled="isTgChecking">
                                <LoadingIndicator v-if="isTgChecking" />
                                <template v-else>
                                    {{ tgButtonState === 'check' ? 'Проверить' : 'Подписаться' }}
                                    <template v-if="settings && tgButtonState !== 'check'">
                                        <span>+{{ settings.tg_sub_bonus }}</span>
                                        <img src="/images/stm-coin.svg" class="theme-icon" alt="Монета" />
                                    </template>
                                </template>
                            </button>
                            <div v-if="tgError" class="error-message-mobile" v-html="tgError"></div>

                        </div>
                    </div>
                    <div v-else class="connect succes" :style="{ order: 1 }">
                        <div class="icon">
                            <img src="/images/section-profile-tl.svg" alt="">
                        </div>
                        <div class="right">
                            <div class="name">Телеграм</div>
                            <button class="btn">
                                Выполнено
                                <img src="/images/accept-bonus.svg" alt="">
                            </button>
                            <div v-if="tgSuccess" class="success-message">
                                <span>{{ formattedTgSuccess }}</span>
                                <img v-if="formattedTgSuccess" src="/images/stm-coin.svg" class="theme-icon"
                                    alt="Монета" />
                            </div>

                        </div>
                    </div>
                </template>
            </div>

            <!-- <div class="items-connect">
                <div class="connect-error">
                    <div v-if="vkSuccess" class="success-message-soc">{{ vkSuccess }}</div>
                    <div v-if="vkError" class="error-message-soc" v-html="vkError"></div>
                </div>
                <div class="connect-error">
                    <div v-if="tgSuccess" class="success-message-soc">{{ tgSuccess }}</div>
                    <div v-if="tgError" class="error-message-soc" v-html="tgError"></div>
                </div>
            </div> -->
        </div>

        <div class="item-block section-profile-three">
            <div class="title">Активировать промокод</div>
            <div class="desc">Введите промокод для получения дополнительных бонусов</div>
            <form class="promo-form" @submit.prevent="activatePromo">
                <div class="input">
                    <input type="text" v-model="promoCode" placeholder="Введите промокод" :disabled="isActivating">
                    <div class="question promowindow">
                        <div class="icon"></div>
                        <div class="text align_right"> Раздаём в нашем канале<a :href="siteConfig.socialLinks.telegram"
                                target="_blank">Telegram-канале</a>
                            —
                            подпишись,
                            чтобы не
                            пропустить! 🔥</div>
                    </div>
                </div>
                <button type="submit" :disabled="isActivating || !promoCode" :class="{ 'disabled': isActivating }">
                    {{ isActivating ? 'Активация...' : 'Активировать' }}
                </button>
            </form>

            <div v-if="promoError" class="error-message">{{ promoError }}</div>
            <div v-if="promoSuccess" class="success-message">{{ promoSuccess }}</div>
        </div>

        <div class="section-profile-share">
            <div class="title">Приглашай друзей и получай бонусы!</div>
            <div class="desc">За каждого приглашенного друга
                <br> начисляются SM баллы со всех его покупок
            </div>
            <div class="add" @click="goToReferrals">Пригласить участника</div>
        </div>
    </div>
</template>

<script>
import { useAuthStore } from '@/store/auth';
import { onMounted, ref } from 'vue';
import RefreshButton from '@/components/RefreshButton/RefreshButton.vue';
import { promoApi } from '@/service/api/promo_api';
import GeneratePromo from './GeneratePromo.vue';
import { useRouter } from 'vue-router';
import LoadingIndicator from '@/components/LoadingIndicator/Loadingindicator.vue';
import { authAPI } from '@/service/api/auth_api';
import settingsService from '@/service/api/service';
import { authService } from '@/service/social_connect/auth.service';
import { VKLinking } from '@/utils/auth/vklinking';
import { siteConfig } from '@/config/site-config';


export default {
    name: 'ProfileOverview',

    components: {
        RefreshButton,
        GeneratePromo,
        LoadingIndicator,
    },

    setup() {
        const router = useRouter();
        const authStore = useAuthStore();
        const settings = ref(null);

        onMounted(async () => {
            await authStore.fetchUserData();
            try {
                await settingsService.fetch();
                settings.value = settingsService.get();
            } catch (error) {
                console.error('Failed to load settings:', error);
            }
        });

        return { authStore, router, settings };
    },

    data() {
        return {
            siteConfig,
            promoCode: '',
            loading: false,
            error: null,
            isRefreshing: false,
            promoError: null,
            promoSuccess: null,
            isActivating: false,
            isVkChecking: false,
            isTgChecking: false,
            vkError: null,
            vkSuccess: null,
            tgError: null,
            tgSuccess: null,
            isVkLinking: false,
            isTgLinking: false,
            linkError: null,
            linkSuccess: null,
            tglinkSuccess: null,
            vkButtonState: 'connect',
            tgButtonState: 'connect',

        }
    },

    computed: {
        socialLinks() {
            return siteConfig.socialLinks;
        },
        acceptVkSuccess() {
            if (!this.linkSuccess) return '';
            return this.linkSuccess.replace(/<img[^>]*>/g, '') + ' ';

        },
        acceptTgSuccess() {
            if (!this.tglinkSuccess) return '';
            return this.tglinkSuccess.replace(/<img[^>]*>/g, '') + ' ';
        },
        formattedVkSuccess() {
            if (!this.vkSuccess) return '';
            return this.vkSuccess.replace(/<img[^>]*>/g, '') + ' ';
        },

        formattedTgSuccess() {
            if (!this.tgSuccess) return '';
            return this.tgSuccess.replace(/<img[^>]*>/g, '') + ' ';
        },
        userName() {
            return this.authStore.userName || 'User';
        },
        userId() {
            return this.authStore.user?.id || '';
        },
        userBalance() {
            return this.authStore.user?.balance || 0;
        },
        isBalanceLoading() {
            return !this.authStore.user || this.authStore.loading;
        },
        userAvatar() {
            return this.authStore.userAvatar || '/images/default-avatar.svg';
        },
        isVkBonusDone() {
            console.log(this.authStore.user);
            return this.authStore.user?.vk_bonus_done || false;
        },
        isTgBonusDone() {
            return this.authStore.user?.tg_bonus_done || false;
        },
        refCode() {
            return this.authStore.user?.refCode || '';
        }
    },

    methods: {
        detectDeviceType() {
            return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
        },
        async handleVkButtonClick() {
            if (!this.authStore.user?.is_vk_user) {
                this.vkError = 'Подключите аккаунт VK, чтобы выполнить это задание';
                setTimeout(() => {
                    this.vkError = null;
                }, 5000);
                return;
            }

            const isMobile = this.detectDeviceType();
            const vkLink = isMobile ? siteConfig.socialMobileLinks.vk : siteConfig.socialLinks.vk;

            if (this.isVkChecking) return;

            if (this.vkButtonState === 'connect') {
                this.vkError = `Подпишитесь на наш <a href="${vkLink}" target="_blank" style="color: #24FF6F; text-decoration: underline;">VK</a>`;
                this.vkButtonState = 'check';

                setTimeout(() => {
                    if (this.vkButtonState === 'check') {
                        this.vkButtonState = 'connect';
                    }
                }, 60000);
            } else {
                this.vkError = null;
                await this.checkVkStatus();
            }
        },

        async handleTgButtonClick() {
    if (!this.authStore.user?.is_tg_user) {
        this.tgError = 'Подключите аккаунт Telegram, чтобы выполнить это задание';
        setTimeout(() => {
            this.tgError = null;
        }, 5000);
        return;
    }

    const isMobile = this.detectDeviceType();
    const tgLink = isMobile ? siteConfig.socialMobileLinks.telegram : siteConfig.socialLinks.telegram;

    if (this.isTgChecking) return;

    if (this.tgButtonState === 'connect') {
        this.tgError = `Подпишитесь на наш <a href="${tgLink}" target="_blank" style="color: #24FF6F; text-decoration: underline;">Telegram</a>`;
        this.tgButtonState = 'check';

        setTimeout(() => {
            if (this.tgButtonState === 'check') {
                this.tgButtonState = 'connect';
            }
        }, 60000);
    } else {
        this.tgError = null;
        await this.checkTelegramStatus();
    }
},


        async linkVkAccount() {
            if (this.isVkLinking) return;
            this.isVkLinking = true;
            this.linkError = null;
            this.linkSuccess = null;

            try {
                const { url } = await VKLinking.generateAuthUrl();

                const authWindow = window.open(
                    url,
                    'vk-link',
                    'width=600,height=400'
                );

                const handleMessage = async (event) => {
                    if (event.data?.type === 'vk-link-success') {
                        if (authWindow && !authWindow.closed) {
                            authWindow.close();
                        }
                        window.removeEventListener('message', handleMessage);
                        await this.authStore.fetchUserData();
                        this.linkSuccess = `Успешно! +${this.settings?.vk_link_bonus}`;

                        setTimeout(() => {
                            this.linkSuccess = null;
                        }, 20000);

                    } else if (event.data?.type === 'vk-link-error') {
                        if (authWindow && !authWindow.closed) {
                            authWindow.close();
                        }
                        window.removeEventListener('message', handleMessage);
                        this.linkError = event.data.error || 'Ошибка при подключении ВКонтакте';

                        setTimeout(() => {
                            this.linkError = null;
                        }, 20000);
                    }
                };

                window.addEventListener('message', handleMessage);

                if (authWindow) {
                    const checkWindow = setInterval(() => {
                        if (authWindow.closed) {
                            clearInterval(checkWindow);
                            window.removeEventListener('message', handleMessage);
                            this.isVkLinking = false;
                            VKLinking.clearLinkData();
                        }
                    }, 500);
                }

            } catch (error) {
                console.error('VK linking error:', error);
                this.linkError = 'Ошибка при открытии окна авторизации';
                VKLinking.clearLinkData();
            } finally {
                this.isVkLinking = false;

                if (this.linkSuccess || this.linkError) {
                    setTimeout(() => {
                        this.linkSuccess = null;
                        this.linkError = null;
                    }, 3000);
                }
            }
        },
        async linkTelegramAccount() {
            if (this.isTgLinking) return;

            this.isTgLinking = true;
            this.linkError = null;
            this.tglinkSuccess = null; 

            try {
                const { url, width, height } = await authService.handleTelegramAuth();
                const authWindow = authService.createAuthWindow(url, width, height);

                const handleTelegramMessage = async (event) => {
                    if (event.origin === "https://oauth.telegram.org") {
                        try {
                            const data = JSON.parse(event.data);
                            if (data.event === "auth_result" && data.result) {
                                if (authWindow && !authWindow.closed) {
                                    authWindow.close();
                                }
                                window.removeEventListener('message', handleTelegramMessage);

                                const { data: response } = await authAPI.linkTelegramAccount(data.result);

                                if (response.status) {
                                    await this.authStore.fetchUserData();
                                    this.tglinkSuccess = `Успешно! +${this.settings?.tg_link_bonus}`;
                                    setTimeout(() => {
                                        this.tglinkSuccess = null;
                                    }, 20000);
                                } else {
                                    throw new Error(response.message || 'Ошибка при подключении Telegram');
                                }
                            }
                        } catch (error) {
                            console.error('Telegram link error:', error);
                            this.linkError = error.message || 'Ошибка при подключении Telegram';

                            if (authWindow && !authWindow.closed) {
                                authWindow.close();
                            }
                        }
                    }
                };

                window.addEventListener('message', handleTelegramMessage);

                if (authWindow) {
                    const checkWindow = setInterval(() => {
                        if (authWindow.closed) {
                            clearInterval(checkWindow);
                            window.removeEventListener('message', handleTelegramMessage);
                            this.isTgLinking = false;
                        }
                    }, 500);
                }

            } catch (error) {
                console.error('Telegram auth window error:', error);
                this.linkError = 'Ошибка при открытии окна авторизации';
            } finally {
                this.isTgLinking = false;
            }
        },

        handleAvatarError(e) {
            e.target.src = "/images/default-avatar.svg";
        },

        async checkVkStatus() {
            if (this.isVkChecking) return;

            this.isVkChecking = true;
            try {
                const { data } = await authAPI.checkVkUserSubscribe();
                if (data.status) {
                    await this.authStore.fetchUserData();
                    this.vkSuccess = `Успешно! +${this.settings?.vk_sub_bonus}`;
                    this.vkButtonState = null;

                    setTimeout(() => {
                        this.vkSuccess = null;
                    }, 10000);
                } else {
                    this.vkError = 'Не удалось подтвердить подписку. Проверьте, что вы подписались на группу.';
                    this.vkButtonState = 'connect';
                }
            } catch (error) {
                console.error('VK check error:', error);
                this.vkError = 'Произошла ошибка при проверке подписки';
                this.vkButtonState = 'connect';
            } finally {
                this.isVkChecking = false;
                if (this.vkError) {
                    setTimeout(() => {
                        this.vkError = null;
                    }, 5000);
                }
            }
        },

        async checkTelegramStatus() {
            if (this.isTgChecking) return;

            this.isTgChecking = true;
            try {
                const { data } = await authAPI.checkTgUserSubscribe();
                if (data.status) {
                    await this.authStore.fetchUserData();
                    this.tgSuccess = `Успешно! +${this.settings?.tg_sub_bonus}`;
                    this.tgButtonState = null;

                    setTimeout(() => {
                        this.tgSuccess = null;
                    }, 10000);
                } else {
                    this.tgError = 'Не удалось подтвердить подписку. Проверьте, что вы подписались на канал.';
                    this.tgButtonState = 'connect';
                }
            } catch (error) {
                console.error('Telegram check error:', error);
                this.tgError = 'Произошла ошибка при проверке подписки';
                this.tgButtonState = 'connect';
            } finally {
                this.isTgChecking = false;
                if (this.tgError) {
                    setTimeout(() => {
                        this.tgError = null;
                    }, 5000);
                }
            }
        },

        async copyId() {
            try {
                await navigator.clipboard.writeText(this.userId);
            } catch (err) {
                console.error('Failed to copy:', err);
            }
        },

        goToReferrals() {
            this.router.push('/profile/referrals');
        },

        async openGeneratePromo() {
            try {
                if (this.$refs.promoModal) {
                    await this.$refs.promoModal.open();
                }
            } catch (error) {
                console.error('Error opening modal:', error);
            }
        },

        async activatePromo() {
            if (!this.promoCode || this.isActivating) return;

            this.isActivating = true;
            this.promoError = null;
            this.promoSuccess = null;

            try {
                const result = await promoApi.applyBonusPromo(this.promoCode);
                if (result.status) {
                    this.promoSuccess = 'Промокод успешно активирован!';
                    this.promoCode = '';
                    await this.authStore.fetchUserData();
                } else {
                    this.promoError = result;
                }
            } catch (error) {
                this.promoError = error;
            } finally {
                this.isActivating = false;
                setTimeout(() => {
                    this.promoError = null;
                    this.promoSuccess = null;
                }, 3000);
            }
        }
    }
}
</script>

<style>
.success-message-inline,
.success-message {
    margin-top: 8px;
    color: #24FF6F;
    font-size: 14px;
    line-height: 1.4;
    display: flex;
    align-items: center;
    gap: 4px;
}

.success-message-inline img.theme-icon,
.success-message img.theme-icon {
    width: 16px;
    height: 16px;
    display: inline-flex;
    vertical-align: middle;
    margin: 0;
}

/* Светлая тема */
body.light .success-message-inline,
body.light .success-message {
    color: #0db740;
}

@media (max-width: 768px) {

    .success-message-inline,
    .success-message {
        font-size: 12px;
    }

    .success-message-inline img.theme-icon,
    .success-message img.theme-icon {
        width: 14px;
        height: 14px;
    }
}
.success-message-inline {
    margin-top: 8px;
    color: #24FF6F;
    font-size: 14px;
    line-height: 1.4;
}

.success-message-inline img.theme-icon {
    width: 16px;
    height: 16px;
    vertical-align: middle;
    margin: -3px 2px 0;
    display: inline-block;
}

body.light .success-message-inline {
    color: #0db740;
}

@media (max-width: 768px) {
    .success-message-inline {
        font-size: 12px;
    }

    .success-message-inline img.theme-icon {
        width: 14px;
        height: 14px;
    }
}

.items-connect {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
}

.connect {
    width: calc(50% - 4px);
    transition: all 0.3s ease;
}

.connect button:disabled {
    opacity: 0.7;
    cursor: not-allowed;
}

/* Серый цвет для выполненных заданий */
.connect.succes {
    opacity: 0.7;
}

@media (max-width: 768px) {
    .connect {
        width: 100%;
    }
}

.success-message-mobile,
.error-message-mobile {
    margin-top: 8px;
    font-size: 12px;
}

.success-message-mobile {
    color: #24FF6F;
}

.error-message-mobile {
    color: #FF3B30;
}

.connect-error {
    width: calc(50% - 4px);
    padding: 12px 16px;
    border-radius: 8px;
    display: flex;
    gap: 12px;
    transition: .5s;
    cursor: pointer;
}

.skeleton-animation {
    position: relative;
    overflow: hidden;
    border-radius: 4px;
    background: rgba(255, 255, 255, 0.04);
    vertical-align: middle;
}

.skeleton-animation::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background: linear-gradient(90deg,
            transparent 0,
            rgba(255, 255, 255, 0.1) 20%,
            rgba(255, 255, 255, 0.2) 60%,
            transparent);
    animation: shimmer 2s infinite;
}

/* Стили для светлой темы */
body.light .skeleton-animation {
    background: rgba(19, 19, 19, 0.04);
}

body.light .skeleton-animation::after {
    background: linear-gradient(90deg,
            transparent 0,
            rgba(19, 19, 19, 0.08) 20%,
            rgba(19, 19, 19, 0.12) 60%,
            transparent);
}

@keyframes shimmer {
    100% {
        transform: translateX(100%);
    }
}
</style>