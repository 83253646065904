import axios from 'axios';
import { ref } from 'vue';

class SettingsService {
    constructor() {
        if (SettingsService.instance) {
            return SettingsService.instance;
        }
        SettingsService.instance = this;

        this.settings = ref(null);
        this.loading = ref(false);
        this.error = ref(null);
        this.initialized = ref(false);
    }

    async fetch() {
        // Return existing settings if already loaded
        if (this.initialized.value && this.settings.value) {
            return this.settings.value;
        }

        // Prevent multiple simultaneous fetches
        if (this.loading.value) {
            return new Promise((resolve) => {
                const checkInterval = setInterval(() => {
                    if (!this.loading.value) {
                        clearInterval(checkInterval);
                        resolve(this.settings.value);
                    }
                }, 100);
            });
        }

        try {
            this.loading.value = true;
            const { data } = await axios.get('/settings');

            if (data.success) {
                this.settings.value = data;
                this.initialized.value = true;
                return data;
            }
            throw new Error('Failed to fetch settings');
        } catch (error) {
            this.error.value = error;
            console.error('Settings fetch error:', error);
            throw error;
        } finally {
            this.loading.value = false;
        }
    }

    get() {
        return this.settings.value;
    }

    getDiscountLimits(currency = 'rub') {
        return this.settings.value?.discountLimits?.[currency];
    }

    getValidationLimits(currency = 'rub') {
        return this.settings.value?.amountValidation?.[currency];
    }

    getOrderCount() {
        return this.settings.value?.order_count;
    }
}

export default new SettingsService();