<template>
    <div class="topline lk">
        <div class="site-width">
            <div class="flex">
                <div class="info">{{ infoMessage }}</div>
                <a href="#" class="login">Узнать подробнее</a>
                <div class="exit"></div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'InfoBlock',
    props: {
        infoMessage: {
            type: String,
            required: false,
        }
    }
}
</script>