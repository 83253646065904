<template>
    <div class="section-contacts">
        <div class="site-width">
            <h1>Контакты</h1>
            <div class="cont"></div>
            <p class="email">{{ companyMail }}</p>
            <div class="support-links">
                <a href="javascript:jivo_api.open()" class="support-chat">Открыть чат с поддержкой</a>
                <span>или</span>
                <a href="https://vk.me/steammoney" target="_blank" class="vk-support">написать сообщение через группу VK</a>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ContactsView',
    data() {
        return {
            companyName: import.meta.env.VITE_GG_COMPANY_NAME || import.meta.env.VITE_COMPANY_NAME || 'ИП ШАЙДУЛЛИН ИЛЬЯ РАДИКОВИЧ',
            companyOgrn: import.meta.env.VITE_GG_COMPANY_OGRN || import.meta.env.VITE_COMPANY_OGRN || '323547600067847',
            companyInn: import.meta.env.VITE_GG_COMPANY_INN || import.meta.env.VITE_COMPANY_INN || '540200606493',
            companyAddress: import.meta.env.VITE_GG_COMPANY_ADDRESS || import.meta.env.VITE_COMPANY_ADDRESS || '630029, Российская Федерация, Новосибирская область, город Новосибирск, улица 2-ая Ракитная, дом 67А',
            companyMail: import.meta.env.VITE_GG_COMPANY_MAIL || import.meta.env.VITE_COMPANY_MAIL || 'info@steam.money'
        }
    },
    mounted() {
        // Устанавливаем CSS-переменные напрямую с кавычками для строковых значений
        document.documentElement.style.setProperty('--COMPANY_NAME', `"${this.companyName}"`);
        document.documentElement.style.setProperty('--COMPANY_OGRN', `"${this.companyOgrn}"`);
        document.documentElement.style.setProperty('--COMPANY_INN', `"${this.companyInn}"`);
        document.documentElement.style.setProperty('--COMPANY_ADDRESS', `"${this.companyAddress}"`);
    }

}

</script>

<style scoped>
.section-contacts {
    padding: 40px 0;
    color: #fff;
    min-height: calc(100vh - 200px);
}

body.light h1 {
    color: var(--Text-Primary, #131313);
}

h1 {
    font-size: 48px;
    margin-bottom: 40px;
}

.cont {
    font-size: 16px;
    color: #808080;
    margin-bottom: 40px;
    line-height: 1.5;
}

.cont::before {
    content: "Режим оказания услуг: Круглосуточно, в автоматическом режиме \A\A"
        var(--COMPANY_NAME) " \A\AОГРНИП " var(--COMPANY_OGRN) " \AИНН " var(--COMPANY_INN) " \A\A " var(--COMPANY_ADDRESS);
    white-space: pre;
    display: block;
}

.email {
    color: #808080;
    font-size: 16px;
    margin-bottom: 0;
}

.support-links {
    margin-top: 20px;
    display: flex;
    gap: 8px;
    align-items: center;
    font-size: 16px;
}

.support-links a {
    color: #fff;
    text-decoration: underline;
    transition: opacity 0.2s;
}

.support-links a:hover {
    opacity: 0.8;
}

.support-links span {
    color: #808080;
}

body.light .support-chat,
body.light .vk-support {
    color: var(--Text-Secondary, rgba(19, 19, 19, 0.72));
}

@media (max-width: 768px) {
    .section-contacts {
        padding: 20px;
        min-height: calc(100vh - 160px);
    }

    .site-width {
        padding: 0;
    }

    h1 {
        font-size: 32px;
        margin-bottom: 24px;
    }

    .cont {
        font-size: 14px;
        margin-bottom: 24px;
    }

    .cont::before {
        content: "Режим оказания услуг: \AКруглосуточно, \Aв автоматическом режиме \A\AИП ШАЙДУЛЛИН ИЛЬЯ РАДИКОВИЧ \A\AОГРНИП 323547600067847 \AИНН 540200606493 \A\A 630029, Российская Федерация, \AНовосибирская область, \Aгород Новосибирск, \Aулица 2-ая Ракитная, дом 67А";
        white-space: pre;
        line-height: 1.4;
    }

    .email {
        font-size: 14px;
    }

    .support-links {
        margin-top: 16px;
        flex-direction: column;
        align-items: flex-start;
        gap: 12px;
        font-size: 14px;
    }

    .support-links span {
        display: none;
    }

    .support-links a {
        width: 100%;
        text-align: left;
    }
}

@media (max-width: 480px) {
    .section-contacts {
        padding: 16px;
    }

    h1 {
        font-size: 28px;
        margin-bottom: 20px;
    }
}
</style>