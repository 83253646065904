import axios from 'axios';
import { useAuthStore } from '@/store/auth';

class OrdersService {
    constructor() {
        if (OrdersService.instance) {
            return OrdersService.instance;
        }
        OrdersService.instance = this;
    }

    async getOrder(orderId) {
        try {
            const authStore = useAuthStore();
            if (!authStore.token) {
                throw new Error('Пользователь не авторизован');
            }

            const response = await axios.get('/users/orders/one', {
                params: { id: orderId },
                headers: { 'Authorization': `${authStore.tokenType} ${authStore.token}` }
            });
            // console.log('response order', response)
            if (!response.data?.status) {
                await authStore.refreshToken();
                return this.getOrder(orderId);
            }
            // console.log('order', response.data.order)  
            return response.data.order;
             
        } catch (error) {
            if (error.response?.status === 401) {
                const authStore = useAuthStore();
                await authStore.refreshToken();
                return this.getOrder(orderId);
            }
            console.error('Ошибка при получении заказа:', error);
            throw error;
        }
    }


    async getOrders(page = 0, serviceId = null) {
        try {
            const authStore = useAuthStore();
            if (!authStore.token) {
                throw new Error('Пользователь не авторизован');
            }

            const params = {
                currentPage: page
            };

            // Добавляем параметр service только если он указан
            if (serviceId) {
                params.service = serviceId;
            }

            const response = await axios.get('/users/orders/list', {
                params,
                headers: { 'Authorization': `${authStore.tokenType} ${authStore.token}` }
            });

            if (!response.data?.status) {
                await authStore.refreshToken();
                return this.getOrders(page, serviceId);
            }

            return response.data;
        } catch (error) {
            if (error.response?.status === 401) {
                const authStore = useAuthStore();
                await authStore.refreshToken();
                return this.getOrders(page, serviceId);
            }
            console.error('Ошибка при получении заказов:', error);
            throw error;
        }
    }
    async getTransactions(page = 0) {
        try {
            const authStore = useAuthStore();
            if (!authStore.token) {
                throw new Error('Пользователь не авторизован');
            }

            // console.log('Fetching transactions for page:', page);
            const response = await axios.get('/users/transactions/list', {
                params: { currentPage: page },
                headers: { 'Authorization': `${authStore.tokenType} ${authStore.token}` }
            });

            // console.log('Transactions response:', response.data);

            if (!response.data?.status) {
                await authStore.refreshToken();
                return this.getTransactions(page);
            }

            return response.data;
        } catch (error) {
            console.error('Failed to fetch transactions:', error);
            throw error;
        }
    }

    formatOrderStatus(status) {
        const statusMap = {
            'success': 'Оплачен',
            'init': 'Оплатить',
            'error': 'Ошибка оплаты',
            'pending': 'В обработке',
            'canceled': 'Платеж отменен'
        };
        return statusMap[status] || status;
    }

    formatTransactionType(type) {
        const typeMap = {
            'cashback': 'Кэшбэк за транзакцию',
            'ref_bonus': 'Отчисление за транзакцию реферала',
            'social_bonus': 'Подписка на соц. сети',
            'promo_creation': 'Создание промокода'
        };
        return typeMap[type] || type;
    }

    formatPrice(price) {
        if (!price) return '0';
        return new Intl.NumberFormat('ru-RU').format(price);
    }

    formatDate(dateString) {
        try {
            return new Date(dateString).toLocaleDateString('ru-RU');
        } catch {
            return dateString;
        }
    }
    async getServiceFilters() {
        try {
            const authStore = useAuthStore();
            if (!authStore.token) {
                throw new Error('Пользователь не авторизован');
            }

            const response = await axios.get('/users/orders/service-filters', {
                headers: { 'Authorization': `${authStore.tokenType} ${authStore.token}` }
            });

            if (!response.data?.status) {
                await authStore.refreshToken();
                return this.getServiceFilters();
            }

            return response.data.list || [];
        } catch (error) {
            if (error.response?.status === 401) {
                const authStore = useAuthStore();
                await authStore.refreshToken();
                return this.getServiceFilters();
            }
            console.error('Ошибка при получении фильтров:', error);
            throw error;
        }
    }

}

export default new OrdersService();