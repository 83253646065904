import axios from 'axios';

export const referralsAPI = {
    async fetchReferralStats() {
        try {
            const response = await axios.get('/users/ref/stats');
            return response.data;
        } catch (error) {
            console.error('Ошибка получения статистики рефералов:', error);
            throw error;
        }
    },

    async fetchReferralList(page = 0) {
        try {
            const response = await axios.get('/users/ref/list', {
                params: { currentPage: page }
            });
            return response.data;
        } catch (error) {
            console.error('Ошибка при получении списка рефералов:', error);
            throw error;
        }
    }
};