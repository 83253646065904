export default {
    referrals: {
        header: {
            title: "Приглашай друзей и зарабатывай!",
            description: "За каждого приглашенного участника начисляются баллы",
            program: {
                title: `Как заработать с реферальной
программой?`,
                steps: [
                    {
                        number: 1,
                        text: "Выберите продукт, который может быть интересен другу"
                    },
                    {
                        number: 2,
                        text: "Скопируйте и отправьте ссылку"
                    },
                    {
                        number: 3,
                        text: "Получай бонусы за его первый заказ!"
                    }
                ]
            }
        },
        services: {
            title: "Для каждого сервиса — своя ссылка и бонусы",
            warning: "Будьте внимательны: поделившись неправильной ссылкой, бонусы могут потеряться("
        },
        steamGifts: {
            title: "Покупка гифтов на Steam Money",
            bonus: {
                amount: "+100",
                text: "бонусных рублей за первый заказ друга"
            }
        },
        links: {
            referral: "Реферальная ссылка клиента",
            promo: "или используйте реферальный промокод",
            copy: "Скопировать",
            copied: "Скопировано",
            notFound: "Код не найден"
        },
        stats: {
            invitedUsers: "Приглашено человек",
            earned: "Заработано",
            currentBalance: "Текущий баланс",
            history: {
                title: "История покупок по твоим ссылкам",
                warning: "Будьте внимательны: поделившись неправильной ссылкой, бонусы могут потеряться(",
                loading: "Загрузка ...",
                columns: {
                    login: "Логин",
                    date: "Дата",
                    amount: "Сумма",
                    points: "Баллы"
                }
            }
        },
        faq: {
            title: "Остались вопросы?",
            accountLogin: {
                question: "Как узнать логин Аккаунта?",
                answer: "Чтобы осуществить пополнение нового стим аккаунта и оставить валюту кошелька - рубли ₽, пользователю нужно добавить в steam библиотеку бесплатную игру, например PUBG, подождать в течение 10 минут и осуществить пополнение аккаунта. Таким способом поступит баланс в рублях. Это работает при учете того, что ваш текущий регион Россия и не используется VPN других стран."
            }
        },
        balance: {
            course: "=1 ₽",
            mobile: {
                course: "1 =1 ₽"
            },
            errors: {
                refresh: "Ошибка обновления баланса",
                copy: "Ошибка копирования",
                modal: "Ошибка открытия модального окна"
            }
        },
        errors: {
            general: "Произошла ошибка. Повторите позже",
            connection: "Ошибка соединения",
            serverError: "Ошибка сервера"
        },
        buttons: {
            refresh: "Обновить",
            copy: "Скопировать",
            close: "Закрыть"
        },
        loading: {
            default: "Загрузка...",
            data: "Загрузка данных...",
            balance: "Обновление баланса..."
        },
        validation: {
            required: "Обязательное поле",
            invalid: "Некорректное значение",
            error: "Ошибка валидации"
        },
        modals: {
            generatePromo: {
                title: "Генерация промокода",
                success: "Промокод успешно сгенерирован",
                error: "Ошибка генерации промокода"
            }
        }
    }
};