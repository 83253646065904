import { defineStore } from 'pinia';
import axios from 'axios';
import { authAPI } from '../service/api/auth_api';


export const useAuthStore = defineStore('auth', {
    state: () => ({
        token: localStorage.getItem('token') || null,
        tokenType: localStorage.getItem('token_type') || 'Bearer',
        expiresIn: parseInt(localStorage.getItem('expires_in')) || null,
        user: (() => {
            try {
                return JSON.parse(localStorage.getItem('user')) || null;
            } catch {
                return null;
            }
        })(),
        loading: false,
        error: null,
        isMenuOpen: false,
        refreshingPromise: null
    }),

    getters: {
        bonusesReceived: (state) => state.user?.bonuses_received,
        bonusesSpent: (state) => state.user?.bonuses_spent,
        isAuthenticated: (state) => !!state.token,
        userBalance: (state) => state.user?.balance || 0,
        userAvatar: (state) => state.user?.avatar || state.user?.photo_url,
        userName: (state) => state.user?.name || state.user?.first_name,
        userEmail: (state) => state.user?.email || '',
        userRefCode: (state) => state.user?.refCode || '',
        isTgBonusDone: (state) => state.user?.tg_bonus_done || false,
        isVkBonusDone: (state) => state.user?.vk_bonus_done || false,
    },

    actions: {
        toggleMenu() {
            this.isMenuOpen = !this.isMenuOpen;
        },

        closeMenu() {
            this.isMenuOpen = false;
        },

        setAuthData(authData) {
            this.token = authData.access_token;
            this.tokenType = authData.token_type || 'Bearer';

            if (authData.expires_in) {
                this.expiresIn = authData.expires_in;
                localStorage.setItem('expires_in', this.expiresIn);
            }

            localStorage.setItem('token', this.token);
            localStorage.setItem('token_type', this.tokenType);

            this.setAuthHeader();
            this.setupTokenRefresh();
        },

        setupTokenRefresh() {
            if (this.expiresIn) {
                setTimeout(async () => {
                    if (this.token) {
                        await this.refreshToken();
                    }
                }, this.expiresIn * 1000);
            }
        },

        setAuthHeader() {
            if (this.token) {
                axios.defaults.headers.common['Authorization'] = `${this.tokenType} ${this.token}`;
            }
        },

        clearAuth() {
            this.token = null;
            this.tokenType = null;
            this.expiresIn = null;
            this.user = null;
            this.isMenuOpen = false;

            localStorage.removeItem('token');
            localStorage.removeItem('token_type');
            localStorage.removeItem('expires_in');
            localStorage.removeItem('user');

            delete axios.defaults.headers.common['Authorization'];
        },

        async initAuth() {
            if (this.token) {
                this.setAuthHeader();
                try {
                    await this.fetchUserData();
                    return true;
                } catch (error) {
                    console.error('Auth initialization failed:', error);
                    this.clearAuth();
                    return false;
                }
            }
            return false;
        },

        async fetchUserData() {
            try {
                const { data } = await authAPI.fetchUserProfile();
                if (data && data.balance !== undefined) {
                    const userData = {
                        ...this.user,
                        ...data,
                    };
                    this.user = userData;
                    localStorage.setItem('user', JSON.stringify(userData));
                    return userData;
                }
                throw new Error('Invalid user data format');
            } catch (error) {
                if (error.response?.status === 401) {
                    const refreshed = await this.refreshToken();
                    if (refreshed) {
                        return this.fetchUserData();
                    }
                }
                throw error;
            }
        },

        async refreshUserData() {
            try {
                await this.fetchUserData();
                return true;
            } catch (error) {
                console.error('Failed to refresh user data:', error);
                return false;
            }
        },

        async loginWithTelegram(userData) {
            this.loading = true;
            this.error = null;

            try {
                const { data } = await authAPI.loginWithSocCallback(userData);
                if (data.status && data.access_token) {
                    this.setAuthData(data);
                    this.user = {
                        ...userData,
                        photo_url: data.avatar || userData.photo_url,
                        first_name: userData.first_name
                    };
                    await this.fetchUserData();
                    return true;
                }
                throw new Error(data.message || 'Authentication failed');
            } catch (error) {
                console.error('Telegram auth error:', error);
                this.error = error.response?.data?.message || 'Authentication failed';
                return false;
            } finally {
                this.loading = false;
            }
        },

        async loginWithVk({ code, device_id, code_verifier, state, ref_code }) {
            this.loading = true;
            this.error = null;

              

            try {
                const { data } = await authAPI.loginWithVk({
                    code,
                    device_id,
                    code_verifier,
                    state,
                    
                });

                if (data.status && data.access_token && data.expires_in) {
                    if (this.refreshToken) {
                        clearTimeout(this.refreshToken);
                    }

                    this.setAuthData({
                        access_token: data.access_token,
                        token_type: data.token_type,
                        expires_in: data.expires_in
                    });

                    this.user = {
                        ...this.user,
                        avatar: data.avatar
                    };

                    await this.fetchUserData();
                    return true;
                }

                throw new Error('Не удалось получить токен доступа');
            } catch (error) {
                console.error('VK login error:', error);
                this.error = error.response?.data?.message || error.message;
                return false;
            } finally {
                this.loading = false;
            }
        },

        async logout() {
            try {
                await authAPI.logout();
            } catch (error) {
                console.error('Logout error:', error);
            } finally {
                this.clearAuth();
            }
        },

        async refreshToken() {
            if (this.refreshingPromise) {
                return this.refreshingPromise;
            }

            const lastRefreshTime = parseInt(localStorage.getItem('last_refresh_time'));
            const now = Date.now();

            if (lastRefreshTime && this.expiresIn) {
                const timeSinceLastRefresh = (now - lastRefreshTime) / 1000; 
                if (timeSinceLastRefresh < this.expiresIn) {
                    return false;
                }
            }

            try {
                this.refreshingPromise = authAPI.refreshToken();
                const { data } = await this.refreshingPromise;

                if (data.status && data.access_token && data.expires_in) {
                    localStorage.setItem('last_refresh_time', now.toString());

                    this.setAuthData({
                        access_token: data.access_token,
                        token_type: data.token_type,
                        expires_in: data.expires_in
                    });
                    return true;
                }

                this.clearAuth();
                return false;
            } catch (error) {
                console.error('Token refresh failed:', error);
                this.clearAuth();
                return false;
            } finally {
                this.refreshingPromise = null;
            }
        },
    },
});