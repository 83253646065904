import { createRouter, createWebHistory } from 'vue-router';
import MainView from '../views/MainView.vue';
import ContactsView from '../views/Footer/ContactsView.vue';
import NotFound from '../views/404/404.vue';
import Profile from '@/components/ProFile/Screen/Profile.vue';
import TelegramCallback from '@/auth/Components/TelegramCallBack.vue';
import VkCallback from '@/auth/Components/VkCallBack.vue';
import { redirectAPI } from '@/service/api/redirect';

import ProfileReferrals from '@/components/ProFile/Components/ProfileReferrals.vue';
import ProfilePurchases from '@/components/ProFile/Components/ProfilePurchases.vue';
import ProfileBonuses from '@/components/ProFile/Components/ProfileBonuses.vue';
import ProfileOverview from '@/components/ProFile/Components/ProfileOverview.vue';

const isAuthenticated = () => {
  return !!localStorage.getItem('token');
};

const routes = [
  {
    path: '/',
    name: 'main',
    component: MainView,
  },
  {
    path: '/kazakhstan',
    name: 'kazakhstan',
    component: MainView,
  },
  {
    path: '/cards',
    name: 'cards',
    component: MainView,
  },
  {
    path: '/pubg-uc',
    name: 'pubg',
    component: MainView,
  },
  {
    path: '/contacts',
    name: 'contacts',
    component: ContactsView,
  },
  {
    path: '/profile',
    component: Profile,
    meta: { requiresAuth: true },
    children: [
      {
        path: '/order/:id',
        name: 'order-details',
        component: () => import('@/components/ProFile/Components/ProfilePaymentInfo.vue'),
        props: route => ({
          orderId: route.params.id,
          orderData: route.query.data ? JSON.parse(route.query.data) : null
        })
      },
      {
        path: '',
        name: 'profile-overview',
        component: ProfileOverview,
      },
      {
        path: 'referrals',
        name: 'profile-referrals',
        component: ProfileReferrals,
      },
      {
        path: 'purchases',
        name: 'profile-purchases',
        component: ProfilePurchases,
      },
      {
        path: 'bonuses',
        name: 'profile-bonuses',
        component: ProfileBonuses,
      },
    ],
  },
  {
    path: '/auth/vk-link-callback',
    name: 'vk-link-callback',
    component: () => import('@/components/VkLinkCallback.vue'),
  },
  {
    path: '/auth/telegram-callback',
    name: 'telegram-callback',
    component: TelegramCallback,
  },
  {
    path: '/auth/vk-callback',
    name: 'vk-callback',
    component: VkCallback,
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'not-found',
    component: NotFound,
    beforeEnter: async (to, from, next) => {
      try {
        const path = to.path.substring(1);
        const data = await redirectAPI.checkRedirect(path);

        if (data.status && data.redirect) {
          window.location.href = data.redirect;
        } else {
          next();
        }
      } catch (error) {
        console.error('Ошибка проверки редиректа:', error);
        next();
      }
    }
  }
];

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, from, next) => {
  if (to.path.includes('/auth/telegram-callback') || to.path.includes('/auth/vk-callback')) {
    next();
    return;
  }

  const canonicalUrls = {
    '/': 'https://steam.money/',
    '/kazakhstan': 'https://steam.money/kazakhstan',
    '/cards': 'https://steam.money/cards'
  };

  const canonicalLink = document.querySelector('link[rel="canonical"]');
  if (canonicalLink) {
    canonicalLink.href = canonicalUrls[to.path] || 'https://steam.money/';
  }

  if (to.query.ref || to.query.r) {
    const refCode = to.query.ref || to.query.r;

    localStorage.setItem('referral_code', refCode);
    localStorage.setItem('referral_code_timestamp', new Date().getTime().toString());
  }

  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (isAuthenticated()) {
      next();
    } else {
      next('/');
    }
  } else {
    if (to.matched.length === 0) {
      try {
        const path = to.path.substring(1);
        const data = await redirectAPI.checkRedirect(path);

        if (data.status && data.redirect) {
          window.location.href = data.redirect;
          return;
        }
      } catch (error) {
        console.error('Ошибка проверки редиректа:', error);
      }
    }
    next();
  }
});

router.afterEach((to) => {
  if (to.name === 'vk-callback' && to.query.payload) {
    console.log('VK callback received:', to.query);
  }


});

export default router;