<template>
    <div class="topline close" v-if="warningMessage">
        <div class="site-width">
            <div class="flex">
                <div class="info"><b>{{ warningMessage }}</b></div>
                <a href="https://t.me/steam_money_pay" class="tg" target="_blank">Telegram channel</a>
                <div class="exit"></div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'WarningBlock',
    props: {
        warningMessage: {
            type: String,
            required: false
        }
    }
}
</script>

<style>
.topline.close {
    background: url(../images/top-bg-close.png) no-repeat center center;
    background-size: cover;
    padding: 8px 0;
    color: rgba(255, 255, 255, 0.79);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
}
</style>