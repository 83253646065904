<template>
    <div>
        <template v-if="loading || mainLoading || isRefreshing || !cards?.length">
            <div class="select-turkiye">
                <label v-for="n in 3" :key="n">
                    <div class="skeleton-animation">
                        <div class="turkiye-item">
                            <img src="/images/flag-ru.svg" class="skeleton-img" alt="" />
                        </div>
                    </div>
                </label>
            </div>
            <div class="select-other-pay">
                <label v-for="n in 4" :key="n">
                    <div class="skeleton-animation">
                        <div class="block">
                            <div class="icon">
                                <img src="/images/steam.svg" class="skeleton-img" />
                            </div>
                            <div class="other-info">
                                <div class="price">
                                </div>
                            </div>
                        </div>
                    </div>
                </label>
            </div>
        </template>
        <template v-else-if="error">
            <div class="error">{{ error }}</div>
        </template>
        <template v-else-if="cards && cards.length">
            <div class="select-turkiye">
                <label v-for="country in availableCountries" :key="country">
                    <input type="radio" name="turkiye1" :value="country" v-model="selectedCountry"
                        :checked="country === selectedCountry" />
                    <div class="turkiye-item">
                        <img :src="getCountryFlag(country)" :alt="country" />
                        {{ country }}
                    </div>
                </label>
            </div>
            <div class="select-other-pay">
                <label v-for="card in filteredCards" :key="card.id">
                    <input type="radio" name="radio5" :value="card.id" v-model="selectedCard" />
                    <div class="block">
                        <div class="icon">
                            <img src="/images/steam.svg" />
                        </div>
                        <div class="other-info">
                            <div class="other-name">{{ card.country }}</div>
                            <div class="price">
                                <span>{{ card.cardPrice }} {{ card.currency }}</span>
                                <span>{{ formatPrice(card.price) }} ₽</span>
                            </div>
                        </div>
                    </div>
                </label>
            </div>
        </template>
    </div>
</template>

<script>
export default {
    name: 'SteamCardsFilter',

    data() {
        return {
            cards: [],
            selectedCard: null,
            selectedCountry: 'Турция',
            mainLoading: true,
            loading: false,
            error: null,
            lastSelectedCard: null,
            isRefreshing: false,
            refreshAttempts: 0,
            maxRefreshAttempts: 3,
        }
    },

    computed: {
        availableCountries() {
            // console.log('Computing available countries:', this.cards);
            if (!Array.isArray(this.cards)) {
                // console.warn('Cards is not an array:', this.cards);
                return [];
            }
            const countries = [...new Set(this.cards.map(card => card.country))];
            // console.log('Available countries:', countries);
            return countries;
        },

        filteredCards() {
            // console.log('Filtering cards for country:', this.selectedCountry);
            if (!Array.isArray(this.cards)) {
                // console.warn('Cards is not an array:', this.cards);
                return [];
            }
            const filtered = this.cards.filter(card => card.country === this.selectedCountry);
            // console.log('Filtered cards:', filtered);
            return filtered;
        }
    },

    async created() {
        // console.log('Component created');
        this.mainLoading = true;
        await this.fetchCards();
    },

    mounted() {
        // console.log('Component mounted');
    },

    updated() {
        // console.log('Component updated', {
        //     cards: this.cards,
        //     selectedCountry: this.selectedCountry,
        //     filteredCards: this.filteredCards
        // });
    },

    methods: {
        async fetchCards() {
            try {
                // console.log('Fetching cards...');
                const response = await this.axios.get('/calc/cards/grid');
                // console.log('API Response:', response.data);

                if (response.data.status) {
                    this.cards = response.data.cards;
                    // console.log('Cards set to:', this.cards);

                    if (this.lastSelectedCard) {
                        const cardStillExists = this.cards.find(card => card.id === this.lastSelectedCard);
                        if (cardStillExists) {
                            this.selectedCard = this.lastSelectedCard;
                        } else {
                            this.selectedCard = this.filteredCards.length > 0 ? this.filteredCards[0].id : null;
                        }
                    } else if (this.filteredCards.length > 0) {
                        this.selectedCard = this.filteredCards[0].id;
                    }

                    this.mainLoading = false;
                    this.refreshAttempts = 0;
                } else {
                    throw new Error('Failed to load cards: ' + (response.data.message || 'Unknown error'));
                }
            } catch (error) {
                // console.error('Error in fetchCards:', error);
                this.error = 'Ошибка при загрузке карточек: ' + error.message;
                this.mainLoading = false;
                throw error;
            }
        },

        async handleBackendError(error) {
            const priceRelatedErrors = [
                'price changed',
                'card unavailable',
                'invalid price',
                'card not found',
            ];

            const errorMessage = error?.response?.data?.message || error.message;
            const shouldRefresh = priceRelatedErrors.some(phrase =>
                errorMessage.toLowerCase().includes(phrase.toLowerCase())
            );

            if (shouldRefresh && this.refreshAttempts < this.maxRefreshAttempts) {
                // console.log(`Attempting refresh ${this.refreshAttempts + 1}/${this.maxRefreshAttempts}`);
                this.refreshAttempts++;
                this.lastSelectedCard = this.selectedCard;
                this.isRefreshing = true;

                try {
                    await this.fetchCards();
                    return true;
                } catch (err) {
                    // console.error('Error during refresh:', err);
                    return false;
                } finally {
                    this.isRefreshing = false;
                }
            }

            return false;
        },

        formatPrice(price) {
            return new Intl.NumberFormat('ru-RU', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            }).format(price)
        },

        getCountryFlag(country) {
            const flagMap = {
                'Турция': '/images/TR.svg',
                'Аргентина': '/images/AR.svg',
                'США': '/images/US.svg'
            }
            return flagMap[country] || '/images/flag-other.svg'
        }
    },

    watch: {
        async selectedCard(newValue) {
            // console.log('Selected card changed:', newValue);
            if (!newValue) return;

            const selectedCardData = this.cards.find(card => card.id === newValue);
            if (selectedCardData) {
                try {
                    // console.log('Emitting card-selected with:', selectedCardData);
                    this.$emit('card-selected', {
                        id: selectedCardData.id,
                        price: selectedCardData.price,
                        cardPrice: selectedCardData.cardPrice,
                        currency: selectedCardData.currency,
                        country: selectedCardData.country
                    });
                } catch (error) {
                    // console.error('Error in selectedCard watch:', error);
                    const wasRefreshed = await this.handleBackendError(error);
                    if (!wasRefreshed) {
                        throw error;
                    }
                }
            }
        },

        selectedCountry(newValue) {
            // console.log('Selected country changed:', newValue);
            this.loading = true;

            setTimeout(() => {
                this.loading = false;
                if (this.filteredCards.length > 0) {
                    this.selectedCard = this.filteredCards[0].id;
                    // console.log('New card selected:', this.selectedCard);
                }
                this.$emit('country-selected', newValue);
            }, 400);
        }
    }
}
</script>

<style scoped>
.skeleton-animation {
    position: relative;
    overflow: hidden;
}

.skeleton-animation::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background: linear-gradient(90deg,
            transparent 0,
            rgba(255, 255, 255, 0.1) 20%,
            rgba(255, 255, 255, 0.2) 60%,
            transparent);
    animation: shimmer 2s infinite;
}

body.light .skeleton-animation::after {
    background: linear-gradient(90deg,
            transparent 0,
            rgba(0, 190, 58, 0.08) 20%,
            rgba(0, 190, 58, 0.12) 60%,
            transparent);
}

@keyframes shimmer {
    100% {
        transform: translateX(100%);
    }
}

.skeleton-img {
    filter: grayscale(100%) brightness(95%) contrast(90%);
    opacity: 0.2;
}

.error {
    color: red;
    padding: 1rem;
    text-align: center;
}
</style>