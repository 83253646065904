<template>
    <div class="section-referals-six">
        <div class="mobile">
            <div class="course">
                1
                <img src="/images/stm-coin.svg" class="theme-icon" alt="Монета" />
                = 1 ₽
            </div>
        </div>
        <div class="total">
            <template v-if="isBalanceLoading">
                <div style="display: flex; align-items: center; gap: 8px;">
                    <div class="skeleton-animation" style="width: 80px; height: 32px;"></div>
                    <div class="skeleton-animation" style="width: 24px; height: 24px; border-radius: 50%;"></div>
                </div>
            </template>
            <template v-else>
                {{ userBalance }} <img src="/images/stm-coin.svg" class="theme-icon" alt="Монета" />

            </template>
        </div>


        <div class="button">
            <RefreshButton :is-loading="isRefreshing" @click="openGeneratePromo" />
        </div>
    </div>

    <div class="section-promo-one">
        <div class="title">Промокоды</div>
        <p>
            Обменивай накопленные SM бонусы на промокоды и применяй их для скидки при оплате
            <!-- <a href="#">Читать подробнее</a> -->
        </p>
    </div>
    <div class="section-promo-two tabs">
        <ul class="tabs__caption">
            <li :class="{ active: activeTab === 'all' }" @click="setActiveTab('all')">Все</li>
            <li :class="{ active: activeTab === 'active' }" @click="setActiveTab('active')">Активные</li>
            <li :class="{ active: activeTab === 'used' }" @click="setActiveTab('used')">Не активные</li>
        </ul>
        <div class="tabs__contents" :class="{ active: true }">
            <template v-if="loading">
                <div class="promo-list">
                    <div v-for="i in 1" :key="i" class="promo-item">
                        <div class="promo-code">
                            <div class="input">
                                <div class="skeleton-animation" style="width: 100%; height: 24px;"></div>
                                <div class="copy skeleton-animation" style="width: 80px; height: 24px;"></div>
                            </div>
                        </div>
                        <div class="promo-details">
                            <div class="promo-value skeleton-animation" style="width: 70px; height: 24px;"></div>
                            <div class="promo-status skeleton-animation" style="width: 90px; height: 24px;"></div>
                            <div class="promo-date skeleton-animation" style="width: 100px; height: 24px;"></div>
                        </div>
                    </div>
                </div>
            </template>
            <template v-else-if="filteredPromos.length === 0">
                <div class="error-referrals-empty">
                    <div class="name">Здесь пока пусто</div>
                    <p class="desc">
                        Здесь пока пусто <br>
                        но если вы обменяете накопившиеся SM бонусы на промокод, тут появится история
                    </p>
                </div>
            </template>

            <template v-else>
                <div class="promo-list">
                    <div v-for="promo in filteredPromos" :key="promo.id" class="promo-item">
                        <div class="promo-code">
                            <div class="input">
                                <input type="text" :value="promo.code" readonly>
                                <div class="copy" :class="{ 'copied': copyStatuses[promo.code] === 'Скопировано' }"
                                    @click="() => copyPromo(promo.code)">
                                    {{ getCopyStatus(promo.code) }}
                                </div>

                            </div>
                        </div>
                        <div class="promo-details">
                            <div class="promo-value">{{ formatCouponValue(promo.couponVal) }} ₽</div>
                            <div class="promo-status" :class="{ 'status-used': promo.isDone }">
                                {{ promo.isDone ? 'Использован' : 'Активен' }}
                            </div>
                            <div class="promo-date">
                                {{ formatDate(promo.exp) }}
                            </div>
                        </div>
                    </div>
                    <button v-if="promoStore.promoList.length > 0 && promoStore.hasMore" class="pagination-bottom"
                        :disabled="promoStore.loading" @click="handleLoadMore">
                        {{ promoStore.loading ? 'Загрузка...' : 'Показать еще' }}
                    </button>


                </div>

            </template>
        </div>

        <div class="item plus">
            <div class="col">Получено бонусов</div>
            <div class="price" v-if="loading">
                <div class="skeleton-animation" style="width: 80px; height: 24px; margin-right: 8px;"></div>
                <div class="skeleton-animation" style="width: 24px; height: 24px; border-radius: 50%;"></div>
            </div>
            <div class="price" v-else>
                + {{ userbonusesReceived || 0 }}
                <div class="coin-plus"></div>
            </div>
        </div>

        <div class="item minus">
            <div class="col">Потрачено бонусов</div>
            <div class="price" v-if="loading">
                <div class="skeleton-animation" style="width: 80px; height: 24px; margin-right: 8px;"></div>
                <div class="skeleton-animation" style="width: 24px; height: 24px; border-radius: 50%;"></div>
            </div>
            <div class="price" v-else>
                - {{ userbonusesSpent || 0 }}
                <div class="coin-minus"></div>
            </div>
        </div>
    </div>

    <div class="section-promo-three">
        <div class="title">Как получить промокод?</div>
        <ul>
            <li><span>1</span>Нажмите "Сгенерировать промокод" / "Обменять"</li>
            <li><span>2</span>Выберите сумму, на которую будет создан промокод</li>
            <li><span>3</span>Скопируйте промокод и примените его при следующей покупке</li>
        </ul>
        <button class="btn" @click="openGeneratePromo">Сгенерировать промокод</button>
        <GeneratePromo ref="promoModal" @promo-generated="handlePromoGenerated" />
    </div>

    <div class="section-referals-five">
        <div class="title">Остались вопросы?</div>
        <div v-for="(question, index) in questions" :key="index" class="item-question"
            :class="{ 'active': openedQuestion === index }">
            <div class="question-name" @click="toggleQuestion(index)">
                {{ question.title }}
            </div>
            <div class="answer" v-html="question.content"></div>
        </div>
    </div>

</template>
<script>
import GeneratePromo from './GeneratePromo.vue';
import { useAuthStore } from '@/store/auth';
import { usePromoStore } from '@/service/api/generate_promo';
import RefreshButton from '@/components/RefreshButton/RefreshButton.vue';

// import PromoButton from './PromoButton.vue';

export default {
    name: 'ProfileBonuses',
    components: {
        GeneratePromo,
        RefreshButton,
    },
    data() {
        return {

            isRefreshing: false,
            couponId: null,
            activeTab: 'all',
            loading: false,
            copyStatus: 'Скопировать',
            copyStatuses: {},
            openedQuestion: null,
            questions: [
                {
                    title: 'Коротко: Как работает реферальная система?',
                    content: 'Для приглашения друзей Вам доступны: личная реферальная ссылка и реферальный пригласительный код.<br><br> Если коротко: Вы пригласили друга, он оплатил услугу по вашей ссылке или с использованием вашего кода, вы получаете SM бонусы!<br> Для вашего приглашенного друга первая оплата по вашему реферальному коду дает ему дополнительную скидку :)'
                },
                {
                    title: 'Подробно: Как работает реферальная система?',
                    content: 'При помощи ссылок и реферального кода система сможет отслеживать приглашенных вами пользователей, и Вы будете получать отчисления с их оплат в виде SM бонусов на ваш бонусный счет. Далее Вы сможете использовать эти бонусы для применения скидок внутри нашего сайта.<br><br> Важно: Чтобы приглашенный пользователь закрепился за вами навсегда, попросите его авторизовать на сайте после перехода по вашей ссылке (через VK или Telegram) и Вы будете всегда получать бонусные отчисления со всех его следующих оплат! В ином случае, без авторизации, вы будете получать бонусные отчисления только в течении 90 дней с момента перехода по ссылке'
                },
                {
                    title: 'Как и сколько бонусов я получу за приглашенного друга?',
                    content: 'Вы будете получать процентные отчисления на бонусный счет в зависимости от услуги, которой воспользуется ваш приглашенный друг. <br><br> На примере пополнения STEAM по логину: Вы будете получать 2% на бонусный счет с пополнений по логину, а ваш друг получит скидку 2% на первое пополнение при использовании вашего промокода.<br><br> При добавлении новых сервисов и услуг на сайт мы дополним этот список, где будет информация об отчислениях.'
                },
                {
                    title: 'Я пригласил друга, но мне не начислили бонусы, что делать?',
                    content: 'Начисление бонусов происходит только за новых приглашенных пользователей. Если пользователь когда-то был на нашем сайте в течении последних 180 дней, система не может его засчитать как нового приглашенного пользователя',
                },
                {
                    title: 'Как использовать SM бонусы?',
                    content: `SM бонусы можно обменять на скидочный промокод на <a href="/profile/bonuses" class="router-link">странице бонусов и промокодов</a>. Там же отображаются созданные вами промокоды. Применяйте их при оплате услуг и получайте скидку* за использование промокода.<br><br>
    
 <div class="important-note">
        <span class="note-content">
            <i>*скидка по промокоду действует на сумму комиссии сайта и может быть не больше, чем 50% от комиссии сайта. Оставшаяся и не используемая сумма промокода сгорает при использовании этого промокода.<br><br>
            Например: комиссия сайта 120 рублей, промокод на скидку 100 рублей. 50% от 120 это 60. Ваш промокод на 100 рублей сделает максимальную скидку в 60 рублей, и оставшаяся сумма промокода в 40 рублей сгорит.
        </i></span>
    </div>`
                },
                {
                    title: 'Как получать больше SM бонусов?',
                    content: `Чтобы получать больше SM бонусов, вы можете выполнять задания доступные на <a href="/profile" class="router-link">странице вашего профиля</a> на сайте. А приглашая друзей по <a href="/profile/referrals" class="router-link">реферальной программе</a>, Вы получите еще больше SM бонусов!`
                },
            ],
            // mockPromos: [
            //     {
            //         id: 1,
            //         code: 'xxvb4gsk',
            //         value: 100,
            //         is_done: false,
            //         exp: '2025-01-28T23:59:59',
            //     },
            //     {
            //         id: 2,
            //         code: 'qj1f48d6',
            //         value: 100,
            //         is_done: false,
            //         exp: '2025-01-26T23:59:59',
            //     },
            //     {
            //         id: 3,
            //         code: 'vdmlpc7j',
            //         value: 100,
            //         is_done: false,
            //         exp: '2025-01-26T23:59:59',
            //     },
            //     {
            //         id: 4,
            //         code: 'ie9nke8p',
            //         value: 100,
            //         is_done: false,
            //         exp: '2025-01-26T23:59:59',
            //     },
            //     {
            //         id: 5,
            //         code: 'ujwwegym',
            //         value: 100,
            //         is_done: false,
            //         exp: '2025-01-26T23:59:59',
            //     },
            //     {
            //         id: 6,
            //         code: 'u7zfg3wj',
            //         value: 100,
            //         is_done: false,
            //         exp: '2025-01-26T23:59:59',
            //     },
            //     {
            //         id: 7,
            //         code: '02fmasul',
            //         value: 100,
            //         is_done: false,
            //         exp: '2025-01-26T23:59:59',
            //     },
            //     {
            //         id: 8,
            //         code: '7deg5uey',
            //         value: 100,
            //         is_done: false,
            //         exp: '2025-01-26T23:59:59',
            //     },
            //     {
            //         id: 9,
            //         code: 'js1upg5z',
            //         value: 100,
            //         is_done: false,
            //         exp: '2025-01-26T23:59:59',
            //     },
            // ],

        };
    },
    setup() {
        const authStore = useAuthStore();
        const promoStore = usePromoStore();
        return { authStore, promoStore };
    },
    computed: {
        isBalanceLoading() {
            return !this.authStore.user || this.authStore.loading;
        },
        userBalance() {
            return this.authStore.userBalance;
        },
        userbonusesReceived() {
            return this.authStore.bonusesReceived;
        },
        userbonusesSpent() {
            return this.authStore.bonusesSpent;
        },
        filteredPromos() {
            const promos = this.promoStore.promoList;

            switch (this.activeTab) {
                case 'active':
                    return promos.filter(promo => !promo.isDone);
                case 'used':
                    return promos.filter(promo => promo.isDone);
                default:
                    return promos;
            }
        },
    },
    methods: {
        handleRouterLink(event) {
            if (event.target.classList.contains('router-link')) {
                event.preventDefault();
                this.$router.push(event.target.getAttribute('href'));
            }
        },
        toggleQuestion(index) {
            this.openedQuestion = this.openedQuestion === index ? null : index;
        },
        formatCouponValue(value) {
            const num = Number(value);
            return isNaN(num) ? 0 : num;
        },
        async handleLoadMore() {
            let params = {};
            if (this.activeTab === 'active') {
                params.is_done = 0;
            } else if (this.activeTab === 'used') {
                params.is_done = 1;
            }
            await this.promoStore.loadMore(params);
        },
        async refreshBalance() {
            if (this.isRefreshing) return;

            try {
                this.isRefreshing = true;
                await this.authStore.fetchUserData();
            } catch (error) {
                console.error('Failed to refresh balance:', error);
            } finally {
                this.isRefreshing = false;
            }
        },
        async openGeneratePromo() {
            try {
                if (this.$refs.promoModal) {
                    await this.$refs.promoModal.open();
                }
            } catch (error) {
                console.error('Error opening modal:', error);
            }
        },
        async handlePromoGenerated() {
            this.loading = true;
            try {
                await this.promoStore.resetState();
                await this.fetchPromos();
                await this.authStore.fetchUserData();
            } catch (error) {
                console.error('Error updating promos:', error);
            } finally {
                this.loading = false;
            }
        },
        async setActiveTab(tab) {
            this.loading = true;
            this.activeTab = tab;

            let params = {};
            if (tab === 'active') {
                params.is_done = 0;
            } else if (tab === 'used') {
                params.is_done = 1;
            }

            try {
                // Сбрасываем состояние списка при смене таба
                this.promoStore.resetState();
                await this.promoStore.getPromoList(params);
            } catch (error) {
                console.error('Error fetching promos:', error);
            } finally {
                this.loading = false;
            }
        },

        formatDate(dateString) {
            return new Date(dateString).toLocaleDateString('ru-RU', {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric'
            });
        },

        async fetchPromos() {
            this.loading = true;
            try {
                // Сбрасываем страницу при новой загрузке
                this.promoStore.currentPage = 0;

                let params = {};
                if (this.activeTab === 'active') {
                    params.is_done = 0;
                } else if (this.activeTab === 'used') {
                    params.is_done = 1;
                }
                await this.promoStore.getPromoList(params);
            } catch (error) {
                console.error('Failed to fetch promos:', error);
            } finally {
                this.loading = false;
            }
        },
        async copyPromo(code) {
            try {
                await navigator.clipboard.writeText(code);
                // Напрямую изменяем состояние в объекте
                this.copyStatuses[code] = 'Скопировано';

                // Через 2 секунды возвращаем статус "Скопировать"
                setTimeout(() => {
                    this.copyStatuses[code] = 'Скопировать';
                }, 2000);
            } catch (err) {
                console.error('Ошибка копирования:', err);
            }
        },

        getCopyStatus(code) {
            return this.copyStatuses[code] || 'Скопировать';
        },
    },
    async mounted() {
        // console.log('Component mounted:', this.$route.name);
        // console.log('Modal ref available:', !!this.$refs.promoModal);
        this.fetchPromos();
        document.addEventListener('click', this.handleRouterLink);
    },
    beforeUnmount() {
        document.removeEventListener('click', this.handleRouterLink);
    },
};
</script>
<style>
:deep(.important-note) {
    display: block !important;
    margin: 16px 0 !important;
    padding: 16px !important;
    background: rgba(237, 238, 238, 0.04) !important;
    border-radius: 8px !important;
}

:deep(.note-content) {
    display: block !important;
    font-size: 12px !important;
    line-height: 16px !important;
    color: rgba(237, 238, 238, 0.48) !important;
    font-style: italic !important;
}

/* Светлая тема */
body.light :deep(.important-note) {
    background: rgba(19, 19, 19, 0.04) !important;
}

body.light :deep(.note-content) {
    color: rgba(19, 19, 19, 0.48) !important;
}

.pagination-bottom {
    width: 100%;
    padding: 12px;
    margin-bottom: 20px;
    border-radius: 8px;
    background: var(--Surface-Primary-Default, rgba(237, 238, 238, 0.04));
    color: var(--Text-Primary, #EDEEEE);
    border: none;
    cursor: pointer;
    font-size: 14px;
    transition: all 0.3s ease;
}

.pagination-bottom:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

body.light .pagination-bottom {
    background: var(--Surface-Primary-Default, rgba(19, 19, 19, 0.06));
    color: var(--Text-Primary, #131313);
}

body.light .pagination-bottom:hover:not(:disabled) {
    background: var(--Surface-Accent-Hover, rgba(0, 190, 58, 0.12)) !important;
    /* background: var(--Surface-Primary-Hover, rgba(19, 19, 19, 0.08)); */
}

.pagination-bottom:hover:not(:disabled) {
    background: var(--Surface-Primary-Hover, rgba(237, 238, 238, 0.08));
}

.item-question:hover .question-name:after {
    background: url(../images/question-arrow-hover.svg) no-repeat;
}

.item-question.active .question-name:after {
    background: url(../images/question-arrow-hover.svg) no-repeat;
    transform: rotate(180deg);
}


.question-name {
    cursor: pointer;
    position: relative;
}

.question-name:after {
    content: '';
    position: absolute;
    right: 16px;
    top: 50%;
    width: 24px;
    height: 24px;
    background: url(/images/question-arrow-hover.svg) no-repeat center;
    transform: translateY(-50%);
    transition: transform 0.3s ease;
}

.item-question:hover .question-name:after {
    background: url(/images/question-arrow-hover.svg) no-repeat center;
}

.item-question.active .question-name:after {
    transform: translateY(-50%) rotate(180deg);
}

.answer {
    display: none;
    padding: 0 16px 16px;
    color: rgba(237, 238, 238, 0.48);
}

.item-question.active .answer {
    display: block;
}

/* Светлая тема */
/* body.light .item-question {
    background: rgba(19, 19, 19, 0.04);
} */

body.light .answer {
    color: rgba(19, 19, 19, 0.48);
}

.price {
    display: flex;
    align-items: center;
    gap: 8px;
}

.coin-plus,
.coin-minus {
    width: 23px;
    height: 23px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.coin-plus {
    background-image: url('/images/stm-coin.svg');
}

.coin-minus {
    background-image: url('/images/stm-coin-pink.svg');
}

body.light .coin-plus {
    background-image: url('/images/stm-coin-orange.svg');
}

body.light .coin-minus {
    background-image: url('/images/stm-coin-rad.svg');
}

:deep(.router-link) {
    color: #24FF6F;
    text-decoration: none;
}

:deep(.router-link:hover) {
    text-decoration: underline;
}
</style>