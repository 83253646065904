<template>
    <div v-if="show" id="login-popup" class="popup-block">
        <div class="popup-bg" @click="$emit('close')"></div>
        <div class="block">
            <div class="close" @click="$emit('close')"></div>
            <div class="title">Авторизация</div>
            <div class="desc">Войдите через VK или Telegram, чтобы получить все преимущества сервиса</div>
            <ul>
                <li>25 SM коинов при регистрации, которые сразу можно применить</li>
                <li>Кэшбэк со всех покупок</li>
                <li>Реферальная система</li>
                <li>Специальные предложения</li>
            </ul>
            <a href="#" class="vk-login" @click.prevent="handleVkAuth">Войти через VK</a>
            <a href="#" class="tg" @click.prevent="handleTelegramAuth">Войти через Telegram</a>
        </div>
    </div>

    <WelcomeSubscribeModal :show="showWelcomeModal" :auth-type="authType" @close="closeWelcomeModal"
        @subscribe-success="handleSubscribeSuccess" />
</template>

<script>
import { useAuthStore } from '@/store/auth';
import { useRouter } from 'vue-router';
import { authService } from '@/service/social_connect/auth.service';
import { VKAuth } from '@/utils/auth/vkauth';
import { ref } from 'vue';
import WelcomeSubscribeModal from '@/components/WelcomeSubscribeModal.vue';

export default {
    name: 'LoginModal',

    components: {
        WelcomeSubscribeModal
    },

    props: {
        show: Boolean
    },

    emits: ['close', 'auth-success'],

    setup() {
        const authStore = useAuthStore();
        const router = useRouter();
        const showWelcomeModal = ref(false);
        const authType = ref(null);

        return {
            router,
            authStore,
            showWelcomeModal,
            authType
        };
    },


    async mounted() {
        

        const showModal = localStorage.getItem('show_welcome_modal');
        const savedAuthType = localStorage.getItem('auth_type');

        if (showModal === 'true' && savedAuthType === 'vk') {
            localStorage.removeItem('show_welcome_modal');
            localStorage.removeItem('auth_type');

            if (!this.authStore.user?.vk_bonus_done) {
                this.authType = savedAuthType;
                this.showWelcomeModal = true;
            }
        }
    },

    methods: {
        closeWelcomeModal() {
            this.showWelcomeModal = false;
            if (this.authStore.user?.vk_bonus_done || this.authStore.user?.tg_bonus_done) {
                this.$emit('close');
                window.location.reload();
            }
        },

        handleSubscribeSuccess() {
            this.authStore.fetchUserData();
        },
        checkReferralCode() {
            const urlParams = new URLSearchParams(window.location.search);
            const refParam = urlParams.get('ref');

            if (refParam) {
                localStorage.setItem('referral_code', refParam);
                // console.log("AuthPopup: Сохранен реферальный код из URL:", refParam);
                this.referralCode = refParam;
            } else if (this.referralCode) {
                // console.log("AuthPopup: Используем реферальный код из localStorage:", this.referralCode);
            }
        },

        async handleVkAuth() {
            const currentRefCode = localStorage.getItem('referral_code');

            if (currentRefCode) {
                sessionStorage.setItem('pending_ref_code', currentRefCode);
            }
            try {
                this.checkReferralCode();
                const returnUrl = encodeURIComponent(window.location.href);
                localStorage.setItem('auth_return_url', returnUrl);

                const { url } = await VKAuth.generateAuthUrl();
                const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

                if (isSafari) {
                    window.location.href = url;
                } else {
                    const width = 800;
                    const height = 600;
                    const left = (window.screen.width - width) / 2;
                    const top = (window.screen.height - height) / 2;

                    const authWindow = window.open(
                        url,
                        'vk-auth',
                        `width=${width},height=${height},left=${left},top=${top},scrollbars=yes,resizable=yes`
                    );

                    if (!authWindow || authWindow.closed) {
                        throw new Error('Браузер заблокировал открытие окна авторизации');
                    }

                    const handleAuthSuccess = async (event) => {
                        if (event.data?.type === 'vk-auth-success') {
                            window.removeEventListener('message', handleAuthSuccess);
                            await this.$emit('auth-success');

                            localStorage.setItem('show_welcome_modal', 'true');
                            localStorage.setItem('auth_type', 'vk');

                            window.location.reload();
                        }
                    };

                    window.addEventListener('message', handleAuthSuccess);

                    const checkInterval = setInterval(() => {
                        if (authWindow.closed) {
                            clearInterval(checkInterval);
                            window.removeEventListener('message', handleAuthSuccess);
                        }
                    }, 500);
                }
            } catch (error) {
                console.error('VK auth error:', error);
                alert('Произошла ошибка при авторизации');
            }
        },


        async handleTelegramAuth() {
            try {
                this.checkReferralCode();

                const { url, width, height } = await authService.handleTelegramAuth();
                const authWindow = authService.createAuthWindow(url, width, height);

                const handleTelegramMessage = async (event) => {
                    if (event.origin === "https://oauth.telegram.org") {
                        try {
                            const data = JSON.parse(event.data);
                            if (data.event === "auth_result" && data.result) {
                                if (authWindow && !authWindow.closed) {
                                    authWindow.close();
                                }
                                window.removeEventListener('message', handleTelegramMessage);

                                const success = await this.authStore.loginWithTelegram(data.result);

                                if (success) {
                                     this.$emit('auth-success');

                                    if (!this.authStore.user?.tg_bonus_done) {
                                        this.authType = 'telegram';
                                        this.showWelcomeModal = true;
                                    }
                                }
                            }
                        } catch (e) {
                            console.error('Auth error:', e);
                            if (authWindow && !authWindow.closed) {
                                authWindow.close();
                            }
                            window.removeEventListener('message', handleTelegramMessage);
                        }
                    }
                };

                window.addEventListener('message', handleTelegramMessage);

                if (authWindow) {
                    const checkWindow = setInterval(() => {
                        if (authWindow.closed) {
                            clearInterval(checkWindow);
                            window.removeEventListener('message', handleTelegramMessage);
                        }
                    }, 500);
                }
            } catch (error) {
                console.error('Telegram auth error:', error);
            }
        }
    }
};
</script>