<template>
  <div>
    <div v-if="message" class="error-info" :class="{
      'first-time-message': isFirstTimeMessage,
      'warning-message': type === 'warning'
    }">
      <span v-if="isFirstTimeMessage">
        Если не было пополнений на этот логин, то
        <a @click.prevent="$emit('open-error-login')" class="faq-link">прочитайте инструкцию</a>
        для вашего аккаунта
      </span>
      <span v-else>{{ message }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ErrorInfo',
  props: {
    message: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'error'
    }
  },
  computed: {
    isFirstTimeMessage() {
      return this.message.includes('first-login-message');
    }
  },
  emits: ['open-error-login']
}
</script>

<style scoped>
.error-info {
  color: var(--Text, #FF3B30);
}

body.light .first-time-message {
    color: var(--Text-Tetriary, rgba(19, 19, 19, 0.4));
}


.first-time-message {
  color: var(--Text-Secondary, rgba(255, 255, 255, .48));
}

.first-time-message .faq-link {
  text-decoration: underline;
  cursor: pointer;
}

.warning-message {
  color: var(--Text-Secondary, rgba(255, 255, 255, .48));
}

body.light .warning-message {
  color: var(--Text-Tetriary, rgba(19, 19, 19, 0.4));
}

</style>