<template>
    <div class="date-picker">
        <input type="text" readonly :value="formattedSelectedDate" @click="toggleCalendar" placeholder="Дата"
            class="date-input" />

        <div v-if="showCalendar" class="calendar">
            <div class="calendar-header">
                <div class="month-selector">
                    <button class="nav-button" @click="prevMonth" tabindex="0" aria-label="Previous month">
                        <img loading="lazy" src="/images/calendar-left-arrow.svg.svg" class="nav-icon" alt="" />
                    </button>
                    <span class="month-text">{{ currentMonthName }}</span>
                    <button class="nav-button" @click="nextMonth" tabindex="0" aria-label="Next month">
                        <img loading="lazy" src="/images/calendar-right-arrow.svg" class="nav-icon" alt="" />
                    </button>
                </div>
                <div class="year-selector">
                    <button class="nav-button" @click="prevYear" tabindex="0" aria-label="Previous year">
                        <img loading="lazy" src="/images/calendar-left-arrow.svg.svg" class="nav-icon" alt="" />
                    </button>
                    <span class="year-text">{{ currentYear }}</span>
                    <button class="nav-button" @click="nextYear" tabindex="0" aria-label="Next year">
                        <img loading="lazy" src="/images/calendar-right-arrow.svg" class="nav-icon" alt="" />
                    </button>
                </div>
            </div>

            <div class="weekdays-header">
                <div class="weekday" v-for="day in weekDays" :key="day">{{ day }}</div>
            </div>

            <div class="calendar-grid">
                <div v-for="(week, weekIndex) in calendarDays" :key="weekIndex" class="calendar-row">
                    <div v-for="day in week" :key="day.date" class="calendar-day" :class="{
                        'inactive': !day.isCurrentMonth,
                        'selected': isSelectedDate(day.date),
                        'today': isToday(day.date)
                    }" @click="selectDate(day.date)">
                        {{ day.dayNumber }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'DatePicker',

    props: {
        modelValue: {
            type: Date,
            default: null
        }
    },
    data() {
        return {
            showCalendar: false,
            currentDate: new Date(),
            selectedDate: this.modelValue,
            weekDays: ['пн', 'вт', 'ср', 'чт', 'пт', 'сб', 'вс']
        }
    },

    computed: {
        currentYear() {
            return this.currentDate.getFullYear();
        },

        currentMonthName() {
            return this.currentDate.toLocaleString('ru', { month: 'long' });
        },

        formattedSelectedDate() {
            if (!this.selectedDate) return '';
            return this.selectedDate.toLocaleDateString('ru');
        },

        calendarDays() {
            const year = this.currentDate.getFullYear();
            const month = this.currentDate.getMonth();

            const firstDay = new Date(year, month, 1);
            const lastDay = new Date(year, month + 1, 0);

            let firstDayOfWeek = firstDay.getDay() || 7;
            firstDayOfWeek--; 

            const daysInMonth = lastDay.getDate();
            const weeks = [];
            let currentWeek = [];

            const prevMonth = new Date(year, month, 0);
            const prevMonthDays = prevMonth.getDate();
            for (let i = firstDayOfWeek - 1; i >= 0; i--) {
                currentWeek.push({
                    date: new Date(year, month - 1, prevMonthDays - i),
                    dayNumber: prevMonthDays - i,
                    isCurrentMonth: false
                });
            }

            for (let day = 1; day <= daysInMonth; day++) {
                currentWeek.push({
                    date: new Date(year, month, day),
                    dayNumber: day,
                    isCurrentMonth: true
                });

                if (currentWeek.length === 7) {
                    weeks.push(currentWeek);
                    currentWeek = [];
                }
            }

            let nextMonthDay = 1;
            while (currentWeek.length < 7) {
                currentWeek.push({
                    date: new Date(year, month + 1, nextMonthDay),
                    dayNumber: nextMonthDay,
                    isCurrentMonth: false
                });
                nextMonthDay++;
            }
            weeks.push(currentWeek);

            return weeks;
        }
    },

    methods: {
        toggleCalendar() {
            this.showCalendar = !this.showCalendar;
        },

        prevMonth() {
            this.currentDate = new Date(this.currentYear, this.currentDate.getMonth() - 1);
        },

        nextMonth() {
            this.currentDate = new Date(this.currentYear, this.currentDate.getMonth() + 1);
        },

        prevYear() {
            this.currentDate = new Date(this.currentYear - 1, this.currentDate.getMonth());
        },

        nextYear() {
            this.currentDate = new Date(this.currentYear + 1, this.currentDate.getMonth());
        },

        selectDate(date) {
            this.selectedDate = date;
            this.$emit('update:modelValue', date);
            this.showCalendar = false;
        },

        isSelectedDate(date) {
            if (!this.selectedDate) return false;
            return date.toDateString() === this.selectedDate.toDateString();
        },

        isToday(date) {
            const today = new Date();
            return date.toDateString() === today.toDateString();
        }
    }
}
</script>

<style scoped>
.date-picker {
    position: relative;
    width: fit-content;
}

.date-input {
    padding: 8px 12px;
    border: 1px solid rgba(237, 238, 238, 0.1);
    border-radius: 8px;
    background: rgba(237, 238, 238, 0.04);
    color: #EDEEEE;
    cursor: pointer;
    width: 200px;
}
.calendar {
    border-radius: 8px;
    background: rgba(19, 19, 19, 1);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.11);
    max-width: 261px;
    padding: 0 8px 8px;
    border: 1px solid rgba(23, 32, 60, 0.08);
    position: absolute;
    z-index: 99;
}

.calendar-header {
    display: flex;
    align-items: center;
    gap: 4px;
    padding: 8px 0;
}

.month-selector,
.year-selector {
    border-radius: 4px;
    background: rgba(237, 238, 238, 0.04);
    display: flex;
    align-items: center;
    gap: 4px;
}

.nav-button {
    border-radius: 4px;
    width: 34px;
    padding: 8px;
    background: none;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.nav-icon {
    width: 18px;
    height: 18px;
}

.month-text,
.year-text {
    color: rgba(237, 238, 238, 1);
    font: 600 12px/1 Inter, sans-serif;
    text-align: center;
}

.weekdays-header {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid rgba(23, 32, 60, 0.08);
}

.weekday {
    flex: 1;
    color: rgba(237, 238, 238, 0.48);
    text-align: center;
    font: 400 12px/1 Inter, sans-serif;
    padding: 0 4px;
    min-height: 24px;
    border-radius: 4px;
}

.calendar-row {
    display: flex;
    justify-content: space-between;
    gap: 3px;
}


.calendar-day {
    flex: 1;
    min-height: 32px;
    padding: 8px 4px;
    border-radius: 4px;
    color: rgba(237, 238, 238, 1);
    text-align: center;
    font: 400 12px/1 Inter, sans-serif;
    cursor: pointer;
}

.calendar-day.inactive {
    color: rgba(237, 238, 238, 0.18);
}

.visually-hidden {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
}

body.light .calendar {
            background: #fff;
}
body.light .calendar-day {
color: var(--Text-Primary, #131313);
}
body.light .weekday{
    color: var(--Text-Secondary, rgba(19, 19, 19, 0.48));
}

body.light .month-text{
    color: var(--Text-Primary, #131313);
}

body.light .year-text{
    color: var(--Text-Primary, #131313);
}
body.light .calendar-day.inactive{
        color: var(--Text-Secondary, rgba(19, 19, 19, 0.48));
}
body.light .nav-icon {
    filter: invert(7%) sepia(8%) saturate(11%) hue-rotate(314deg) brightness(101%) contrast(93%);
}
.nav-icon {
    filter: invert(0%) sepia(0%) saturate(7500%) hue-rotate(346deg) brightness(94%) contrast(108%);
}
body.light .section-history .date-input value {
    background: var(--Surface-Primary-Default, rgba(19, 19, 19, 0.06));
}
</style>