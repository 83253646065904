import * as Sentry from "@sentry/vue";

export function initSentry(app, router) {
        Sentry.init({
            app,
            dsn: import.meta.env.VITE_SENTRY_DSN,
            integrations: [],
            tracesSampleRate: 1.0,
            environment: import.meta.env.MODE,
            enabled: import.meta.env.PROD,

            beforeSend(event) {
                if (event.exception) {
                    const errorMessage = event.exception.values?.[0]?.value;
                    if (errorMessage?.includes('Network Error') ||
                        errorMessage?.includes('Failed to fetch')) {
                        return null;
                    }
                }
                return event;
            },

            attachProps: true,
            logErrors: true,
            trackComponents: true,
            hooks: ['activate', 'mount', 'update'],

            initialScope: {
                tags: {
                    'app.version': import.meta.env.VITE_APP_VERSION || 'unknown'
                }
            }
        });

        // Отслеживание маршрутов через navigation guards
        router.beforeEach((to) => {
            if (import.meta.env.PROD) {
                try {
                    Sentry.addBreadcrumb({
                        category: 'navigation',
                        message: `Navigating to ${to.path}`,
                        level: 'info'
                    });
                } catch (e) {
                    console.error('Sentry navigation tracking error:', e);
                }
            }
        });
    }
