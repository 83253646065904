<template>
  <div class="footer">
    <div class="site-width">
      <div class="col-left">
        <div class="links">
          <a href="#faq" @click.prevent="scrollToFaq">FAQ</a>
          <a href="javascript:jivo_api.open()">Поддержка</a>
          <router-link to="/contacts">Контакты</router-link>
        </div>

        <SocialLinks />
      </div>

      <div class="menu">
        <ul>
          <li><a class="pointers" @click="openModal('return-rules')">Правила возврата</a></li>
          <li><a class="pointers" @click="openModal('method-buy')">Способы оплаты</a></li>
        </ul>
        <ul>
          <li><a :href="getUrl('terms')" target="_blank">Пользовательское соглашение</a></li>
          <li><a :href="getUrl('offer')" target="_blank">Публичная оферта</a></li>
          <li><a :href="getUrl('privacy')" target="_blank">Политика конфиденциальности</a></li>
        </ul>
        <ul>
          <li><a :href="getUrl('privacy')" target="_blank">Политика конфиденциальности</a></li>
        </ul>
      </div>



      <div class="pay">
        <div><img src="/images/pay1.svg" alt="" /></div>
        <div><img src="/images/pay2.svg" alt="" /></div>
        <div><img src="/images/pay3.svg" alt="" /></div>
        <div><img src="/images/pay4.svg" alt="" /></div>
        <div><img src="/images/pay5.svg" alt="" /></div>
      </div>

      <div class="pay black">
        <div><img src="/images/pay1-black.svg" alt="" /></div>
        <div><img src="/images/pay2-black.svg" alt="" /></div>
        <div><img src="/images/pay3-black.svg" alt="" /></div>
        <div><img src="/images/pay4-black.svg" alt="" /></div>
        <div><img src="/images/pay5-black.svg" alt="" /></div>
      </div>
    </div>
  </div>
  <UniversalPopup :isVisible="modals['return-rules']" title="Правила возврата" @close="closeModal('return-rules')">
    <div class="block-popup">
      <div class="text">
        Если вы указали неверный логин своего аккаунта и совершили оплату, мы сможем оформить возврат средств
        только при условии, что такого логина не зарегистрировано в STEAM.<br><br>

        Если вы указали логин, который существует в STEAM, оформить возврат средств является невозможным, так
        как зачисление средств произойдет на существующий аккаунт.<br><br>

        Будьте внимательны при заполнении поля "логин". Как узнать свой логин, мы подробно написали в нашем
        FAQ<br><br>

        Более подробные правила возврата вы можете прочитать в
        <a :href="getUrl('offer')" class="return-class" target="_blank">Публичной оферте</a>
      </div>
    </div>
  </UniversalPopup>
  <UniversalPopup :isVisible="modals['method-buy']" title="Правила возврата" @close="closeModal('method-buy')">
    <div class="block">
      <div class="close"></div>

      <div class="title text">Способы оплаты</div>
      <div id="payments-popup-text">Оплата происходит через авторизационный сервер Процессингового центра Банка с
        использованием Банковских
        карт следующих Платежных систем:<br>
        — VISA International<br>
        — MasterCard Worldwide<br>
        — МИР<br>
        «Для осуществления платежа Вам потребуется сообщить данные Вашей пластиковой карты.
        Передача этих сведений производится с соблюдением всех
        необходимых мер безопасности. Данные будут сообщены только на авторизационный
        сервер Банка по защищенному каналу (протокол TLS). Информация передается в
        зашифрованном виде и сохраняется только на специализированном сервере Платежной
        системы. Сайт и магазин не знают и не хранят данные вашей пластиковой карты.
        При выборе формы оплаты с помощью пластиковой карты проведение платежа по
        заказу производится непосредственно после его оформления. После завершения
        оформления заказа на нашем сайте, Вы должны будете нажать на кнопку "Перейти к оплате", при этом система
        переключит Вас на страницу авторизационного сервера, где
        Вам будет предложено ввести данные пластиковой карты, инициировать ее
        авторизацию, после чего вернуться в наш магазин кнопкой "Вернуться в магазин". После
        того, как Вы возвращаетесь в наш магазин, система уведомит Вас о результатах
        авторизации. В случае подтверждения авторизации Ваш заказ будет автоматически
        выполняться в соответствии с заданными Вами условиями. В случае отказа в авторизации
        карты Вы сможете повторить процедуру оплаты.</div>
    </div>
  </UniversalPopup>
</template>

<script>
import UniversalPopup from '@/components/Popup/PopupDefault.vue';
import SocialLinks from '@/components/Soclinks.vue';

export default {
  name: 'Footer',
  components: {
    UniversalPopup,
    SocialLinks
  },
  data() {
    return {
      modals: {
        'return-rules': false,
        'method-buy': false,
        
      },
            isGG: true 
    }
  },
  methods: {
    getUrl(type) {
      const urls = {
        terms: this.isGG ? import.meta.env.VITE_GG_TERMS_URL : import.meta.env.VITE_TERMS_URL,
        offer: this.isGG ? import.meta.env.VITE_GG_OFFER_URL : import.meta.env.VITE_OFFER_URL,
        privacy: this.isGG ? import.meta.env.VITE_GG_PRIVACY_URL : import.meta.env.VITE_PRIVACY_URL
      };

      return urls[type] || 'https://steam.money/terms.pdf?t=1';
    },
    openModal(modalId) {
      this.modals[modalId] = true;
    },
    closeModal(modalId) {
      this.modals[modalId] = false;
    },
    scrollToFaq() {
      // Сначала проверяем текущий путь
      if (this.$route.path !== '/') {
        this.$router.push('/').then(() => {
          this.$nextTick(() => {
            const faqElement = document.getElementById('faq') || document.getElementById('clients');
            if (faqElement) {
              faqElement.scrollIntoView({ behavior: 'smooth' });
            }
          });
        });
      } else {
        const faqElement = document.getElementById('faq') || document.getElementById('clients');
        if (faqElement) {
          faqElement.scrollIntoView({ behavior: 'smooth' });
        }
      }
    }
  },
}
</script>
<style>

</style>