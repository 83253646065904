<template>
    <div v-if="show" class="modal-overlay" @click.self="handleClose" @keydown.esc="handleClose" tabindex="-1">
        <section class="payment-spb">
            <h1 class="payment-title">Оплата по СБП</h1>
            <button class="close-button" @click="handleClose" aria-label="Close">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M18 6L6 18M6 6L18 18" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                        stroke-linejoin="round" />
                </svg>
            </button>

            <div class="payment-content">
                <div class="qr-code-container">
                    <div class="qr-wrapper" v-html="qr"></div>
                    <p v-if="qrLink" class="qr-code-help">
                        <a :href="qrLink" target="_blank" rel="noopener noreferrer">
                            Проблема с QR-кодом?
                        </a>
                    </p>
                </div>

                <div class="payment-details">
                    <div class="order-summary">
                        <div class="order-icon"></div>
                        <div class="order-info">
                            <h2 class="order-title">Пополнение баланса STEAM</h2>
                            <p class="order-email">{{ email || login }}</p>
                            <div class="order-amount">
                                <span v-if="cardPrice && cardCurrency && !login" class="amount-usd">
                                    {{ cardPrice }} {{ cardCurrency }}
                                </span>
                                <span class="amount-rub">{{ formatAmount(amount) }}</span>
                            </div>
                        </div>
                    </div>
                    <p class="payment-instructions">
                        Для оплаты отсканируйте QR-код в мобильном приложении банка или штатной камерой телефона или
                        перейдите в приложение банка для оплаты:
                    </p>

                    <!-- Разный контент для десктопа и мобильных -->
                    <template v-if="isMobile">
                        <button @click="showBankBottomSheet" class="payment-button">
                            Оплатить в приложении банка {{ formatAmount(amount) }}
                        </button>

                        <BankBottomSheet v-if="isBankBottomSheetVisible" :qrUrl="qrLink"
                            @close="isBankBottomSheetVisible = false" @bank-selected="handleBankSelected" />
                    </template>
                    <template v-else>
                        <a v-if="qrLink" :href="qrLink" target="_blank" rel="noopener noreferrer"
                            class="payment-button">
                            Оплатить в приложении банка {{ formatAmount(amount) }}
                        </a>
                    </template>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import BankBottomSheet from './BankList.vue';

export default {
    name: 'SbpQrPopup',

    components: {
        BankBottomSheet,
    },

    props: {
        show: {
            type: Boolean,
            default: false
        },
        qr: {
            type: String,
            default: ''
        },
        qrLink: {
            type: String,
            default: ''
        },
        amount: {
            type: [Number, String],
            required: true
        },
        email: {
            type: String,
            default: null
        },
        login: {
            type: String,
            default: null
        },
        cardPrice: {
            type: [Number, String],
            default: null
        },
        cardCurrency: {
            type: String,
            default: null
        },
        orderId: {
            type: String,
            required: true
        },
    },

    data() {
        return {
            isBankBottomSheetVisible: false,
            isMobile: false,
            statusCheckInterval: null,
        }
    },

    computed: {
        isGiftCard() {
            return !!this.cardPrice && !!this.cardCurrency;
        }
    },

    mounted() {
        document.addEventListener('keydown', this.handleEscKey);
        this.checkDevice();
        if (this.show) {
            this.startPaymentStatusCheck(); 
        }
    },

    beforeUnmount() {
        if (this.statusCheckInterval) {
            clearInterval(this.statusCheckInterval);
            this.statusCheckInterval = null;
        }
        document.removeEventListener('keydown', this.handleEscKey);
        document.body.style.overflow = '';
    },

    methods: {

        startPaymentStatusCheck() {
            if (!this.show || !this.orderId) return;

            if (this.statusCheckInterval) {
                clearInterval(this.statusCheckInterval);
                this.statusCheckInterval = null;
            }

            const checkInterval = setInterval(async () => {
                try {
                    const { data } = await this.axios.get('/check/order', {
                        params: { id: this.orderId }
                    });

                    if (data.status === true) {
                        clearInterval(this.statusCheckInterval);
                        this.statusCheckInterval = null;
                        this.isBankBottomSheetVisible = false;
                        this.$emit('close');
                        window.location.href = `/success?billId=${this.orderId}`;
                    }
                } catch (error) {
                    console.error('Error checking payment status:', error);
                    clearInterval(this.statusCheckInterval);
                    this.statusCheckInterval = null;
                }
            }, 3000);

            this.statusCheckInterval = checkInterval;
        },


        checkDevice() {
            this.isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
        },

        handleClose() {
            if (this.statusCheckInterval) {
                clearInterval(this.statusCheckInterval);
                this.statusCheckInterval = null;
            }
            this.$emit('close');
        },

        handleEscKey(e) {
            if (this.show && e.key === 'Escape') {
                this.handleClose();
            }
        },

        formatAmount(amount) {
            return `${amount}₽`;
        },

        showBankBottomSheet() {
            this.isBankBottomSheetVisible = true;
        },

        handleBankSelected(payload) {
            if (payload.deepLink) {
                window.location.href = payload.deepLink;

                // Таймаут для открытия основной ссылки если приложение не установлено
                setTimeout(() => {
                    window.location.href = this.qrLink;
                }, 2000);
            }
        }
    },

    watch: {
        show(newValue) {
            if (newValue) {
                document.body.style.overflow = 'hidden';
                this.startPaymentStatusCheck();
            } else {
                document.body.style.overflow = '';
                if (this.statusCheckInterval) {
                    clearInterval(this.statusCheckInterval);
                    this.statusCheckInterval = null;
                }
            }
        }
    }
};
</script>

<style scoped>
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(43, 43, 43, 0.75);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
}

.payment-spb {
    border-radius: 16px;
    background-color: #131313;
    position: relative;
    display: flex;
    max-width: 727px;
    width: 100%;
    flex-direction: column;
    font-family: Inter, sans-serif;
    padding: 40px 32px;
    margin: 20px;
}

.payment-title {
    color: #edeeee;
    font-size: 24px;
    font-weight: 500;
    line-height: 1;
    text-align: center;
    margin-bottom: 20px;
}

.close-button {
    position: absolute;
    right: 16px;
    top: 16px;
    background: none;
    border: none;
    cursor: pointer;
    width: 24px;
    height: 24px;
    padding: 0;
}

.close-button svg {
    color: #fff;
}

.payment-content {
    display: flex;
    width: 100%;
    align-items: flex-start;
    gap: 32px;
}

.qr-code-container {
    border-radius: 16px;
    background-color: rgba(255, 255, 255, 0.06);
    display: flex;
    width: 282px;
    flex-direction: column;
    align-items: center;
    padding: 19px 15px;
    border: 1px solid rgba(255, 255, 255, 0.1);
}

.qr-wrapper {
    background-color: #fff;
    width: 252px;
    height: 252px;
}

.qr-wrapper rect {
    fill: white;
}

.qr-wrapper :deep(svg) {
    width: 100%;
    height: 100%;
}

.qr-code-help {
    padding-top: 13px;
    font-size: 14px;
    line-height: 1;
    color: rgba(237, 238, 238, 0.48);
}

.qr-code-help a {
    color: inherit;
    text-decoration: none;
}

.payment-details {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.order-summary {
    border-radius: 8px;
    background-color: #333;
    display: flex;
    gap: 12px;
    padding: 16px;
}

.order-icon {
    width: 60px;
    height: 60px;
    background: url('/new/images/steam.svg') no-repeat center;
    background-size: contain;
    opacity: 0.7;
}

.order-info {
    flex: 1;
}

.order-title {
    color: #edeeee;
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 6px;
}

.order-email {
    color: #24ff6f;
    font-size: 16px;
    margin-bottom: 6px;
}

.order-amount {
    display: flex;
    align-items: center;
    gap: 6px;
    font-weight: 600;
}

.amount-usd {
    border-radius: 7px;
    background-color: rgba(36, 255, 111, 0.04);
    color: #24ff6f;
    padding: 4px 8px;
    border: 1px solid rgba(36, 255, 111, 0.24);
}

.amount-rub {
    color: #edeeee;
}

.payment-instructions {
    color: rgba(237, 238, 238, 0.48);
    font-size: 12px;
    line-height: 16px;
}

.payment-button {
    border-radius: 8px;
    background-color: rgba(36, 255, 111, 0.04);
    color: #24ff6f;
    font-weight: 600;
    padding: 16px;
    border: 1px solid #24ff6f;
    text-decoration: none;
    text-align: center;
    transition: background-color 0.2s;
    width: 100%;
    cursor: pointer;
}

.payment-button:hover {
    background-color: rgba(36, 255, 111, 0.08);
}

/* Light theme */
body.light .payment-spb {
    background-color: #fff;
}

body.light .close-button svg {
    color: #131313;
}

body.light .payment-title {
    color: rgba(19, 19, 19, 0.72);
}

body.light .qr-code-help {
    color: rgba(19, 19, 19, 0.72);
}

body.light .payment-instructions {
    color: rgba(19, 19, 19, 0.72);
}

body.light .order-summary {
    background-color: rgba(19, 19, 19, 0.06);
}

body.light .order-title {
    color: #131313;
}

body.light .amount-rub {
    color: #131313;
}

body.light .payment-button {
    background-color: rgba(0, 190, 58, 0.12);
    color: #0db740;
    border: 1px solid #0db740;
}

body.light .amount-usd {
    color: #0db740;
    border: 1px solid #0db740;
}

/* Media queries */
@media (max-width: 991px) {
    .payment-spb {
        padding: 20px;
        margin-bottom: 300px;
    }

    .payment-content {
        flex-direction: column;
        align-items: center;
    }

    .qr-wrapper {
        width: 180px;
        height: 180px;
    }

    .qr-code-container {
       display: none;
    }
}

@media (max-width: 375px) {
    .payment-spb {
        margin: 12px;
        padding: 16px;
        margin-bottom: 300px;
    }

    .qr-wrapper {
        width: 180px;
        height: 180px;
    }

    .order-summary {
        padding: 12px;
    }

    .order-icon {
        width: 48px;
        height: 48px;
    }
}
</style>
