<script setup>
import { RouterLink, RouterView } from 'vue-router';
import Footer from '@/components/Footer.vue';
import WarningBlock from '@/components/InfoBlock/WarningBlock.vue';
import InfoBlock from '@/components/InfoBlock/InfoBlock.vue';
import settingsService from '@/service/api/service';
import { useUIStore } from '@/store/uistore';
import { eventBus } from '@/service/eventBus';
import * as Sentry from '@sentry/vue';

window.addEventListener('unhandledrejection', (event) => {
    Sentry.captureException(event.reason);
  
});
</script>

<template>
  <Favicon/>
  <div class="app-wrapper">
    <TokenRefresh />

    <div class="notifications-wrapper">
      <WarningBlock v-if="warningMessage" :warningMessage="warningMessage" />
      <InfoBlock v-if="infoMessage" :infoMessage="infoMessage" />
    </div>

    <Header />

    <main class="main-content">
      <RouterView />
    </main>

    <Footer />
  </div>
</template>


<script>
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';
import TokenRefresh from './auth/TokenRefresh.vue';
import Favicon from './components/Favicon.vue';


const logoWidth = import.meta.env.VITE_GG_LOGO_WIDTH || import.meta.env.VITE_LOGO_WIDTH;
// const logoHeight = import.meta.env.VITE_GG_LOGO_HEIGHT || import.meta.env.VITE_LOGO_HEIGHT;
const logoUrl = import.meta.env.VITE_GG_LOGO_URL || import.meta.env.VITE_LOGO_URL || '../images/logo.svg';
document.documentElement.style.setProperty("--logo-url", `url(${logoUrl})`);
document.documentElement.style.setProperty("--logo-width", `${logoWidth}px`);
// document.documentElement.style.setProperty("--logo-height", `${logoHeight}px`);



export default {
  components: { Header, Footer, WarningBlock, InfoBlock },
  data() {
    return {
      settings: null,
      warningMessage: null,
      infoMessage: null
    };
  },
  async mounted() {
    eventBus.on('forceUpdateHeader', () => {
      this.$nextTick(() => {
        if (this.$refs.header) {
          this.$refs.header.$forceUpdate();
        }
      });
    });

    const uiStore = useUIStore();
    uiStore.initTheme();

    try {
      
      await settingsService.fetch();
      this.settings = settingsService.get();
      this.warningMessage = this.settings?.warning_message;
      this.infoMessage = this.settings?.notice_message;
    } catch (error) {
      console.error('Ошибка загрузки настроек:', error);
        Sentry.captureException(error);
      
    }
  },
  mounted() {
    // Тестовая ошибка для проверки работы Sentry
      // Намеренно вызываем несуществующую функцию
      const testError = new Error('Test Sentry Error');
      Sentry.captureException(testError);
    
  },
  beforeUnmount() {
    eventBus.off('forceUpdateHeader');
  }
};
</script>