import axios from 'axios';
import { refCodeStore } from '../../store/referralCodeStore';

export const authAPI = {
    referralCode: null,

    async fetchUserProfile() {
        return await axios.get('/users/profile');
    },

    async logout() {
        return await axios.post('/auth/logout');
    },

    async refreshToken() {
        return await axios.post('/auth/refresh');
    },

    async loginWithSocCallback(userData, type) {
        const referralStore = refCodeStore();
        const refCode = referralStore.getCurrentReferralCode();
        if (type === 'vk') {

            return await axios.post('/soc-callback', {
                code: userData.code,
                state: userData.state,
                code_verifier: userData.code_verifier,
                device_id: userData.device_id,
                type,
                ref_code: refCode, 
            });
        }

        return await axios.post('/soc-callback', {
            ...userData,
            ref_code: refCode ,
            type
        });
    },


    async checkVkUserSubscribe() {
        try {
            const response = await axios.post('/users/check-vk');
            return response;
        } catch (error) {
            if (error.response?.status === 401) {
                throw error.response.data;
            } else if (error.response?.status === 500) {
                throw error.response.data;
            }
            throw error;
        }
    },


    async checkTgUserSubscribe() {
        try {
            const response = await axios.post('/users/check-tg');
            return response;
        } catch (error) {
            if (error.response?.status === 401) {
                throw error.response.data;
            } else if (error.response?.status === 500) {
                throw error.response.data;
            }
            throw error;
        }
    },
    async linkVkAccount({ code, device_id, state, code_verifier }) {
        try {
            const response = await axios.post('/users/soc-link/vk', {
                code,
                device_id,
                state,
                code_verifier 
            }, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            });
            return response;
        } catch (error) {
            if (error.response?.status === 401) {
                throw error.response.data;
            }
            throw error;
        }
    },

    async linkTelegramAccount(userData) {
        try {
            const response = await axios.post('/users/soc-link/tg', userData, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            });
            return response;
        } catch (error) {
            if (error.response?.status === 401) {
                throw error.response.data;
            }
            throw error;
        }
    },
    async loginWithVk({ code, device_id, code_verifier, state, ref_code }) {
        return await this.loginWithSocCallback({
            code,
            device_id,
            code_verifier,
            state,
            ref_code
        }, 'vk');
    },
};
