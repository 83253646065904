import { defineStore } from 'pinia';
import axios from 'axios';

export const usePromoStore = defineStore('promo', {
    state: () => ({
        loading: false,
        error: null,
        lastGeneratedPromo: null,
        availableCoupons: [],
        promoList: [],
        currentPage: 0,
        hasMore: true,
        totalPages: 0
    }),


    actions: {
        async fetchAvailableCoupons() {
            this.loading = true;
            this.error = null;

            try {
                const response = await axios.get('/users/coupons');

                if (response?.data?.status) {
                    this.availableCoupons = response.data.list;
                    return this.availableCoupons;
                }

                throw new Error('Не удалось получить список купонов');
            } catch (error) {
                this.error = error.response?.data?.message || 'Ошибка при получении списка купонов';
                throw error;
            } finally {
                this.loading = false;
            }
        },

        // Генерация промокода
        async getPromoList(params = {}) {
            if (this.loading) return this.promoList;

            this.loading = true;
            this.error = null;

            try {
                const response = await axios.get('/promo/list', {
                    params: {
                        ...params,
                        page: this.currentPage
                    }
                });

                if (response?.data?.status) {
                    const newPromos = (response.data.list || []).map(promo => ({
                        ...promo,
                        isDone: promo.isDone === true || promo.is_done === 1,
                        couponVal: Number(promo.couponVal || promo.value || 0)
                    }));

                    if (this.currentPage === 0) {
                        this.promoList = newPromos;
                    } else {
                        this.promoList = [...this.promoList, ...newPromos];
                    }

                    this.hasMore = newPromos.length >= 4;
                    return this.promoList;
                }

                return [];
            } catch (error) {
                this.error = error.response?.data?.error || 'Ошибка при получении списка промокодов';
                return [];
            } finally {
                this.loading = false;
            }
        },

        async generatePromo(couponId) {
            if (!couponId) {
                throw new Error('couponId обязателен для генерации промокода');
            }

            this.loading = true;
            this.error = null;

            try {
                const response = await axios.post('/promo/generate', {
                    couponId: couponId
                });

                if (response.data?.status) {
                    this.lastGeneratedPromo = {
                        code: response.data.code,
                        exp: response.data.exp,
                        couponVal: response.data.coupon_val,
                        isDone: false
                    };

                    await this.getPromoList();

                    return this.lastGeneratedPromo;
                }

                throw new Error(response.data?.error || 'Не удалось сгенерировать промокод');
            } catch (error) {
                this.error = error.response?.data?.error || 'Произошла ошибка при генерации промокода';
                throw error;
            } finally {
                this.loading = false;
            }
        },

        async copyToClipboard(text) {
            try {
                await navigator.clipboard.writeText(text);
                return true;
            } catch (error) {
                console.error('Ошибка копирования:', error);
                return false;
            }
        },

      
        async loadMore(params = {}) {
            if (!this.hasMore || this.loading) return;

            this.currentPage++;
            await this.getPromoList(params);
        },
        resetState() {
            this.lastGeneratedPromo = null;
            this.error = null;
            this.currentPage = 0;
            this.hasMore = true;
            this.promoList = [];
            this.totalPages = 0;
        },

    }
});