<template>
  <div :id="this.id" class="popup-block" v-if="this.active">
    <div class="popup-bg" @click="this.closeModal"></div>
    <div class="block">
      <div class="close" @click="this.closeModal"></div>
      <div class="title">{{ this.$props.title }}</div>
      <div>
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['title', 'id'],
  data() {
    return {
      active: false,
    };
  },

  mounted() {
    this.$events.on('openModal', (id) => {
      // console.log('open');
      this.$events.emit('modalOpened', id);
      if (id === this.$props.id) {
        this.active = true;
      }
    });
  },
  methods: {
    closeModal() {
      this.$events.emit('modalClosed', this.id);
      this.active = false;
    },
  },
};
</script>
