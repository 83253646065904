<template>
    <div class="select-other-pay">
        <template v-if="isRefreshing || cards.length === 0">
            <label v-for="n in 4" :key="n">
                <div class="skeleton-animation">
                    <div class="block">
                        <div class="icon">
                            <img src="/images/steam.svg" class="skeleton-img" />
                        </div>
                        <div class="other-info">
                            <div class="price"></div>
                        </div>
                    </div>
                </div>
            </label>
        </template>
        <template v-else-if="error">
            <div class="error">{{ error }}</div>
        </template>
        <template v-else>
            <label v-for="card in cards" :key="card.id">
                <input type="radio" name="card" :value="card.id" @change="handleCardSelection(card)"
                    :checked="selectedCardId === card.id" />
                <div class="block">
                    <div class="icon">
                        <img class="pubg-icon" alt="icon" src="/images/pubg-icon.svg" />
                    </div>
                    <div class="other-info">
                        <div class="name">UC</div>
                        <div class="price">
                            <span>{{ card.cardPrice }} {{ card.currency }}</span>
                            <span>{{ formatPrice(card.price) }} ₽</span>
                        </div>
                    </div>
                </div>
            </label>
        </template>
    </div>
</template>

<script>
export default {
    name: 'PubgCardsFilter',
    emits: ['card-selected'],
    props: {
        paymentMethod: {
            type: String,
            default: 'card'
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            cards: [],
            selectedCardId: null,
            isRefreshing: false,
            error: null,
            refreshAttempts: 0,
            maxRefreshAttempts: 3,
            lastCalculatedMethod: null,
            processingPriceCalculation: false
        };
    },

    async created() {
        await this.fetchCards();
    },

    methods: {
        async fetchCards() {
            if (this.isRefreshing) return;

            this.isRefreshing = true;
            this.error = null;

            try {
                // console.log('Загрузка карточек PUBG...');
                const { data } = await this.axios.get('/calc/cards/pubg/grid');

                if (data.status) {
                    this.cards = Array.isArray(data.cards) ? data.cards :
                        (Array.isArray(data.list) ? data.list : []);

                    if (this.cards.length === 0) {
                        console.warn('PUBG cards list is empty');
                        this.error = 'empty_list';
                        return;
                    }

                    // Если карта была выбрана ранее, проверяем, существует ли она всё ещё
                    const prevCardStillExists = this.selectedCardId &&
                        this.cards.some(card => card.id === this.selectedCardId);

                    // Если нет сохраненной карты или она больше не существует, выбираем первую карту
                    if (!prevCardStillExists && this.cards.length > 0) {
                        this.handleCardSelection(this.cards[0]);
                    }
                } else {
                    throw new Error(data.message || 'Не удалось загрузить карточки');
                }
            } catch (error) {
                console.error('Failed to fetch PUBG cards:', error);
                this.error = error.message || 'Не удалось загрузить карточки';
                this.cards = [];
            } finally {
                this.isRefreshing = false;
                this.refreshAttempts = 0;
            }
        },

        handleCardSelection(card) {
            if (!card || this.processingPriceCalculation) return;
            if (this.selectedCardId === card.id) return;

            // console.log(`Выбрана карта PUBG: ${card.id}`);
            this.selectedCardId = card.id;

            // Эмитим событие выбора карты без расчета цены
            // Это позволит родителю знать, что выбрана новая карта
            this.$emit('card-selected', card);

            // Теперь запускаем отдельный расчет с текущим методом оплаты
            this.calculateCardPrice(card);
        },

        async calculateCardPrice(card) {
            // Проверяем, не выполняется ли уже расчет
            if (this.processingPriceCalculation) {
                // console.log('Расчет цены уже выполняется, пропускаем...');
                return;
            }

            // Проверяем, менялся ли метод оплаты с последнего расчета
            if (this.lastCalculatedMethod === this.paymentMethod && this.selectedCardId === card.id) {
                // console.log('Метод оплаты не изменился, пропускаем повторный расчет');
                return;
            }

            // Устанавливаем флаг выполнения расчета
            this.processingPriceCalculation = true;

            try {
                // console.log(`Расчет цены для карты ${card.id} с методом оплаты ${this.paymentMethod}`);

                // Сохраняем текущий метод оплаты для проверки актуальности
                const currentPayMethod = this.paymentMethod;

                const response = await this.axios.get('/calc/card', {
                    params: {
                        cardId: card.id,
                        payMethod: currentPayMethod,
                        email: document.querySelector('input[type="email"]')?.value || ''
                    }
                });

                // Если методы оплаты не совпадают (изменились во время запроса), 
                // то прерываем обработку
                if (currentPayMethod !== this.paymentMethod) {
                    console.warn('Метод оплаты изменился во время расчета, игнорируем результат');
                    return;
                }

                if (response.data.status) {
                    // Обновляем последний использованный метод оплаты
                    this.lastCalculatedMethod = currentPayMethod;

                    const calculatedCard = {
                        ...card,
                        ...response.data
                    };

                    // Отправляем родителю обновленные данные
                    this.$emit('card-selected', calculatedCard);
                } else {
                    throw new Error(response.data.message || 'Ошибка расчета стоимости карты');
                }
            } catch (error) {
                console.error('Card calculation error:', error);

                if (this.refreshAttempts < this.maxRefreshAttempts) {
                    this.refreshAttempts++;
                    await this.fetchCards();
                }
            } finally {
                // Сбрасываем флаг расчета
                this.processingPriceCalculation = false;
            }
        },

        formatPrice(price) {
            return new Intl.NumberFormat('ru-RU').format(price);
        }
    },

    watch: {
        paymentMethod: {
            handler(newMethod, oldMethod) {
                // Проверяем, действительно ли изменился метод оплаты
                if (newMethod !== oldMethod && this.selectedCardId && !this.processingPriceCalculation) {
                    // console.log(`Метод оплаты изменился: ${oldMethod} -> ${newMethod}`);

                    // Находим текущую выбранную карту
                    const selectedCard = this.cards.find(card => card.id === this.selectedCardId);
                    if (selectedCard) {
                        // Запускаем перерасчет с новым методом оплаты
                        this.calculateCardPrice(selectedCard);
                    }
                }
            }
        }
    }
};
</script>

<style scoped>
.select-other-pay .price {
    display: grid;
}

.pubg-cards-container {
    margin-bottom: 20px;
}

.loading-state,
.error-state {
    padding: 20px;
    text-align: center;
}

.cards-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 16px;
}

.card-item {
    border-radius: 8px;
    background: rgba(237, 238, 238, 0.04);
    padding: 16px;
    cursor: pointer;
    transition: all 0.2s ease;
}

.card-item:hover {
    background: rgba(237, 238, 238, 0.08);
}

.card-item.selected {
    background: rgba(36, 255, 111, 0.08);
    border: 1px solid rgba(36, 255, 111, 0.24);
}

.card-content {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.card-title {
    font-weight: 500;
    color: #FFFFFF;
}

.card-price-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.card-price {
    font-weight: 600;
    color: #24FF6F;
}

.card-store-price {
    color: rgba(237, 238, 238, 0.48);
    font-size: 0.9em;
}

.name {
    display: none;
}

.refresh-btn {
    background: rgba(36, 255, 111, 0.1);
    color: #24FF6F;
    border: 1px solid rgba(36, 255, 111, 0.3);
    padding: 8px 16px;
    border-radius: 4px;
    margin-top: 16px;
    cursor: pointer;
}

/* Light Theme */
body.light .card-item {
    background: rgba(19, 19, 19, 0.04);
}

body.light .card-item:hover {
    background: rgba(19, 19, 19, 0.08);
}

body.light .card-item.selected {
    background: rgba(0, 190, 58, 0.08);
    border: 1px solid rgba(0, 190, 58, 0.24);
}

body.light .card-title {
    color: #131313;
}

body.light .card-price {
    color: #0DB740;
}

body.light .card-store-price {
    color: rgba(19, 19, 19, 0.48);
}

/* Стили для скелетона */
.skeleton-animation {
    position: relative;
    overflow: hidden;
    border-radius: 8px;
    background: rgba(255, 255, 255, 0.04);
}

.skeleton-animation::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background: linear-gradient(90deg,
            transparent 0,
            rgba(255, 255, 255, 0.1) 20%,
            rgba(255, 255, 255, 0.2) 60%,
            transparent);
    animation: shimmer 2s infinite;
}

body.light .skeleton-animation {
    background: rgba(19, 19, 19, 0.04);
}

body.light .skeleton-animation::after {
    background: linear-gradient(90deg,
            transparent 0,
            rgba(19, 19, 19, 0.08) 20%,
            rgba(19, 19, 19, 0.12) 60%,
            transparent);
}

@media screen and (max-width: 768px) {
    .select-other-pay .price {
        display: flex;
    }

    body.light .name{
                color: var(--Text-Secondary, rgba(19, 19, 19, 0.72));
    }

    .name {
        color: var(--Text-Secondary, rgba(255, 255, 255, 0.48));
        display: block;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        margin: 0 0 4px 0;
    }
}

@keyframes shimmer {
    100% {
        transform: translateX(100%);
    }
}

.skeleton-img {
    filter: grayscale(100%) brightness(95%) contrast(90%);
    opacity: 0.2;
}

.error {
    color: red;
    padding: 1rem;
    text-align: center;
}
</style>