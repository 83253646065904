<template>

    <div id="generator-popup" class="popup-block" v-if="show">
        <div class="popup-bg" @click="close"></div>
        <div class="block">
            <div class="close" @click="close"></div>

            <div class="title">Выберите номинал</div>

            <div class="balance">
                <div class="names">У вас бонусов:</div>
                <div class="number">
                    <img src="/images/stm-coin.svg" alt="coin" />
                    {{ authStore.userBalance }}
                </div>
            </div>

            <div class="desc">
                У вас есть {{ authStore.userBalance }} бонусных баллов, которые вы можете использовать для создания
                промокода.
                <br>
                1 бонусный балл = 1 ₽
            </div>

            <div v-if="promoStore.loading">
                <div class="select">
                    <label v-for="i in 4" :key="i" class="skeleton-animation"
                        style="width: 100px; height: 36px;"></label>
                </div>
                <button class="btns skeleton-animation" v-if="promoStore.loading" disabled
                    style="width: 245px; height: 48px;">

                </button>
            </div>

            <template v-else>
                <div class="select" :style="{ gap: windowWidth <= 768 ? '1px' : '2px' }">
                    <label v-for="coupon in promoStore.availableCoupons" :key="coupon.id">
                        <input type="radio" name="select" :value="coupon.id" v-model="selectedCouponId">
                        <span :style="{
                            padding: `${windowWidth <= 768 ? '8px' : '9px'} ${getSpanPadding(coupon.nominal)}px`,
                            fontSize: windowWidth <= 375 ? '12px' : windowWidth <= 768 ? '13px' : '14px'
                        }">
                            {{ coupon.nominal }}₽
                        </span>
                    </label>
                </div>

                <div v-if="successMessage" class="error green">
                    {{ successMessage }}
                </div>
                <div v-else-if="promoStore.error" class="error red">
                    {{ promoStore.error }}
                </div>

                <div class="promo-block" v-if="promoStore.lastGeneratedPromo">
                    <div class="label">
                        <label>Промокод</label>
                        <div class="input">
                            <input type="text" :value="promoStore.lastGeneratedPromo.code" readonly>
                            <div class="copy" @click="copyPromo">{{ copyStatus }}</div>
                        </div>
                    </div>

                    <!-- <div class="promo-details">
                            <span>У вас есть {{ promoStore.lastGeneratedPromo.couponVal }}₽</span>
                            <span>Действует до: {{ formatDate(promoStore.lastGeneratedPromo.exp) }}</span>
                        </div>
                        <div>Промокод успешно сгенерирован. Вы можете скопировать его и отправить другу.</div> -->
                </div>


                <button class="btns" v-else @click="generatePromo" :disabled="!canGenerate">
                    {{ promoStore.loading ? 'Генерация...' : 'Сгенерировать промокод' }}
                </button>
            </template>
        </div>
    </div>
</template>

<script>
import { useAuthStore } from '@/store/auth';
import { usePromoStore } from '@/service/api/generate_promo';

export default {
    name: 'GeneratePromo',

    emits: ['promo-generated'],

    setup() {
        const authStore = useAuthStore();
        const promoStore = usePromoStore();
        return { authStore, promoStore };
    },

    data() {
        return {
            show: false,
            selectedCouponId: null,
            copyStatus: 'Скопировать',
            copyTimeout: null,
            successMessage: null,
            windowWidth: window.innerWidth

        }
    },

    computed: {
        isMobile() {
            return this.windowWidth <= 768;
        },

        isSmallMobile() {
            return this.windowWidth <= 375;
        },
        getSpanPadding() {
            return (nominal) => {
                const length = nominal.toString().length;

                // Базовые значения паддингов
                const basePadding = this.isMobile ? 16 : 28;

                // Расчет паддинга в зависимости от длины числа и устройства
                if (this.isMobile) {
                    if (length <= 3) return 12;
                    if (length === 4) return 10;
                    if (length === 5) return 8;
                    return 6;
                } else {
                    if (length <= 3) return basePadding;
                    if (length === 4) return basePadding - 4;
                    if (length === 5) return basePadding - 8;
                    return basePadding - 12;
                }
            };
        },
        canGenerate() {
            return this.selectedCouponId && !this.promoStore.loading;
        }
    },
    mounted() {
        window.addEventListener('resize', this.handleResize);

    },
    methods: {
        handleResize() {
            this.$forceUpdate();
        },
        formatDate(dateStr) {
            if (!dateStr) return '';
            return new Date(dateStr).toLocaleDateString();
        },

        async open() {
            this.show = true;
            await this.promoStore.fetchAvailableCoupons();

            if (this.promoStore.availableCoupons.length > 0) {
                this.selectedCouponId = this.promoStore.availableCoupons[0].id;
            }
        },

        async close() {
            this.show = false;
            this.selectedCouponId = null;
            this.successMessage = null;
        },


        async generatePromo() {
            if (!this.selectedCouponId) return;
            this.successMessage = null;

            try {
                await this.promoStore.generatePromo(this.selectedCouponId);
                await this.authStore.fetchUserData();

                const selectedCoupon = this.promoStore.availableCoupons.find(
                    coupon => coupon.id === this.selectedCouponId
                );

                if (selectedCoupon) {
                    this.successMessage = `Промокод на ${selectedCoupon.nominal} ₽ успешно создан`;
                    // Эмитим событие для обновления списка промокодов
                    this.$emit('promo-generated');
                }
            } catch (error) {
                console.error('Failed to generate promo:', error);
                this.successMessage = null;
            }
        },

        async copyPromo() {
            if (!this.promoStore.lastGeneratedPromo?.code) return;

            try {
                await this.promoStore.copyToClipboard(this.promoStore.lastGeneratedPromo.code);
                this.copyStatus = 'Скопировано';

                if (this.copyTimeout) {
                    clearTimeout(this.copyTimeout);
                }

                this.copyTimeout = setTimeout(() => {
                    this.copyStatus = 'Скопировать';
                }, 2000);
            } catch (error) {
                console.error('Error copying promo:', error);
            }
        }
    },

    beforeUnmount() {
        window.removeEventListener('resize', this.handleResize);
        if (this.copyTimeout) {
            clearTimeout(this.copyTimeout);
        }
    }
}
</script>


<style scoped>
#generator-popup .desc {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    margin: 20px 0 20px 0;
    text-align: left;
}

.popup-block .block {
    border-radius: 16px;
    background: linear-gradient(0deg, var(--Surface-Primary-Default, rgba(237, 238, 238, 0.04)) 0%, var(--Surface-Primary-Default, rgba(237, 238, 238, 0.04)) 100%), var(--Surface-BG, #131313);
    width: 100%;
    max-width: 447px;
    padding: 40px 32px;
    position: fixed;
    top: 50%;
    left: 50%;
    z-index: 99999999999;
    transform: translate(-50%, -50%);
    color: var(--Text-Secondary, rgba(237, 238, 238, 0.48));
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}

.popup-block .close {
    width: 24px;
    height: 24px;
    background: url(../images/popup-close.svg) no-repeat;
    cursor: pointer;
    position: absolute;
    top: 20px;
    right: 20px;
    transition: .5s;
}

#generator-popup .title {
    text-align: left;
}

.popup-block .title {
    color: var(--Text-Primary, #EDEEEE);
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    margin: 0 0 20px 0;
}

#generator-popup .balance .number {
    display: flex;
    align-items: center;
    gap: 8px;
    color: #FFD703;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}



#generator-popup .select {
    margin: 0 0 20px 0;
    display: inline-flex;
    padding: 2px;
    border-radius: 10px;
    background: var(--Surface-BG, #131313);
    width: 100%;
    justify-content: space-between;
    gap: 2px;
}

#generator-popup .error {
    border-radius: 16px;
    padding: 16px 16px 16px 56px;
    color: var(--Text-Primary, #EDEEEE);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    margin: 0 0 20px 0;
}

#generator-popup .error.green {
    background: url(../images/generator-popup-icon1.svg) no-repeat 16px, rgba(36, 255, 111, 0.24);
}

#generator-popup .error.red {
    background: url(../images/generator-popup-icon2.svg) no-repeat 16px, rgba(255, 75, 36, 0.24);
}

body.light #generator-popup .error {
    color: var(--Text-Primary, #131313);
}

#generator-popup .balance .names {
    margin: 0 0 8px 0;
}

#generator-popup .select input {
    display: none;
}

#generator-popup .btns:hover {
    background: var(--Button-Accent-Hover, #01DB4B);
    color: var(--Text-Primary-Inverse, #131313);
}

input,
button,
textarea,
select {
    font-size: 1rem;
    outline: none;
    font-family: 'Inter', sans-serif;
}

#generator-popup .select input:checked+span {
    background: linear-gradient(0deg, var(--Surface-Primary-Default, rgba(237, 238, 238, 0.04)) 0%, var(--Surface-Primary-Default, rgba(237, 238, 238, 0.04)) 100%), var(--Surface-BG, #131313);
    color: var(--Text-Primary, #EDEEEE);
}

#generator-popup .select span {
    display: block;
    cursor: pointer;
    border-radius: 8px;
    transition: .5s;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    white-space: nowrap;
    text-align: center;
}

#generator-popup .select label {
    flex: 1;
    min-width: min-content;
    margin: 0 1px;
}

#generator-popup .label .input {
    display: flex;
    align-items: center;
    padding: 8px 6px 8px 16px;
    border-radius: 8px;
    border: 1px solid var(--Border-Primary-Default, rgba(255, 255, 255, 0.04));
    background: var(--Surface-Primary-Default, rgba(255, 255, 255, 0.04));
    width: 100%;
    transition: .5s;
}

#generator-popup .label .input:hover {
    background: var(--Surface-Prymary-Hover, rgba(255, 255, 255, 0.08));
}

#generator-popup .label .input:focus-within {
    border: 1px solid var(--Border-Accent, #24FF6F);
}

#generator-popup .label.error .input {
    border: 1px solid rgba(255, 16, 16, 0.33);
    background: rgba(255, 0, 0, 0.04);
}

#generator-popup .label input {
    width: 100%;
    flex: 1;
    border: 0px;
    background: none;
    color: var(--Text-Primary, #FFF);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
}

#generator-popup .label ::placeholder {
    color: var(--Text-Tetriary, rgba(255, 255, 255, 0.18));
}

#generator-popup .label .copy {
    display: block;
    text-decoration: none;
    cursor: pointer;
    padding: 2px 8px;
    border-radius: 6px;
    border: 1px solid var(--Border-Primary-Default, rgba(255, 255, 255, 0.04));
    background: var(--Surface-Primary-Default, rgba(255, 255, 255, 0.04));
    color: var(--Text-Primary, #FFF);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    transition: .5s;
    margin: 0 0 0 5px;
}

#generator-popup .label .copy:hover {
    border: 1px solid var(--Border-Accent-Secondary, rgba(36, 255, 111, 0.24));
    background: var(--Surface-Accent-Hover, rgba(36, 255, 111, 0.04));
    color: var(--Text-Accent, #24FF6F);
}

#generator-popup .promo-block {
    border-radius: 12px;
    background: var(--Surface-Accent-Hover, rgba(36, 255, 111, 0.04));
    padding: 16px;
    margin: 0 0 20px 0;
}

#generator-popup .label {
    margin: 0 0 10px 0;
    position: relative;
    flex: 1;
}

body.light #generator-popup .select {
    background: var(--Surface-Prymary-Hover, rgba(19, 19, 19, 0.08));
    color: var(--Text-Primary, #131313);
}

body.light #generator-popup .select input:checked+span {
    background: var(--White-600, #FFF);
    color: var(--Text-Primary, #131313);
}

body.light #generator-popup .label .input {
    border: 1px solid var(--Border-Primary-Default, rgba(19, 19, 19, 0.06));
    background: var(--Surface-Primary-Default, rgba(19, 19, 19, 0.06));
}

body.light #generator-popup .label input {
    color: var(--Text-Primary, #131313);
}

body.light #generator-popup .label .copy {
    border: 1px solid var(--Border-Primary-Default, rgba(19, 19, 19, 0.06));
    background: var(--Surface-Primary-Default, rgba(19, 19, 19, 0.06));
    color: var(--Text-Primary, #131313);
}

body.light #generator-popup .btns {
    background: var(--Button-Accent, #0DB740);
    color: var(--Text-Primary-Inverse, #FFF);
    border: 0px;
}

body.light #generator-popup .promo-block {
    background: var(--Surface-Accent-Hover, rgba(0, 190, 58, 0.12));
}

#generator-popup .promo-block {
    border-radius: 12px;
    background: var(--Surface-Accent-Hover, rgba(36, 255, 111, 0.04));
    padding: 16px;
    margin: 0 0 20px 0;
}

#generator-popup .select {
    margin: 0 0 20px 0;
    display: inline-flex;
    gap: 2px;
    padding: 2px;
    border-radius: 10px;
    background: var(--Surface-BG, #131313);
}

.popup-block {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.popup-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
}

.block {
    position: relative;
    background: #1a1a1a;
    border-radius: 12px;
    padding: 24px;
    max-width: 480px;
    width: 90%;
    margin: 20px;
}

.close {
    position: absolute;
    top: 16px;
    right: 16px;
    width: 24px;
    height: 24px;
    cursor: pointer;
    background: url('/images/close.svg') no-repeat center;
}

.coin-icon {
    width: 24px;
    height: 24px;
    margin-right: 8px;
}

/* Add other necessary styles */

.skeleton-animation {
    position: relative;
    overflow: hidden;
    border-radius: 8px;
    background: rgba(255, 255, 255, 0.04);
}

.skeleton-animation::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background: linear-gradient(90deg,
            transparent 0,
            rgba(255, 255, 255, 0.1) 20%,
            rgba(255, 255, 255, 0.2) 60%,
            transparent);
    animation: shimmer 2s infinite;
}

body.light .skeleton-animation {
    background: rgba(19, 19, 19, 0.04);
}

body.light .skeleton-animation::after {
    background: linear-gradient(90deg,
            transparent 0,
            rgba(19, 19, 19, 0.08) 20%,
            rgba(19, 19, 19, 0.12) 60%,
            transparent);
}

@keyframes shimmer {
    100% {
        transform: translateX(100%);
    }
}

/* Основные медиа-запросы для мобильных устройств */
@media (max-width: 768px) {
    .popup-block .block {
        width: calc(100% - 32px);
        padding: 32px 20px;
        margin: auto;
        max-height: 90vh;
        overflow-y: auto;
    }

    #generator-popup .title {
        font-size: 20px;
        margin-bottom: 16px;
    }

    #generator-popup .balance .number {
        font-size: 18px;
    }

    #generator-popup .select {
        gap: 1px;
        padding: 1px;
    }

    #generator-popup .select span {
        font-size: 13px;
        padding-top: 8px;
        padding-bottom: 8px;
    }

    #generator-popup .label .input {
        padding: 6px 8px;
    }

    #generator-popup .label input {
        font-size: 13px;
    }

    #generator-popup .label .copy {
        font-size: 11px;
        padding: 2px 6px;
    }

    #generator-popup .promo-block {
        padding: 12px;
    }

    #generator-popup .select label {
        flex: 1;
        min-width: auto;
    }
}

@media (max-width: 375px) {
    .popup-block .block {
        padding: 24px 16px;
    }

    #generator-popup .select span {
        font-size: 12px;
        padding-top: 6px;
        padding-bottom: 6px;
    }

    #generator-popup .balance .number {
        font-size: 16px;
    }

    #generator-popup .select {
        gap: 1px;
    }

    #generator-popup .select label {
        min-width: 0;
    }
}

@media (max-width: 768px) {
    .popup-block .block {
        -webkit-overflow-scrolling: touch;
    }

    #generator-popup .select::-webkit-scrollbar {
        width: 4px;
        height: 4px;
    }

    #generator-popup .select::-webkit-scrollbar-thumb {
        background: rgba(255, 255, 255, 0.1);
        border-radius: 2px;
    }

    /* Стили для светлой темы на мобильных */
    body.light #generator-popup .select::-webkit-scrollbar-thumb {
        background: rgba(0, 0, 0, 0.1);
    }
}

/* Поддержка Safe Area на iPhone */
@supports (padding-bottom: env(safe-area-inset-bottom)) {
    @media (max-width: 768px) {
        .popup-block .block {
            padding-bottom: calc(20px + env(safe-area-inset-bottom));
        }
    }
}

@media (max-width: 768px) {
    @supports (-webkit-touch-callout: none) {
        .popup-block {
            position: fixed;
            height: -webkit-fill-available;
        }
    }
}

@media (max-width: 375px) {
    #generator-popup .select span {
        font-size: 12px;
    }
}
</style>