<template>
    <div class="bank-selection-overlay" @click.self="closeBottomSheet">
        <div class="modal-window" :class="{ expanded: showAll }" ref="modalWindow">
            <div class="drag-area">
                <div class="drag-handle"></div>
            </div>

            <header class="headers" :class="{ 'header-expanded': showAll }">
                <button v-if="showAll" class="back-button" @click="closeBottomSheet" tabindex="0">
                    <img :src="`/images/${isDarkTheme ? 'arrow_left_white.svg' : 'arrow_left_dart.svg'}`" alt="Назад"
                        class="back-icon" />
                </button>
                <span v-if="showAll" class="back-text">Вернуться</span>
            </header>

            <div class="content" :class="{ 'search-active': isSearchActive }">
                <template v-if="showAll">
                    <div class="sticky-header">
                        <h2 class="selection-title">Выберите ваш банк для совершения оплаты</h2>
                        <div class="search-container">
                            <form class="search-form" role="search" @submit.prevent>
                                <input type="search" v-model="searchQuery" class="search-input" placeholder="Поиск"
                                    @focus="handleSearchFocus" @blur="handleSearchBlur" />
                                <svg class="search-icon" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path d="M21 21l-4.35-4.35M19 11a8 8 0 1 1-16 0 8 8 0 0 1 16 0z"
                                        stroke="currentColor" stroke-width="2" stroke-linecap="round" />
                                </svg>
                            </form>
                        </div>
                    </div>
                </template>

                <h2 v-else class="initial-title">
                    Выберите ваш банк, чтобы завершить оплату в мобильном приложении
                </h2>

                <div v-if="isLoading" class="loading-state">
                    <LoadingIndicator />
                    <p class="loading-text">Загрузка списка банков...</p>
                </div>

                <div v-else-if="error" class="error-state">
                    <p class="error-text">{{ error }}</p>
                    <button class="retry-button" @click="fetchBanks">Повторить загрузку</button>
                </div>

                <nav v-else class="bank-list" role="navigation">
                    <div v-for="bank in displayedBanks" :key="bank.schema" class="bank-item"
                        @click="handleBankSelect(bank)">
                        <div class="bank-info">
                            <div class="logo-container">
                                <LoadingIndicator v-if="!bank.isLoaded" />
                                <img :src="bank.logoURL" :alt="bank.bankName" class="bank-logo"
                                    @load="bank.isLoaded = true" :style="{ opacity: bank.isLoaded ? 1 : 0 }">
                            </div>
                            <span class="bank-name">{{ bank.bankName }}</span>
                        </div>
                        <svg class="arrow-icon" width="20" height="20" viewBox="0 0 24 24" fill="none">
                            <path d="M9 18l6-6-6-6" stroke="#C7C7CC" stroke-width="2" />
                        </svg>
                    </div>

                    <div v-if="!showAll" class="bank-item show-more" @click.stop="expandList">
                        <div class="bank-info">
                            <div class="show-more-icon">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path d="M6 9l6 6 6-6" stroke="#8E8E93" stroke-width="2" />
                                </svg>
                            </div>
                            <span class="more-text">Показать больше</span>
                        </div>
                    </div>
                </nav>
            </div>
        </div>
    </div>
</template>

<script>
import LoadingIndicator from '@/components/LoadingIndicator/Loadingindicator.vue';

export default {
    components: {
        LoadingIndicator
    },
    name: 'BankBottomSheet',
    props: {
        qrUrl: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            preventOutsideClick: false,
            isLoading: true,
            banks: [],
            showAll: false,
            searchQuery: '',
            isSearchActive: false,
            currentHeight: 0,
            isClosing: false,
            MAIN_BANK_SCHEMAS: [
                'bank100000000111',
                'bank100000000004',
                'bank110000000005',
                'bank100000000008'
            ]
        }
    },
    computed: {
        isDarkTheme() {
            return !document.body.classList.contains('light');
        },
        mainBanks() {
            return this.MAIN_BANK_SCHEMAS
                .map(schema => this.banks.find(bank => bank.schema === schema))
                .filter(bank => bank);
        },
        displayedBanks() {
            let banksToShow = this.showAll ? this.banks : this.mainBanks;
            if (this.searchQuery?.trim()) {
                const query = this.searchQuery.toLowerCase().trim();
                return banksToShow.filter(bank =>
                    bank.bankName.toLowerCase().includes(query)
                );
            }
            return banksToShow;
        }
    },
    methods: {
        initDragHandling() {
            const dragArea = this.$refs.modalWindow;
            let startY = 0;
            let startTransform = 0;

            const handleTouchStart = (e) => {
                startY = e.touches[0].clientY;
                startTransform = 0;
                dragArea.style.transition = 'none';
            };

            const handleTouchMove = (e) => {
                const deltaY = e.touches[0].clientY - startY;
                if (deltaY > 0) { // Только вниз
                    startTransform = deltaY;
                    dragArea.style.transform = `translateY(${deltaY}px)`;
                }
            };

            const handleTouchEnd = () => {
                dragArea.style.transition = 'transform 0.3s cubic-bezier(0.4, 0, 0.2, 1)';
                if (startTransform > 100) { // Порог для закрытия
                    this.closeBottomSheet();
                } else {
                    dragArea.style.transform = 'translateY(0)';
                }
            };

            const dragHandle = this.$refs.modalWindow.querySelector('.drag-area');
            dragHandle.addEventListener('touchstart', handleTouchStart);
            dragHandle.addEventListener('touchmove', handleTouchMove);
            dragHandle.addEventListener('touchend', handleTouchEnd);

            // Сохраним ссылки для удаления слушателей
            this.dragListeners = {
                handleTouchStart,
                handleTouchMove,
                handleTouchEnd,
                dragHandle
            };
        },

        destroyDragHandling() {
            if (this.dragListeners) {
                const { handleTouchStart, handleTouchMove, handleTouchEnd, dragHandle } = this.dragListeners;
                dragHandle.removeEventListener('touchstart', handleTouchStart);
                dragHandle.removeEventListener('touchmove', handleTouchMove);
                dragHandle.removeEventListener('touchend', handleTouchEnd);
            }
        },
        async fetchBanks() {
            this.isLoading = true;
            this.error = null;

            try {
                const response = await fetch('https://qr.nspk.ru/proxyapp/c2bmembers.json');
                if (!response.ok) throw new Error('Не удалось загрузить список банков');

                const data = await response.json();
                this.banks = data.dictionary.map(bank => ({
                    ...bank,
                    logoURL: bank.logoURL || '/images/default-bank-logo.svg',
                    isLoaded: false
                }));
            } catch (error) {
                this.error = 'Произошла ошибка при загрузке списка банков';
                console.error('Ошибка загрузки списка банков:', error);
            } finally {
                this.isLoading = false;
            }
        },
        handleSearchFocus() {
            this.isSearchActive = true;

            this.$nextTick(() => {
                if (this.$refs.modalWindow) {
                    const modalWindow = this.$refs.modalWindow;

                    if (!this.currentHeight) {
                        this.currentHeight = modalWindow.offsetHeight;
                        modalWindow.style.height = `${this.currentHeight}px`;
                    }

                    // Когда поисковая строка активна, отключаем закрытие по клику вне
                    this.preventOutsideClick = true;
                }
            });
        },
        handleSearchBlur() {
            // Небольшая задержка перед сбросом флага, чтобы избежать мгновенного закрытия
            setTimeout(() => {
                this.isSearchActive = false;
                this.preventOutsideClick = false;

                if (!this.isClosing && this.$refs.modalWindow) {
                    this.$refs.modalWindow.style.height = '';
                    this.currentHeight = 0;
                }
            }, 150);
        },


        async handleBankSelect(bank) {
            if (!bank || !this.qrUrl) return;

            try {
                const deepLink = this.qrUrl.replace('https://', `${bank.schema}://`);
                window.location.href = deepLink;

                setTimeout(() => {
                    window.history.pushState({}, '', window.location.pathname);
                }, 200);
            } catch (error) {
                console.error('Error opening bank app:', error);
            }
        },
        expandList() {
            this.showAll = true;
        },
        closeBottomSheet() {
            if (this.preventOutsideClick) return;

            const modalWindow = this.$refs.modalWindow;
            if (modalWindow && !this.isClosing) {
                this.isClosing = true;
                modalWindow.style.transform = 'translateY(100%)';

                setTimeout(() => {
                    this.showAll = false;
                    this.isClosing = false;
                    this.$emit('close');
                    modalWindow.style.height = '';
                    this.currentHeight = 0;
                }, 300);
            } else {
                this.$emit('close');
            }
        },
    },
    async created() {
        await this.fetchBanks();
    },
    mounted() {
        setTimeout(() => {
            if (this.$refs.modalWindow) {
                this.$refs.modalWindow.style.transform = 'translateY(0)';
                this.initDragHandling();

            }
        }, 50);
    },
    beforeUnmount() {
        this.destroyDragHandling();

        this.isClosing = false;
        if (this.$refs.modalWindow) {
            this.$refs.modalWindow.style.height = '';
            this.currentHeight = 0;
        }
    }
}
</script>

<style scoped>
.loading-state {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 40px 0;
    gap: 16px;
}

.loading-text {
    color: rgba(237, 238, 238, 0.48);
    font: 400 14px/20px Inter, sans-serif;
    text-align: center;
}

.error-state {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px 16px;
    gap: 16px;
}

.error-text {
    color: #FF3B30;
    font: 400 14px/20px Inter, sans-serif;
    text-align: center;
}

.retry-button {
    background: rgba(237, 238, 238, 0.04);
    border: 1px solid rgba(237, 238, 238, 0.04);
    border-radius: 8px;
    padding: 8px 16px;
    color: #EDEEEE;
    font: 500 14px Inter, sans-serif;
    cursor: pointer;
    transition: background-color 0.2s ease;
}

.bank-selection-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.4);
    z-index: 9999;
    display: flex;
    align-items: flex-end;
    animation: fadeIn 0.3s ease-out;
}

.modal-window {
    width: 100%;
    background: #131313;
    border-radius: 20px 20px 0 0;
    max-height: 97vh;
    overflow-y: auto;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    transform: translateY(-0%);
    transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1), height 0.3s ease;
    will-change: transform;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    touch-action: none;
}

.drag-area {
    position: relative;
    background: inherit;
    border-radius: 20px 20px 0 0;
    z-index: 10;
    padding: 12px 0;
    cursor: grab;
    touch-action: none;
    -webkit-user-select: none;
    user-select: none;
}

.drag-handle {
    width: 36px;
    height: 4px;
    background: rgba(237, 238, 238, 0.2);
    border-radius: 2px;
    margin: 0 auto;
}

.headers {
    display: flex;
    align-items: center;
    padding: 8px 16px 0;
}

.back-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    border-radius: 8px;
    background: rgba(237, 238, 238, 0.04);
    border: none;
    cursor: pointer;
}

.back-text {
    color: #EDEEEE;
    font: 400 14px/24px Inter, sans-serif;
    margin-left: 8px;
}

.sticky-header {
    position: sticky;
    top: 0;
    background: inherit;
    padding-top: 16px;
    z-index: 2;
}

.content {
    padding: 0 16px 32px;
    overflow-y: auto;
    min-height: 200px;
}

.content.search-active {
    padding-bottom: 80vh;
}

.selection-title {
    color: rgba(237, 238, 238, 0.48);
    font: 400 14px/24px Inter, sans-serif;
    margin-bottom: 16px;
}

.initial-title {
    color: rgba(237, 238, 238, 0.48);
    font: 400 14px/20px Inter, sans-serif;
    text-align: center;
    padding: 0 20px;
    margin: 12px 0 20px;
}

.search-form {
    position: relative;
    margin-bottom: 16px;
    display: flex;
    align-items: center;
    background: rgba(237, 238, 238, 0.04);
    border: 1px solid rgba(237, 238, 238, 0.04);
    border-radius: 8px;
    padding: 8px 16px;
}

.search-input {
    flex: 1;
    background: transparent;
    border: none;
    color: #EDEEEE;
    font: 400 14px/24px Inter, sans-serif;
}

.search-input::placeholder {
    color: rgba(237, 238, 238, 0.18);
}

.search-icon {
    color: rgba(237, 238, 238, 0.18);
}

.bank-list {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.bank-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 16px;
    border-radius: 16px;
    background: rgba(237, 238, 238, 0.04);
    cursor: pointer;
    transition: background-color 0.2s ease;
    -webkit-tap-highlight-color: transparent;
}

.bank-item:active {
    background: rgba(237, 238, 238, 0.08);
}

.bank-info {
    display: flex;
    align-items: center;
    gap: 16px;
}

.bank-logo {
    width: 40px;
    height: 40px;
    border-radius: 12px;
    background: #FFFFFF;
    object-fit: contain;
}

.bank-name {
    color: #EDEEEE;
    font: 500 16px Inter, sans-serif;
}

.show-more {
    background: transparent;
}

.more-text {
    color: rgba(237, 238, 238, 0.48);
    font: 500 16px Inter, sans-serif;
}

/* Анимации */
@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

/* Светлая тема */
body.light .modal-window {
    background: #FFFFFF;
}

body.light .drag-handle {
    background: rgba(0, 0, 0, 0.2);
}

body.light .back-button {
    background: rgba(0, 0, 0, 0.04);
}

body.light .back-text,
body.light .bank-name {
    color: #131313;
}

body.light .selection-title,
body.light .initial-title {
    color: rgba(19, 19, 19, 0.48);
}

body.light .search-form {
    background: rgba(0, 0, 0, 0.04);
    border-color: rgba(0, 0, 0, 0.04);
}

body.light .search-input {
    color: #131313;
}

body.light .search-input::placeholder {
    color: rgba(19, 19, 19, 0.48);
}

body.light .bank-item {
    background: rgba(0, 0, 0, 0.04);
}

body.light .bank-item:active {
    background: rgba(0, 0, 0, 0.08);
}

body.light .more-text {
    color: rgba(19, 19, 19, 0.48);
}

body.light .loading-text {
    color: rgba(19, 19, 19, 0.48);
}

body.light .retry-button {
    background: rgba(19, 19, 19, 0.04);
    border-color: rgba(19, 19, 19, 0.04);
    color: #131313;
}

body.light .retry-button:hover {
    background: rgba(19, 19, 19, 0.08);
}

/* Медиа-запросы */
@media (max-width: 991px) {
    .modal-window {
        max-height: 97vh;
    }

    .bank-item {
        padding: 10px 12px;
    }

    .bank-logo {
        width: 36px;
        height: 36px;
    }

    .content.search-active {
        padding-bottom: 40vh;
    }
}

@media (max-width: 375px) {
    .content {
        padding: 0 12px 24px;
    }

    .bank-logo {
        width: 32px;
        height: 32px;
    }

    .bank-name {
        font-size: 14px;
    }

    .search-input {
        font-size: 14px;
    }
}

/* Поддержка безопасной зоны для iOS */
@supports (padding-bottom: env(safe-area-inset-bottom)) {
    .modal-window {
        padding-bottom: env(safe-area-inset-bottom);
    }
}

/* Скроллбар */
.modal-window::-webkit-scrollbar {
    width: 4px;
}

.modal-window::-webkit-scrollbar-track {
    background: rgba(237, 238, 238, 0.04);
}

.modal-window::-webkit-scrollbar-thumb {
    background: rgba(237, 238, 238, 0.1);
    border-radius: 2px;
}

body.light .modal-window::-webkit-scrollbar-track {
    background: rgba(19, 19, 19, 0.04);
}

body.light .modal-window::-webkit-scrollbar-thumb {
    background: rgba(19, 19, 19, 0.1);
}

/* Оптимизация для мобильных устройств */
@media (hover: none) and (pointer: coarse) {
    .bank-item:active {
        background: rgba(237, 238, 238, 0.08);
    }

    body.light .bank-item:active {
        background: rgba(0, 0, 0, 0.08);
    }
}

/* Фикс для iOS при открытой клавиатуре */
@supports (-webkit-touch-callout: none) {
    .content.search-active {
        padding-bottom: 50vh;
    }

    .bank-selection-overlay {
        position: fixed;
        height: 100%;
        /* Фиксируем высоту для iOS */
        height: -webkit-fill-available;
    }

    .modal-window {
        /* Обеспечиваем правильное поведение на iOS */
        height: auto;
        max-height: calc(100% - env(safe-area-inset-top) - env(safe-area-inset-bottom));
    }
}
</style>