<template>
  <div class="container">
    <h1 class="title">{{ tabActive === 1 ? 'Пополнить баланс STEAM в тенге Казахстан' : 'Пополнить баланс STEAM' }}</h1>
    <div class="description" @click="console.log(this.paymentMethod)">
      <h2>Пополнить кошелек Steam в несколько кликов</h2>
      <h3>У нас выгодное пополнение баланса Steam!</h3>
    </div>
    <div class="tabs">
      <div class="select-name-mobile">Выберите страну аккаунта</div>
      <div class="select-mobile-country">
        <div class="select"><img src="/images/flag-ru.svg" alt="" /> Россия</div>
        <ul class="tabs__caption">
          <li :class="{ active: $route.path === '/' }" @click="handleTabChange('/')">
            <img src="/images/flag-ru.svg" alt="" /> Россия
          </li>
          <li :class="{ active: $route.path === '/kazakhstan' }" @click="handleTabChange('/kazakhstan')">
            <img src="/images/flag-kz.svg" alt="" /> Казахстан
          </li>
          <li :class="{ active: $route.path === '/cards' }" @click="handleTabChange('/cards')">
            <img src="/images/flag-other.svg" alt="" />
            Турция, Аргентина, США
          </li>
          <li :class="{ active: $route.path === '/pubg-uc' }" @click="handleTabChange('/pubg-uc')">
            <img class="pubg-icon" alt="icon" src="/images/pubg-icon.svg" />
            PUBG M UC
          </li>
        </ul>
      </div>

      <div class="tabs__content active">
        <div v-if="tabActive == 0" class="tab-left">
          <ErrorInfo :message="paymentError" />
          <div class="label" :class="{ error: showError }">
            <label>Логин Steam</label>
            <div class="input">
              <input type="text" v-model="loginInput.value" @input="handleLoginInput" @blur="handleLoginBlur"
                placeholder="Введите логин" />
              <LoadingIndicator v-if="loginInputLoading && !loginInput.status" class="loading-indicator" />
              <CheckMarkIcon v-if="loginInput.status && !loginInputLoading" class="check-icon" />
              <div href="#" class="where" @click="openModal('promo-kek')">Где найти логин</div>
            </div>
            <ErrorInfo :message="loginInput.error" @open-error-login="modals['error-login'] = true" />
          </div>
          <input type="hidden" name="client-id-input" />
          <UniversalPopup :isVisible="modals['promo-kek']" title="Где взять логин" @close="closeModal('promo-kek')">
            <div class="block-popup">

              <div id="wherelogin-popup-text" class="text"><a href="https://store.steampowered.com/account/"
                  target="_blank">Перейдите по ссылке</a> и вы увидите
                ваш логин<br>
                Скриншот:<br>
                <img src="/images/nickSteam.webp"><br>
                Проще говоря нужен логин, по которому вы авторизуетесь в своём STEAM аккаунте. Это одно и тоже
                :)<br>
                Внимание! Не спутайте логин и никнейм, это очень важно. Никнейм профиля можно менять в STEAM, логин
                нет!
              </div>
            </div>
          </UniversalPopup>
          <div>
          </div>
          <div class=" label summ">
            <label>Сумма</label>
            <div class="input">
              <input type="number" placeholder="Введите сумму" @input="handleAmountInput" @change="handleAmountChange"
                v-model.number="amountInput.value" />
              <span class="absolute right-2 top-1/2 -translate-y-1/2 pr-6"> {{ currencySymbol }}</span>
              <div class="speed-enter">
                <span @click="setAmount(minAmount)">{{ minAmount }} {{ currency }}</span>
                <span v-for="preset in ['low', 'middle', 'max']" :key="preset"
                  @click="setAmount(settings?.amountButtons?.[tabActive === 1 ? 'kzt' : 'rub']?.[`amount_preset_${preset}`])">
                  {{ settings?.amountButtons?.[tabActive === 1 ? 'kzt' : 'rub']?.[`amount_preset_${preset}`] }} {{
                    currency }}
                </span>
              </div>
            </div>
            <ErrorInfo :message="amountInput.error" />
          </div>

          <Popup title="Где взять промокод?" id="promo-kek">
            <div class="desc">
              Промокод на первое пополнение STAR <br />
              Нужны ещё? Раздаем их в ВК и ТГК - подписывайся чтобы не пропустить;)
            </div>
            <a href="https://vk.com/steammoney" class="vk" target="_blank">Наша группа ВК</a>
          </Popup>
          <DiscountProgress :amount="amountInput.value" :currency="currency" :settings="settings" />
          <div class="flex-label">
            <div class="label">
              <label>Email</label>
              <div class="input">
                <input type="email" v-model="emailInput.value" @input="handleEmailInput" @blur="handleEmailBlur"
                  placeholder="Введите свой Email" />
              </div>
              <ErrorInfo :message="this.emailInput.error" />
            </div>
            <div class="label">
              <label>Промокод</label>
              <div class="input">
                <input type="text" v-model="promoInput.value" placeholder="Введите промокод" @input="handlePromoInput"
                  @blur="handlePromoBlur" @change="calculate" ref="promoInput" />
                <CheckMarkIcon v-if="paymentData.discountPercentByPromo > 0 && promoInput.status" class="check-icon" />
                <div class="question promowindow">
                  <div class="icon"></div>
                  <div class="text"> Промокод на первое пополнение: first <br>
                    <br>
                    Хотите еще промокоды? <br>
                    <br>
                    Можете получить личный промокод в <a :href="socialBotLinks.telegram" target="_blank">нашем
                      Telegram</a>
                    боте <br>
                    <br>
                    Раздаём в нашем канале<a :href="socialLinks.telegram" target="_blank"> Telegram </a>
                    —
                    подпишись,
                    чтобы не
                    пропустить! 🔥
                  </div>
                </div>
              </div>
              <ErrorInfo :message="promoInput.error" type="warning" />
            </div>
          </div>
          <div class="checkbox" @click="toggleAgree">
            <input v-model="aggree" type="checkbox" name="checkbox" />
            <span>
              Я подтверждаю, что указал верный логин, и согласен с
              <a :href="getUrl('offer')" target="_blank">публичной офертой</a>,
              <a :href="getUrl('privacy')" target="_blank">политикой конфиденциальности</a>,
              <a role="button" class="return pointers" tabindex="0" rel="nofollow"
                @click.stop="openModal('return-rule')">
                правилами возврата
              </a>
            </span>
          </div>

          <button class="order"
            :class="{ disabled: !isButtonEnabled || (tabActive === 0 ? isBalanceButtonDisabled : isCardButtonDisabled) }"
            :disabled="!isButtonEnabled || isLoading || (tabActive === 0 ? isBalanceButtonDisabled : isCardButtonDisabled)"
            @click="createOrder">
            <template v-if="isSettingsLoading">
              <LoadingIndicator />
            </template>
            <template v-else-if="tabActive === 0 ? isBalanceButtonDisabled : isCardButtonDisabled">
              Платежи временно отключены. Попробуйте позже.
            </template>
            <template v-else>
              {{ isLoading ? 'Обработка...' : `Перейти к оплате (${formatLabel(paymentData.total)})` }}
            </template>
          </button>
        </div>

        <div v-if="tabActive == 1" class="tab-left">
          <ErrorInfo :message="paymentError" />
          <div class="label" :class="{ error: showError }">
            <label>Логин Steam</label>
            <div class="input">
              <input type="text" v-model="loginInput.value" @input="handleLoginInput" @blur="handleLoginBlur"
                placeholder="Введите логин" />
              <LoadingIndicator v-if="loginInputLoading && !loginInput.status" class="loading-indicator" />
              <CheckMarkIcon v-if="loginInput.status && !loginInputLoading" class="check-icon" />
              <div href="#" class="where" @click="openModal('promo-kek')">Где найти логин</div>
            </div>
            <ErrorInfo :message="loginInput.error" @open-error-login="modals['error-login'] = true" />
          </div>
          <input type="hidden" name="client-id-input" />
          <UniversalPopup :isVisible="modals['promo-kek']" title="Где взять логин" @close="closeModal('promo-kek')">
            <div class="block-popup">

              <div id="wherelogin-popup-text" class="text"><a href="https://store.steampowered.com/account/"
                  target="_blank">Перейдите по ссылке</a> и вы увидите
                ваш логин<br>
                Скриншот:<br>
                <img src="/images/nickSteam.webp"><br>
                Проще говоря нужен логин, по которому вы авторизуетесь в своём STEAM аккаунте. Это одно и тоже
                :)<br>
                Внимание! Не спутайте логин и никнейм, это очень важно. Никнейм профиля можно менять в STEAM, логин
                нет!
              </div>
            </div>
          </UniversalPopup>
          <div>
          </div>
          <div class=" label summ">
            <label>Сумма</label>
            <div class="input">
              <input type="number" placeholder="Введите сумму" @input="handleAmountInput" @change="handleAmountChange"
                v-model.number="amountInput.value" />
              <span class="absolute right-2 top-1/2 -translate-y-1/2 pr-6"> {{ currencySymbol }}</span>
              <div class="speed-enter">
                <span @click="setAmount(settings?.amountValidation?.[currencyType]?.min || 100)">
                  {{ settings?.amountValidation?.[currencyType]?.min || 100 }} {{ currency }}
                </span>
                <span v-for="preset in ['low', 'middle', 'max']" :key="preset"
                  @click="setAmount(settings?.amountButtons?.[tabActive === 1 ? 'kzt' : 'rub']?.[`amount_preset_${preset}`])">
                  {{ settings?.amountButtons?.[tabActive === 1 ? 'kzt' : 'rub']?.[`amount_preset_${preset}`] }} {{
                    currency }}
                </span>
              </div>
            </div>
            <ErrorInfo :message="amountInput.error" />
          </div>

          <Popup title="Где взять промокод?" id="promo-kek">
            <div class="desc">
              Промокод на первое пополнение STAR <br />
              Нужны ещё? Раздаем их в ВК и ТГК - подписывайся чтобы не пропустить;)
            </div>
            <a href="https://vk.com/steammoney" class="vk" target="_blank">Наша группа ВК</a>
          </Popup>
          <DiscountProgress :amount="amountInput.value" :currency="currency" :settings="settings" />
          <div class="flex-label">
            <div class="label">
              <label>Email</label>
              <div class="input">
                <input type="email" v-model="emailInput.value" @input="handleEmailInput" @blur="handleEmailBlur"
                  placeholder="Введите свой Email" />
              </div>
              <ErrorInfo :message="this.emailInput.error" />
            </div>
            <div class="label">
              <label>Промокод</label>
              <div class="input">
                <input type="text" v-model="promoInput.value" placeholder="Введите промокод" @input="handlePromoInput"
                  @blur="handlePromoBlur" @change="calculate" ref="promoInput" />
                <CheckMarkIcon v-if="paymentData.discountPercentByPromo > 0" class="check-icon" />
                <div class="question promowindow">
                  <div class="icon"></div>
                  <div class="text"> Промокод на первое пополнение: first <br>
                    <br>
                    Хотите еще промокоды? <br>
                    <br>
                    Можете получить личный промокод в <a :href="socialBotLinks.telegram" target="_blank">нашем
                      Telegram</a>
                    боте <br>
                    <br>
                    Также раздаём в канале <a :href="socialLinks.telegram" target="_blank">нашем
                      Telegram</a>
                    —
                    подпишись,
                    чтобы не
                    пропустить! 🔥
                  </div>
                </div>
              </div>
              <ErrorInfo :message="promoInput.error" type="warning" />
            </div>
          </div>
          <div class="checkbox" @click="toggleAgree">
            <input v-model="aggree" type="checkbox" name="checkbox" />
            <span>
              Я подтверждаю, что указал верный логин, и согласен с
              <a :href="getUrl('offer')" target="_blank">публичной офертой</a>,
              <a :href="getUrl('privacy')" target="_blank">политикой конфиденциальности</a>,
              <a role="button" class="return pointers" tabindex="0" rel="nofollow"
                @click.stop="openModal('return-rule')">
                правилами возврата
              </a>
            </span>
          </div>

          <button class="order" :class="{
            disabled: !isButtonEnabled ||
              (tabActive === 0 && isBalanceButtonDisabled) ||
              (tabActive === 1 && isCardButtonDisabledKz) ||
              (tabActive === 2 && isCardButtonDisabled)
          }" :disabled="!isButtonEnabled || isLoading ||
            (tabActive === 0 && isBalanceButtonDisabled) ||
            (tabActive === 1 && isCardButtonDisabledKz) ||
            (tabActive === 2 && isCardButtonDisabled)" @click="createOrder">
            <template v-if="isSettingsLoading">
              <LoadingIndicator />
            </template>
            <template v-else-if="
              (tabActive === 0 && isBalanceButtonDisabled) ||
              (tabActive === 1 && isCardButtonDisabledKz) ||
              (tabActive === 2 && isCardButtonDisabled)
            ">
              Платежи временно отключены. Попробуйте позже.
            </template>
            <template v-else>
              {{ isLoading ? 'Обработка...' : `Перейти к оплате (${paymentData.total} ₽)`
              }}
            </template>
          </button>
        </div>


        <div v-if="tabActive == 2" class="tab-left">
          <!-- <ErrorInfo :message="paymentError" /> -->
          <div class="label">
            <label>Email</label>
            <div class="input">
              <input type="email" v-model="emailInput.value" @input="handleEmailInput" @blur="handleEmailBlur"
                placeholder="Введите свой Email" />
              <div class="question promowindow">
                <div class="icon"></div>
                <div class="text"> Укажите действующий почтовый ящик, на него поступит письмо с кодом активации. Код
                  активации состоит из 15 символов вида: AAAAA-BBBBB-CCCCC
                </div>
              </div>
            </div>
            <!-- <ErrorInfo :message="this.emailInput.error" /> -->
          </div>
          <ErrorInfo :message="paymentError" />
          <div>
          </div>
          <div>
            <steam-cards-filter ref="giftCards" @card-selected="handleCardSelect"
              @country-selected="handleCountrySelect" />
          </div>
          <div class="checkbox" @click="toggleAgree">
            <input v-model="this.aggree" type="checkbox" name="checkbox" />
            <span>
              Я подтверждаю, что указал верный email, и согласен с <a :href="getUrl('offer')" target="_blank">публичной
                офертой</a>, <a :href="getUrl('privacy')" target="_blank">политикой конфиденциальности</a>, <a
                role="button" class="return pointers" tabindex="0" rel="nofollow"
                @click="openModal('return-rule')">правилами
                возврата</a>
            </span>
          </div>
          <button class="order" :class="{ disabled: !isButtonEnabled || isCardButtonDisabled }"
            :disabled="!isButtonEnabled || isLoading || isCardButtonDisabled" @click="createOrder">
            <template v-if="isSettingsLoading">
              <LoadingIndicator />
            </template>
            <template v-else-if="isCardButtonDisabled">
              Платежи временно отключены. Попробуйте позже.
            </template>
            <template v-else>
              {{ isLoading ? 'Обработка...' : `Перейти к оплате (${formatLabel(paymentData.total)})` }}
            </template>
          </button>
        </div>
        <div v-if="tabActive == 3" class="tab-left">
          <div class="label">
            <label>Email</label>
            <div class="input">
              <input type="email" v-model="emailInput.value" @input="handleEmailInput" @blur="handleEmailBlur"
                placeholder="Введите свой Email" />
              <div class="question promowindow">
                <div class="icon"></div>
                <div class="text">Укажите действующий почтовый ящик, на него поступит код активации.</div>
              </div>
            </div>
          </div>
          <ErrorInfo :message="paymentError" />
          <div>
            <pubg-cards-filter ref="pubgCards" @card-selected="handleCardSelect" />
          </div>
          <div class="checkbox" @click="toggleAgree">
            <input v-model="this.aggree" type="checkbox" name="checkbox" />
            <span>
              Я подтверждаю, что указал верный email, и согласен с <a :href="getUrl('offer')" target="_blank">публичной
                офертой</a>, <a :href="getUrl('privacy')" target="_blank">политикой конфиденциальности</a>, <a
                role="button" class="return pointers" tabindex="0" rel="nofollow"
                @click="openModal('return-rule')">правилами
                возврата</a>
            </span>
          </div>
          <button class="order" :class="{ disabled: !isButtonEnabled || isPubgButtonDisabled }"
            :disabled="!isButtonEnabled || isLoading || isPubgButtonDisabled" @click="createOrder">
            <template v-if="isSettingsLoading">
              <LoadingIndicator />
            </template>
            <template v-else-if="isPubgButtonDisabled">
              Платеж временно отключен. Попробуйте позже.
            </template>
            <template v-else>
              {{ isLoading ? 'Обработка...' : `Перейти к оплате (${formatLabel(paymentData.total)})` }}
            </template>
          </button>
        </div>
        <div class="tab-right">
          <div class="pay-name">Выберите способ оплаты:</div>
          <div class="select-pay">
            <label>
              <input type="radio" name="radio2" value="sbp" v-model="selectedPaymentType"
                @change="handlePaymentMethodChange" />
              <div class="block">
                <img src="/new/images/sbp1.svg" />
                <img src="/new/images/sbp.svg" />
                <span class="discount">-1%</span>
              </div>
            </label>

            <label>
              <input type="radio" name="radio2" value="pay" v-model="selectedPaymentType"
                @change="handlePaymentMethodChange" />
              <div class="block">
                <img src="/new/images/pay.svg" />
                <img src="/new/images/pay.svg" />
              </div>
            </label>
            <label>
              <input type="radio" name="radio2" value="visa" v-model="selectedPaymentType"
                @change="handlePaymentMethodChange" />
              <div class="block">
                <img src="/new/images/visa.svg" />
                <img src="/new/images/visa2.svg" />
              </div>
            </label>

            <label>
              <input type="hidden" name="client-id-input" />

              <input type="radio" name="radio2" value="spay" v-model="selectedPaymentType"
                @change="handlePaymentMethodChange" />
              <div class="block">
                <img src="/new/images/spay.svg" />
                <img src="/new/images/spay.svg" />
              </div>
            </label>
          </div>
          <div class="info-total">
            <div class="line" v-if="tabActive !== 2 && tabActive !== 3">
              <div class="name">Сумма зачисления в STEAM</div>
              <div class="sum">
                <span v-if="isCalculating">
                  <LoadingIndicator />
                </span>
                <span v-else>{{ formatLabel(amountInput.value || 0) }}</span>
              </div>
            </div>
            <div class="line" v-if="paymentData.discountPercentByAmount && tabActive !== 2">
              <div class="name">Скидка за сумму пополнения</div>
              <div class="sum sale">
                <span v-if="isCalculating">
                  <LoadingIndicator />
                </span>
                <span v-else>{{ paymentData.discountPercentByAmount }} %</span>
              </div>
            </div>
            <div class="line" v-if="paymentData.discountPercentByPayMethod">
              <div class="name">Скидка за способ оплаты</div>
              <div class="sum sale">
                <span v-if="isCalculating">
                  <LoadingIndicator />
                </span>
                <span v-else>{{ paymentData.discountPercentByPayMethod }} %</span>
              </div>
            </div>
            <div class="line" v-if="paymentData.discountPercentByPromo && tabActive !== 2">
              <div class="name">Скидка по промокоду</div>
              <div class="sum sale">
                <span v-if="isCalculating">
                  <LoadingIndicator />
                </span>
                <span v-else>{{ paymentData.discountPercentByPromo }} %</span>
              </div>
            </div>

            <div class="line">
              <div class="name">Итоговая сумма к оплате
                <div class="question promowindow">
                  <div class="icon"></div>
                  <div class="text">В комиссии учтены издержки на осуществление платежа, банковские комиссии,
                    разницы
                    на
                    конвертации валют, комиссии провайдеров услуг</div>
                </div>

              </div>

              <div class="sum">
                <span v-if="isCalculating">
                  <LoadingIndicator />
                </span>
                <span v-else>{{ (paymentData.total) + ' ₽' }}</span>
              </div>
            </div>


          </div>
          <button class="enter-buy" @click="openLoginModal">
            {{ !isAuthenticated ? 'Войти и получить кэшбек' : 'Кэшбек' }}
            <span>
              <template v-if="isCalculating">
                <LoadingIndicator color="var(--Text-Gold, #c08e0c)" />
              </template>
              <template v-else>
                {{ paymentData.cashback }}
              </template>
            </span>
          </button>
        </div>
        <LoginModal :show="isLoginModalOpen" @close="closeLoginModal" @auth-success="handleAuthSuccess" />

        <UniversalPopup :isVisible="modals['error-login']" title="Инструкция для нового аккаунта"
          @close="modals['error-login'] = false">
          <div class="block-popup">
            <div class="text">
              Если Вы ранее не делали пополнения на аккаунт, чтобы баланс аккаунта оставался в валюте Российский
              рубль
              и
              регион аккаунта РФ, Вам следует: <br> <br>

              1) Отключить любые программы, которые могут маскировать ваше местоположение <br>
              2) Добавить в свою игровую библиотеку STEAM бесплатные игры, мы рекомендуем: <a
                href="https://store.steampowered.com/app/730/CounterStrike_2" target="_blank">CS2</a>, <a
                href="https://store.steampowered.com/app/578080/PUBG_BATTLEGROUNDS/" target="_blank">PUBG</a>,
              <a href="https://store.steampowered.com/app/570/Dota_2/" target="_blank">Dota 2</a>
              Зачастую хватает только добавить игры в библиотеку, но мы рекомендуем не просто добавить, а скачать
              одну
              из
              предложенных игр на ПК <br>
              3) Подождать 10 минут и сделать пополнение баланса аккаунта на нашем сайте <br> <br>

              Таким образом средства на баланс аккаунта поступят в рублях. Это работает при учете того, что ваш
              текущий
              регион местонахождения Россия и не используется VPN других стран
            </div>
          </div>
        </UniversalPopup>

        <UniversalPopup :isVisible="modals['return-rule']" title="Правила возврата" @close="closeModal('return-rule')">
          <div class="block-popup">
            <div class="text">
              Если вы указали неверный логин своего аккаунта и совершили оплату, мы сможем оформить возврат средств
              только при условии, что такого логина не зарегистрировано в STEAM.<br><br>

              Если вы указали логин, который существует в STEAM, оформить возврат средств является невозможным, так
              как зачисление средств произойдет на существующий аккаунт.<br><br>

              Будьте внимательны при заполнении поля "логин". Как узнать свой логин, мы подробно написали в нашем
              FAQ<br><br>

              Более подробные правила возврата вы можете прочитать в
              <a href="socialLinks.offer" class="return-class" target="_blank">Публичной оферте</a>
            </div>
          </div>
        </UniversalPopup>

        <SbpQrPopup :show="sbpPayment.show" :qr="paymentData.qr" :qr-link="paymentData.qrLink"
          :amount="this.paymentData.total" :email="tabActive === 2 ? emailInput.value : null"
          :login="tabActive === 2 ? null : loginInput.value" :card-price="selectedCardData?.cardPrice"
          :card-currency="selectedCardData?.currency" @close="handleSbpModalClose" />

        <GiftAccesModal :show="giftModal.show" :orderId="giftModal.orderId" @close="handleGiftModalClose" />
        <div v-if="successModal.show">
          {{ successModal.orderId }}
        </div>
        <PaymentSuccessModal :show="successModal.show" :orderId="successModal.orderId"
          @close="handleSuccessModalClose" />
        <div class="mobile-bottom">
          <div class="checkbox" @click="toggleAgree">
            <input v-model="aggree" type="checkbox" name="checkbox" />
            <span>
              Я подтверждаю, что указал верный логин, и согласен с
              <a :href="getUrl('offer')" target="_blank">публичной офертой</a>,
              <a :href="getUrl('privacy')" target="_blank">политикой конфиденциальности</a>,
              <a role="button" class="return pointers" tabindex="0" rel="nofollow"
                @click.stop="openModal('return-rule')">
                правилами возврата
              </a>
            </span>
          </div>
          <button class="order" :class="{
            disabled: !isButtonEnabled ||
              (tabActive === 0 && isBalanceButtonDisabled) ||
              (tabActive === 1 && isCardButtonDisabledKz) ||
              (tabActive === 2 && isCardButtonDisabled)
          }" :disabled="!isButtonEnabled || isLoading ||
            (tabActive === 0 && isBalanceButtonDisabled) ||
            (tabActive === 1 && isCardButtonDisabledKz) ||
            (tabActive === 2 && isCardButtonDisabled)" @click="createOrder">
            <template v-if="isSettingsLoading">
              <LoadingIndicator />
            </template>
            <template v-else-if="
              (tabActive === 0 && isBalanceButtonDisabled) ||
              (tabActive === 1 && isCardButtonDisabledKz) ||
              (tabActive === 2 && isCardButtonDisabled)
            ">
              Платежи временно отключены. Попробуйте позже.
            </template>
            <template v-else>
              {{ isLoading ? 'Обработка...' : `Перейти к оплате (${paymentData.total} ₽)`
              }}
            </template>
          </button>
        </div>
      </div>
    </div>
  </div>

</template>
<script>
import ErrorInfo from '@/components/PaymentForm/Input/InputError.vue';
import Popup from '@/components/Popup.vue';
import DiscountProgress from './DIscountProgress/DiscountProgress.vue';
import SbpQrPopup from './sbp/SBPComponent.vue'
import SteamCardsFilter from './SteamgiftCards/SteamgiftCards.vue';
import PaymentSuccessModal from './PaymentModal/PaymentSteamAccountAcces.vue'
import LoadingIndicator from '@/components/LoadingIndicator/Loadingindicator.vue';
import CheckMarkIcon from '@/components/GoodCheckIndicator/ValidIndicator.vue';
import GiftAccesModal from './PaymentModal/GiftAccesModal.vue';
import UniversalPopup from '@/components/Popup/PopupDefault.vue';
import metrikaService from '@/service/metrica/metrica'
import settingsService from '@/service/api/service';
import LoginModal from "@/auth/Components/AuthPopup.vue";
import { useAuthStore } from '@/store/auth';
import { siteConfig } from '@/config/site-config';
import PubgCardsFilter from './Components/PubgCardsFilter.vue';
import { refCodeStore } from '@/store/referralCodeStore';



export default {
  components: {
    Popup,
    ErrorInfo,
    DiscountProgress,
    SbpQrPopup,
    SteamCardsFilter,
    PaymentSuccessModal,
    LoadingIndicator,
    CheckMarkIcon,
    GiftAccesModal,
    UniversalPopup,
    LoginModal,
    PubgCardsFilter,
  },

  data() {
    return {
      isPubgButtonDisabled: false,
      isGG: true,
      isLoginModalOpen: false,
      settings: null,
      utmString: null,
      isCheckingUtmPromo: false,
      isSettingsLoading: true,
      modals: {
        'promo-kek': false,
        'return-rule': false,
        'error-login': false,
      },
      isCalculating: false,
      selectedCardData: null,
      isRefreshing: false,
      giftModal: {
        show: false,
        orderId: null
      },
      savedUserData: {
        login: localStorage.getItem('userLogin') || '',
        email: localStorage.getItem('userEmail') || ''
      },
      loginInputLoading: false,
      emailValidationTimer: null,
      selectedPaymentType: 'sbp',
      successModal: {
        show: false,
        orderId: localStorage.getItem('currentOrderId'),
      },
      isLoading: false,
      saleData: [
        { percent: 0, amount: 100 },
        { percent: 2, amount: 2000 },
        { percent: 3, amount: 6000 },
        { percent: 5, amount: 10000 },
      ],
      tabActive: 0,
      currency: '₽',
      loginInput: {
        jobId: null,
        value: '',
        error: '',
        loading: false,
        status: false,
        touched: false
      },
      amountInput: {
        error: '',
        value: null,
      },
      emailInput: {
        error: '',
        value: null,
        touched: false,
      },
      promoInput: {
        error: '',
        value: null,
        status: false,
        isValidating: false
      },
      paymentMethod: 'sbp',
      paymentData: {
        cashbackPercent: 0,
        cashback: 0,
        discountPercentByAmount: 0,
        discountPercentByPayMethod: 0,
        discountPercentByPromo: 0,
        discountRubByPromo: 0,
        total: 0,
        cardId: null,
        orderId: '',
        payUrl: null,
        qr: null,
        qrLink: null,
        userAmount: null,
        login: null
      },
      sbpPayment: {
        show: false,
        status: 'pending',
        timeLeft: 20000,
        timer: null,
        checkInterval: null
      },
      aggree: false,
      paymentError: null,
      referralCode: null,
    };
  },


  async mounted() {
    const urlParams = new URLSearchParams(window.location.search);
    const currentUrl = new URL(window.location.href);

    // Извлекаем и сохраняем ref параметр отдельно от UTM
    const refParam = urlParams.get('ref');
    if (refParam) {
      this.referralCode = refParam;
      localStorage.setItem('referral_code', this.referralCode);
      // console.log("Сохранен реферальный код:", this.referralCode);

      // Удаляем ref из строки запроса перед сохранением UTM
      urlParams.delete('ref');
      const cleanUtmString = urlParams.toString();
      if (cleanUtmString) {
        this.utmString = `?${cleanUtmString}`;
      } else {
        this.utmString = null;
      }
    } else {
      // Если в URL нет ref, используем сохраненный ранее
      this.referralCode = localStorage.getItem('referral_code');

      // Сохраняем обычные UTM-параметры
      if (currentUrl.search) {
        this.utmString = currentUrl.search;
      }
    }

    // Сохраняем UTM-параметры в localStorage, если они есть
    if (this.utmString) {
      localStorage.setItem('utmParams', this.utmString);
      await this.checkUtmPromo();
    } else if (localStorage.getItem('utmParams')) {
      this.utmString = localStorage.getItem('utmParams');
    }

    const path = this.$route.path;
    if (path === '/cards') {
      this.tabActive = 2;
      this.currency = '₽';
    } else if (path === '/payment-kz') {
      this.tabActive = 1;
      this.currency = '₸';
    }
    else if (path === '/pubg-uc') {
      this.tabActive = 3;
      this.currency = '₽';
    } else {
      this.tabActive = 0;
      this.currency = '₽';
    }

    // Получаем и сохраняем UTM при загрузке
    let queryString = window.location.search;
    if (queryString.startsWith('??')) {
      queryString = queryString.substring(1);
    }
    if (window.location.search) {
      this.checkUtmPromo();
    }
    if (queryString) {
      localStorage.setItem('utmParams', queryString);
      this.utmString = queryString;
      await this.checkUtmPromo();
    }

    const billId = this.$route.query.billId;

    // Если есть billId в URL, сначала проверяем детали заказа
    if (billId) {
      // Проверяем статус заказа и показываем соответствующую модалку
      this.checkOrderStatus(billId);
      // Получаем детали заказа для определения типа
      this.getOrderDetails(billId);
    }

    // Устанавливаем tabActive в зависимости от текущего пути
    this.tabActive = this.$route.path === '/cards' ? 2 : (this.$route.path === '/kazakhstan' ? 1 : 0);

    // Обновленная инициализация jQuery для табов
    $('.section-main .container .tabs__caption li').click(function () {
      var sel = $(this).html();
      $('.select-mobile-country .select').html(sel);
      $('.section-main .container .tabs__caption').removeClass('active');
    });

    // Добавим инициализацию активного таба при загрузке
    this.$nextTick(() => {
      const path = this.$route.path;
      let activeTabIndex = 0;

      if (path === '/cards') {
        activeTabIndex = 2;
      } else if (path === '/kazakhstan') {
        activeTabIndex = 1;
      }

      // Установка активного таба в мобильном селекте
      const activeTabHtml = $(`.tabs__caption li:eq(${activeTabIndex})`).html();
      if (activeTabHtml) {
        $('.select-mobile-country .select').html(activeTabHtml);
      }

      // Добавление класса active нужному табу
      $(`.tabs__caption li`).removeClass('active');
      $(`.tabs__caption li:eq(${activeTabIndex})`).addClass('active');
    });

    this.$nextTick(() => {
      const path = this.$route.path;
      let activeTabIndex = 0;

      if (path === '/cards') {
        activeTabIndex = 2;
      } else if (path === '/kazakhstan') {
        activeTabIndex = 1;
      } else if (path === '/pubg-uc') {
        activeTabIndex = 3;
      }

      const $select = $('.select-mobile-country .select');
      const $tabs = $('.tabs__caption li');

      if ($select.length && $tabs.length) {
        const $activeTab = $tabs.eq(activeTabIndex);
        if ($activeTab.length) {
          $select.html($activeTab.html());
          $tabs.removeClass('active');
          $activeTab.addClass('active');
        }
      }
    });

    // Проверяем наличие незавершенного платежа
    const pendingOrderId = localStorage.getItem('pendingOrderId');
    const paymentMethod = localStorage.getItem('paymentMethod');
    const savedTabActive = localStorage.getItem('tabActive');

    if (pendingOrderId && paymentMethod === 'card') {
      // Восстанавливаем данные
      this.paymentData.orderId = pendingOrderId;

      // Восстанавливаем тип операции и обновляем маршрут
      if (savedTabActive) {
        this.tabActive = parseInt(savedTabActive);
        // Важно: обновляем путь в соответствии с типом операции
        const path = this.tabActive === 2 ? '/cards' : '/';
        this.$router.push(path);
      }

      // Запускаем проверку статуса
      this.checkCardPayment();

      // Очищаем данные из localStorage только после проверки
      localStorage.removeItem('pendingOrderId');
      localStorage.removeItem('paymentMethod');
      localStorage.removeItem('tabActive');
    } else {
      // Если нет незавершенного платежа, проверяем маршрут
      const path = this.$route.path;
      this.tabActive = path === '/cards' ? 2 : 0;
    }

    // Восстанавливаем сохраненные данные пользователя
    if (this.savedUserData.login) {
      this.loginInput.value = this.savedUserData.login;
      this.checkLogin();
    }
    if (this.savedUserData.email) {
      this.emailInput.value = this.savedUserData.email;
    }

    // Добавляем viewport meta для iOS
    if (/iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream) {
      const viewportMeta = document.createElement('meta');
      viewportMeta.name = 'viewport';
      viewportMeta.content = 'width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no';
      document.head.appendChild(viewportMeta);

      const style = document.createElement('style');
      style.innerHTML = `
      @media screen and (-webkit-min-device-pixel-ratio:0) { 
        select,
        textarea,
        input {
        } 
      }
    `;
      document.head.appendChild(style);
    }
  },


  setup() {
    const authStore = useAuthStore();
    const referralStore = refCodeStore();
    return { authStore, referralStore };
    

  },
  

  computed: {
    isPubgButtonDisabled() {
      if (this.isSettingsLoading) return true;
      return this.settings?.pubg_dis ?? false;
    },
    socialBotLinks() {
      return siteConfig.socialBotLinks;
    },
    socialLinks() {
      return siteConfig.socialLinks;
    },
    isAuthenticated() {
      return this.authStore.isAuthenticated;
    },
    amountButtons() {
      const currType = this.tabActive === 1 ? 'kzt' : 'rub';
      const buttons = this.settings?.amountButtons?.[currType];
      return buttons;
    },

    currencySymbol() {
      return this.tabActive === 1 ? '₸' : '₽';
    },
    minAmount() {
      return this.settings?.amountValidation?.rub?.min;
    },

    showError() {
      // Показываем красную обводку только если это реальная ошибка, а не first-login-message
      return this.loginInput.error && !this.loginInput.error.includes('first-login-message');
    },

    isBalanceButtonDisabled() {
      if (this.isSettingsLoading) return true;
      return this.settings?.balance_dis ?? false;
    },

    isCardButtonDisabled() {
      if (this.isSettingsLoading) return true;
      return this.settings?.card_dis ?? false;
    },
    isCardButtonDisabledKz() {
      if (this.isSettingsLoading) return true;
      return this.settings?.kzt_dis ?? false;
    },
    isButtonEnabled() {
      // Базовая проверка email
      const isEmailValid = this.emailInput.value &&
        !this.emailInput.error &&
        this.validateEmail();

      if (this.tabActive === 2 || this.tabActive === 3) {
        return this.aggree &&
          isEmailValid &&
          this.selectedCardData &&
          !this.isCalculating;
      } else {
        const isLoginError = this.loginInput.error &&
          !this.loginInput.error.includes('first-login-message');

        return this.aggree &&
          this.loginInput.value &&
          !isLoginError &&
          this.loginInput.status &&
          this.amountInput.value &&
          !this.amountInput.error &&
          isEmailValid &&
          !this.isCalculating;
      }
    },
    serviceFee() {
      if (!this.paymentData.total) return 0;
      if (this.tabActive === 2 && this.selectedCardData) {
        return this.paymentData.total - this.selectedCardData.price;
      }
      return this.paymentData.total - (this.amountInput.value || 0);
    },
    buttonText() {
      if (this.isLoading) return 'Обработка...';

      if (this.tabActive === 2) {
        if (!this.selectedCardData) return 'Выберите карту';
        return `Перейти к оплате (${this.formatLabel(this.paymentData.total)})`;
      }

      if (!this.amountInput.value) return 'Перейти к оплате';
      return `Перейти к оплате (${this.formatLabel(this.paymentData.total)})`;
    },

    isFormValid() {
      if (this.tabActive === 2) {
        return this.validateEmail() && this.selectedCardData && this.aggree;
      }
      return (
        this.loginInput.status &&
        (!this.emailInput.touched || this.validateEmail()) &&
        this.validateAmount() &&
        this.aggree
      );
    },

    formatTime() {
      const minutes = Math.floor(this.sbpPayment.timeLeft / 60);
      const seconds = this.sbpPayment.timeLeft % 60;
      return `${minutes}:${seconds.toString().padStart(2, '0')}`;
    },

    sbpStatusMessage() {
      const messages = {
        pending: 'Ожидание оплаты...',
        success: 'Оплата успешно завершена!',
        failed: 'Ошибка оплаты. Попробуйте снова.',
        timeout: 'Время ожидания истекло'
      };
      return messages[this.sbpPayment.status] || 'Ожидание оплаты...';
    }
  },



  async created() {
    this.isSettingsLoading = true;
    try {
      await settingsService.fetch();
      this.settings = settingsService.get();
      // console.log('Settings loaded in created:', this.settings);
    } catch (error) {
      // console.error('Failed to load settings:', error);
      this.settings = null;
    } finally {
      this.isSettingsLoading = false;
    }
  },

  methods: {

    getUrl(type) {
      const urls = {
        terms: this.isGG ? import.meta.env.VITE_GG_TERMS_URL : import.meta.env.VITE_TERMS_URL,
        offer: this.isGG ? import.meta.env.VITE_GG_OFFER_URL : import.meta.env.VITE_OFFER_URL,
        privacy: this.isGG ? import.meta.env.VITE_GG_PRIVACY_URL : import.meta.env.VITE_PRIVACY_URL
      };

      return urls[type] || 'https://steam.money/terms.pdf?t=1';
    },

    toggleAgree(event) {
      // Исключаем клик по ссылкам из триггера чекбокса
      if (!event.target.closest('a')) {
        this.aggree = !this.aggree;

        // Если пытаемся включить чекбокс, проверяем все поля
        if (this.aggree) {
          // Помечаем поля как "тронутые" для показа ошибок
          this.emailInput.touched = true;
          this.loginInput.touched = true;

          // Проверяем email
          if (!this.emailInput.value) {
            this.emailInput.error = 'Введите Email';
          } else {
            this.validateEmail();
          }

          // Проверяем логин если это не гифт карты
          if (this.tabActive !== 2) {
            if (!this.loginInput.value) {
              this.loginInput.error = 'Введите логин';
            } else {
              this.validateLogin();
            }

            // Проверяем сумму
            if (!this.amountInput.value) {
              this.amountInput.error = 'Введите сумму';
            } else {
              this.validateAmount();
            }
          }
        }
      }
    },

    async checkUtmPromo() {
      if (this.isCheckingUtmPromo) return;
      this.isCheckingUtmPromo = true;

      try {
        if (!this.utmString) return;

        const { data } = await this.axios.get('/promo/check', {
          params: { utm: this.utmString }
        });

        // console.log("Ответ проверки UTM-промокода:", data);

        if (data.status && data.code) {
          this.promoInput.value = data.code;
          this.promoInput.status = true;
          this.promoInput.isFromUtm = true;

          // console.log("Применен промокод из UTM:", data.code);

          if (!this.amountInput.value) {
            this.promoInput.error = 'Введите сумму для активации промокода';
          }
        }
      } catch (error) {
        console.error('UTM promo check error:', error);
      } finally {
        this.isCheckingUtmPromo = false;
      }
    },

    openLoginModal() {
      if (!this.isLoading && !this.authStore.isAuthenticated) {
        // console.log('Opening login modal');
        this.isLoginModalOpen = true;
      }
    },

    closeLoginModal() {
      // console.log('Closing login modal');
      this.isLoginModalOpen = false;
    },
    async handleAuthSuccess() {
      this.isLoginModalOpen = false;
      // console.log('Auth success', this.isLoginModalOpen);
      await this.$nextTick();
      this.isMenuOpen = false;
    },
    // Также об
    // новляем getUtmParams
    getUtmParams() {
      const queryString = window.location.search;

      if (queryString && queryString.includes('utm_source')) {
        localStorage.setItem('utmParams', queryString);
        this.utmString = queryString;
        this.checkUtmPromo();
      }
    },


    // Дополнительный метод для установки значения промокода
    setPromoCode(code) {
      this.promoInput.value = code;
      if (this.$refs.promoInput) {
        this.$refs.promoInput.value = code;
        this.$refs.promoInput.dispatchEvent(new Event('input'));
      }
      this.calculate();
    },


    async loadSettings() {
      this.isSettingsLoading = true;
      try {
        await settingsService.fetch();
        this.settings = settingsService.get();
        // console.log('Settings loaded:', this.settings);
      } catch (error) {
        // console.error('Failed to load settings:', error);
        this.settings = null;
      } finally {
        this.isSettingsLoading = false;
      }
    },
    // ... rest of your methods
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    },
    openModal(modalId) {
      this.modals[modalId] = true;
    },
    closeModal(modalId) {
      this.modals[modalId] = false;
    },
    async checkOrderStatus(billId) {
      try {
        const orderDetails = await this.getOrderDetails(billId);
        // console.log('Order details:', orderDetails?.data);

        if (!orderDetails?.data) {
          return;
        }

        // Определяем тип заказа (баланс или гифт карта)
        const savedTabActive = localStorage.getItem('tabActive');
        if (savedTabActive) {
          this.tabActive = parseInt(savedTabActive);
        } else {
          this.tabActive = 0;
        }

        const path = this.tabActive === 2 ? '/cards' : (this.tabActive === 3 ? '/pubg-uc' : '/');
        this.$router.push(path);

        if (orderDetails.data.status === true) {
          metrikaService.sendSuccessPayment({
            orderId: billId,
            total: orderDetails.data.amount,
            paymentMethod: orderDetails.data.paymentMethod || 'unknown',
            isGiftCard: this.tabActive === 2,
            utm: this.utmString || null,
            clientId: await metrikaService.getClientId(),
            login: orderDetails.data.login,
            email: this.emailInput.value,
            promoCode: this.promoInput.value || null,
            cardId: (this.tabActive === 2 || this.tabActive === 3) ? this.selectedCardData?.id : null,
            amount: orderDetails.data.userAmount
          });

          this.sbpPayment.show = false;
          this.successModal.show = false;
          this.giftModal.show = false;

          if (this.tabActive === 2 || this.tabActive === 3) {
            this.giftModal = {
              show: true,
              orderId: billId
            };
          } else {
            // Изменено: теперь мы используем billId
            this.successModal = {
              show: true,
              orderId: billId  // Используем billId напрямую
            };
          }

          window.history.replaceState({}, '', window.location.pathname);
          localStorage.removeItem('tabActive');
          localStorage.removeItem('pendingOrderId');
          localStorage.removeItem('paymentMethod');
        }
      } catch (error) {
        console.error('Error checking order status:', error);
      }
    },

    async getOrderDetails(billId) {
      try {
        const response = await this.axios.get('/check/order', {
          params: { id: billId }
        });
        return response;
      } catch (error) {
        // console.error('Error fetching order details:', error);
        return null;
      }
    },
    handleTabChange(path) {
      // Update router
      const currentQuery = new URLSearchParams(window.location.search);

      this.$router.push({
        path: path,
        query: Object.fromEntries(currentQuery)
      });


      // Set tab and currency
      if (path === '/cards') {
        this.tabActive = 2;
        this.currency = '₽';
      } else if (path === '/kazakhstan') {
        this.tabActive = 1;
        this.currency = '₸';
      }
      else if (path === '/pubg-uc') {
        this.tabActive = 3;
        this.currency = '₽';
      } else {
        this.tabActive = 0;
        this.currency = '₽';
      }

      // Reset payment data
      this.paymentData = {
        cashbackPercent: 0,
        cashback: 0,
        discountPercentByAmount: 0,
        discountPercentByPayMethod: 0,
        discountPercentByPromo: 0,
        discountRubByPromo: 0,
        total: 0,
        cardId: null,
        orderId: null,
        payUrl: null,
        qr: null,
        qrLink: null,
        userAmount: null,
        login: null
      };

      // Clear amount but preserve promo if it's from UTM
      if (this.amountInput.value) {
        this.amountInput.value = '';
        this.amountInput.error = '';
      }

      // Update jQuery mobile select
      const sel = $(`.tabs__caption li:eq(${path === '/cards' ? 1 : (path === '/pubg-uc' ? 2 : 0)})`).html();
      $('.select-mobile-country .select').html(sel);
      $('.section-main .container .tabs__caption').removeClass('active');
    },

    handleAmountChange(event) {
      let value = event.target.value;
      const limits = this.settings?.amountValidation?.[this.tabActive === 1 ? 'kzt' : 'rub'];

      if (!limits) return;

      if (value && value < limits.min) {
        this.amountInput.value = limits.min;
        this.amountInput.error = `Минимальная сумма ${limits.min} ${this.currencySymbol}`;
        setTimeout(() => this.amountInput.error = '', 4000);
      }

      if (value > limits.max) {
        this.amountInput.value = limits.max;
        this.amountInput.error = `Максимальная сумма ${limits.max} ${this.currencySymbol}`;
        setTimeout(() => this.amountInput.error = '', 4000);
      }
      this.calculate();
    },

    handleAmountInput() {
      let value = this.amountInput.value;

      if (!value) {
        this.amountInput.value = '';
        this.amountInput.error = '';
        return;
      }

      if (value && this.promoInput.error === 'Введите сумму для активации промокода') {
        this.promoInput.error = '';
        if (this.promoInput.value) {
          this.calculate();
        }
      }

      const limits = this.settings?.amountValidation?.[this.tabActive === 1 ? 'kzt' : 'rub'];

      if (value > limits?.max) {
        this.amountInput.value = limits.max;
        this.amountInput.error = `Максимальная сумма ${limits.max} ${this.currency}`;
        setTimeout(() => this.amountInput.error = '', 4000);
      } else if (value < limits?.min) {
        this.amountInput.error = `Минимальная сумма ${limits.min} ${this.currency}`;
      } else {
        this.amountInput.error = '';
      }

      this.calculate();
    },

    handleAmountBlur() {
      let value = this.amountInput.value;

      if (value && value < 100) {
        this.amountInput.value = 100;
        value = 100;
        this.amountInput.error = 'Минимальная сумма 100 рублей';
        this.errorTimeoutId = setTimeout(() => {
          this.amountInput.error = '';
        }, 4000);
        this.calculate();
      }
    },

    async setAmount(amount) {
      this.amountInput.value = amount;
      this.amountInput.error = '';

      if (this.promoInput.value) {
        if (this.promoInput.error === 'Введите сумму для активации промокода') {
          this.promoInput.error = '';
        }
        await this.calculate();
      } else {
        await this.calculate();
      }
    },


    handleCountrySelect() {
      // Сброс данных при смене страны
      if (this.selectedCardData) {
        this.selectedCardData = null;
        this.paymentData = {
          ...this.paymentData,
          total: 0,
          cashback: 0,
          cashbackPercent: 0,
          discountPercentByPayMethod: 0,
          cardId: null
        };
      }
    },

    async handleCardSelect(cardData) {
      this.selectedCardData = cardData;
      this.isCalculating = true;

      try {
        const { data } = await this.axios.get('/calc/card?' + new URLSearchParams({
          cardId: cardData.id,
          payMethod: this.paymentMethod,
          email: this.emailInput.value || ''
        }));

        if (!data.status) {
          this.paymentError = data.message || 'Ошибка расчета';
          throw new Error(data.message || 'Ошибка расчета');
        }

        // Успешный ответ - очищаем ошибку
        this.paymentError = null;

        this.paymentData = {
          ...this.paymentData,
          total: data.total,
          cashback: data.cashback,
          cashbackPercent: data.cashbackPercent,
          discountPercentByPayMethod: data.discountPercentByPayMethod
        };

        this.selectedCardData = cardData;

      } catch (error) {
        if (error.response?.status === 422) {
          this.paymentError = '';
          this.emailInput.error = 'Введите корректный email';
        } else {
          this.paymentError = error.response?.data?.message || error.message || 'Ошибка расчета стоимости карты';
        }
        if (window.innerWidth <= 991) {
          this.scrollToTop();
        }
      } finally {
        this.isCalculating = false;
      }
    },


    handlePaymentMethodChange(event) {
      const target = event.target;
      const newValue = target.value;

      // Обновляем selectedPaymentType для UI
      this.selectedPaymentType = newValue;

      this.paymentMethod = newValue === 'sbp' ? 'sbp' : 'card';

      // Вызываем пересчет
      if (this.tabActive === 2 || this.tabActive === 3 && this.selectedCardData) {
        this.isCalculating = true;
        this.handleCardSelect(this.selectedCardData);
      } else {
        this.calculate();
      }
    },

    handleLoginInput() {
      const hasRussianLetters = /[а-яА-ЯёЁ]/.test(this.loginInput.value);

      if (hasRussianLetters) {
        this.loginInput.error = 'Логин не может содержать русские буквы';
        this.loginInput.status = false;
        this.loginInput.value = this.loginInput.value.replace(/[а-яА-ЯёЁ]/g, '');
        return;
      }

      this.loginInput.status = false;

      if (this.loginInput.jobId) {
        clearTimeout(this.loginInput.jobId);
        this.loginInput.jobId = null;
      }

      if (!this.loginInput.value) {
        this.loginInput.loading = false;
        this.loginInput.error = '';
        return;
      }

      this.loginInput.loading = true;
      this.loginInput.error = '';

      this.loginInput.jobId = setTimeout(async () => {
        this.checkLogin();
      }, 500);

      localStorage.setItem('userLogin', this.loginInput.value);
    },

    handleLoginBlur() {
      this.validateLogin();

      if (this.loginInput.value && !this.loginInput.loading && !this.loginInput.status) {
        this.checkLogin();
      }
    },

    validateLogin() {
      if (!this.loginInput.value) {
        this.loginInput.error = 'Введите логин';
        this.loginInput.status = false;
        return false;
      }

      if (this.loginInput.value.length < 2) {
        this.loginInput.error = 'Логин должен быть длиннее 2 символов';
        this.loginInput.status = false;
        return false;
      }

      return true;
    },

    validateAmount() {
      const limits = this.settings?.amountValidation?.[this.currency === '₽' ? 'rub' : 'kzt'];
      if (!limits) return false;

      const value = this.amountInput.value;
      if (!value) return false;
      if (value < limits.min || value > limits.max) return false;
      return true;
    },



    async calculate() {
      if (this.tabActive === 2) return;

      if (!this.validateAmount()) {
        this.isCalculating = false;
        return;
      }

      this.isCalculating = true;

      try {
        const { data } = await this.axios.get('/calc/steam-balance', {
          params: {
            login: this.loginInput.value,
            amount: this.amountInput.value,
            payMethod: this.paymentMethod,
            promoCode: this.promoInput.value || undefined,
            currency: this.tabActive === 1 ? 'kzt' : 'rub'
          }
        });

        if (!data.status) {
          if (data.message && data.message.includes('promo')) {
            // Всегда сбрасываем некорректный промокод и показываем ошибку
            this.promoInput.value = null;
            this.promoInput.status = false;
            this.promoInput.error = data.message || 'Промокод не найден или истек срок действия.';
            this.promoInput.isFromUtm = false; // Сбрасываем флаг UTM
          } else {
            throw new Error(data.message || 'Ошибка расчета');
          }
        }

        // Проверяем результат применения промокода
        if (this.promoInput.value) {
          // Если промокод не дал скидки - сбрасываем его в любом случае
          if (data.discountRubByPromo === 0 || data.discountPercentByPromo === 0) {
            this.promoInput.value = null;
            this.promoInput.status = false;
            this.promoInput.error = 'Промокод не найден или истек срок действия.';
            this.promoInput.isFromUtm = false; // Сбрасываем флаг UTM
          } else {
            this.promoInput.lastValidLogin = this.loginInput.value;
            this.promoInput.status = true;
            this.promoInput.error = '';
          }
        }

        // Обновляем данные платежа только если промокод валиден или его нет
        if (!this.promoInput.value || this.promoInput.status) {
          this.paymentData = {
            ...this.paymentData,
            total: data.total,
            cashback: data.cashback,
            cashbackPercent: data.cashbackPercent,
            discountPercentByAmount: data.discountPercentByAmount,
            discountPercentByPayMethod: data.discountPercentByPayMethod,
            discountPercentByPromo: data.discountPercentByPromo,
            discountRubByPromo: data.discountRubByPromo
          };
        }

      } catch (error) {
        console.error('Calculate error:', error);
        this.paymentError = error.message || 'Ошибка расчета';
        this.promoInput.status = false;
      } finally {
        this.isCalculating = false;
      }
    },
    // Обработчик ввода промокода
    handlePromoInput() {
      if (!this.promoInput.value) {
        this.promoInput.error = '';
        this.promoInput.status = false;
        return;
      }

      if (!this.amountInput.value) {
        this.promoInput.error = 'Введите сумму для активации промокода';
        return;
      }
    },

    handlePromoBlur() {
      if (!this.promoInput.value || !this.amountInput.value) {
        return;
      }

      if (this.promoTimeout) {
        clearTimeout(this.promoTimeout);
      }

      // Проверяем промокод только если он не из UTM
      if (!this.promoInput.isFromUtm) {
        this.calculate();
      }
    },



    validateEmail() {
      // Если поле не тронуто и пустое, считаем что валидация не пройдена
      if (!this.emailInput.touched && !this.emailInput.value) {
        return false;
      }

      // Проверка на пустое поле
      if (!this.emailInput.value || this.emailInput.value.trim() === '') {
        this.emailInput.error = 'Введите Email';
        return false;
      }

      // Проверка формата email
      const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
      if (!emailRegex.test(this.emailInput.value)) {
        this.emailInput.error = 'Введите корректный email';
        return false;
      }

      this.emailInput.error = '';
      return true;
    },

    handleEmailInput() {
      this.emailInput.touched = true;
      this.emailInput.error = '';

      localStorage.setItem('userEmail', this.emailInput.value);

      // Очищаем предыдущий таймер
      if (this.emailValidationTimer) {
        clearTimeout(this.emailValidationTimer);
      }

      // Устанавливаем новый таймер для валидации
      this.emailValidationTimer = setTimeout(() => {
        // Валидируем email если есть символ @ или поле потеряло фокус
        if (this.emailInput.value && this.emailInput.value.includes('@')) {
          this.validateEmail();
        }

        // Для гифт карт дополнительно вызываем пересчет
        if (this.tabActive === 2 && this.selectedCardData) {
          this.handleCardSelect(this.selectedCardData);
        }
      }, 1000);
    },


    handleEmailBlur() {
      this.emailInput.touched = true;

      // При потере фокуса всегда проводим валидацию
      this.validateEmail();

      // Для гифт карт проводим пересчет
      if (this.tabActive === 2 && this.selectedCardData) {
        this.handleCardSelect(this.selectedCardData);
      }
    },



    async createOrder() {
      if (this.isLoading) return;
      if (!this.aggree) return;
      if (!this.validateEmail()) return;
      this.stopAllTimers();
      const refCode = this.referralStore.getCurrentReferralCode();
      this.referralStore.debugCookies();
      // console.log(refCode,'asd')


      if (!this.utmString && localStorage.getItem('utmParams')) {
        this.utmString = localStorage.getItem('utmParams');
      }

      // Проверяем наличие реферального кода
      if (!this.referralCode) {
        // Проверяем localStorage
        this.referralCode = localStorage.getItem('referral_code');
      }

      if (this.tabActive === 3 && this.isPubgButtonDisabled) {
        this.paymentError = 'Платеж временно отключен';
        return;
      }



      this.isLoading = true;
      this.paymentError = null;

      // Проверки для разных вкладок
      if (this.tabActive === 2 || this.tabActive === 3) {
        if (!this.selectedCardData) return;
        if (!this.emailInput.value) {
          this.emailInput.touched = true;
          this.emailInput.error = 'Введите Email';
          return;
        }
        if (!this.validateEmail()) return;
      } else {
        if (!this.loginInput.value) {
          this.loginInput.touched = true;
          this.loginInput.error = 'Введите логин';
          return;
        }
        if (!this.validateLogin()) return;
        if (!this.amountInput.value) {
          this.amountInput.error = 'Введите сумму';
          return;
        }
        if (!this.validateAmount()) return;
        if (!this.emailInput.value) {
          this.emailInput.touched = true;
          this.emailInput.error = 'Введите Email';
          return;
        }
        if (!this.validateEmail()) return;
      }

      this.isLoading = true;
      this.paymentError = null;

      try {
        const orderData = (this.tabActive === 2 || this.tabActive === 3)
          ? {
            utm: this.utmString || null,
            clientId: await this.generateClientId(),
            login: this.emailInput.value,
            cardId: this.selectedCardData.id,
            payMethod: this.paymentMethod,
            agree: true,
            ref_code: refCode,
            type: this.tabActive === 3 ? 'pubg' : 'gift'  

          }
          : {
            utm: this.utmString || null,
            clientId: await this.generateClientId(),
            login: this.loginInput.value,
            amount: this.amountInput.value.toString(),
            email: this.emailInput.value,
            promo: this.promoInput.value || '',
            payMethod: this.paymentMethod,
            currency: this.tabActive === 1 ? 'kzt' : 'rub',
            agree: true,
            ref_code: refCode,

          };
        // console.log(orderData);
        const endpoint = (this.tabActive === 2 || this.tabActive === 3) ? '/card/pay' : '/balance/pay';
        const orderResponse = await this.axios.post(endpoint, orderData);

        if (orderResponse.data.status) {
          localStorage.setItem('currentOrderId', orderResponse.data.orderId);
          // Отправляем в метрику все доступные данные
          // console.log(orderResponse.data.orderId)
          metrikaService.sendSuccessPayment({
            orderId: orderResponse.data.orderId,
            total: orderResponse.data.total,
            paymentMethod: this.paymentMethod,
            isGiftCard: this.tabActive === 2,
            utm: this.utmString || null,
            clientId: await this.generateClientId(),
            login: this.tabActive === 2 ? this.emailInput.value : this.loginInput.value,
            email: this.emailInput.value,
            promoCode: this.promoInput.value || null,
            cardId: this.tabActive === 2 ? this.selectedCardData.id : null,
            amount: this.amountInput.value || null
          });
        }
        // console.log(orderResponse.data.orderId);
        const metricsData = {
          orderId: orderResponse.data.orderId,
          total: orderResponse.data.total,
          paymentMethod: this.paymentMethod,
          isGiftCard: this.tabActive === 2,
          utm: this.utmString || null,
          clientId: await this.generateClientId(),
          login: this.tabActive === 2 ? this.emailInput.value : this.loginInput.value,
          email: this.emailInput.value,
          promoCode: this.promoInput.value || null,
          cardId: this.tabActive === 2 ? this.selectedCardData.id : null,
          amount: this.amountInput.value || null
        };
        // console.log('Sending metrics for successful order1:', metricsData);
        metrikaService.sendSuccessPayment(metricsData);

        if (!orderResponse.data.status) {
          this.paymentError = orderResponse.data.error;
          return;
          // throw new Error(orderResponse.data.message || 'Ошибка создания заказа');
        }

        // Очищаем UTM после успешного создания заказа
        localStorage.removeItem('utmParams');

        this.paymentData = {
          ...this.paymentData,
          orderId: orderResponse.data.orderId,
          payUrl: orderResponse.data.payUrl,
          qr: orderResponse.data.qr,
          qrLink: orderResponse.data.qrLink,
          total: orderResponse.data.total
        };
        this.successModal.orderId = orderResponse.data.orderId;

        // console.log('Updated payment data:', this.paymentData);

        if (this.paymentMethod === 'sbp') {
          this.sbpPayment.show = true;
          this.startPaymentStatusCheck();
        } else if (this.paymentData.payUrl) {
          localStorage.setItem('pendingOrderId', this.paymentData.orderId);
          localStorage.setItem('paymentMethod', this.paymentMethod);
          localStorage.setItem('tabActive', this.tabActive.toString());
          window.location.href = this.paymentData.payUrl;
        }

      } catch (error) {
        // console.error('Payment error:', error);
        this.paymentError = error.response?.data?.error || null;

        if (this.tabActive === 2 && error?.response?.status === 500) {
          this.$refs.giftCards.isRefreshing = true;
          this.aggree = false;
          try {
            await this.$refs.giftCards.fetchCards();
          } catch (refreshError) {
            // console.error('Error refreshing cards:', refreshError);
          } finally {
            this.$refs.giftCards.isRefreshing = false;
          }
        }

        this.paymentError = error.response?.data?.error || error.message || 'Произошла ошибка при оплате';
      } finally {
        this.isLoading = false;
      }


    },
    getUrlParams() {
      const urlParams = new URLSearchParams(window.location.search);

      // Обработка реферального кода
      const refCode = urlParams.get('ref');
      if (refCode) {
        this.referralCode = refCode;
        localStorage.setItem('referral_code', refCode);
        console.log("Получен реферальный код из URL:", refCode);

        // Удаляем ref из параметров для UTM
        urlParams.delete('ref');
      }

      // Обработка UTM параметров
      const hasUtm = Array.from(urlParams.keys()).some(key => key.startsWith('utm_'));
      if (hasUtm) {
        const cleanUtmString = urlParams.toString();
        if (cleanUtmString) {
          this.utmString = `?${cleanUtmString}`;
          localStorage.setItem('utmParams', this.utmString);
        }
      }

      return {
        refCode: this.referralCode,
        utmString: this.utmString
      };
    },
    async checkCardPayment() {
      try {
        const { data } = await this.axios.get('/check/order', {
          params: { id: this.paymentData.orderId }
        });

        // console.log('Card payment status response:', data);

        if (data.status === true) {
          if (this.tabActive === 2) {
            this.giftModal = {   // Предполагая что у вас есть такой объект в data
              show: true,
              orderId: this.paymentData.orderId
            }
          } else {  // Если это обычное пополнение
            this.successModal = {
              show: true,
              orderId: this.paymentData.orderId
            };
          }
        } else (error) => {
          this.paymentError = error.message || 'Оплата не была подтверждена';
        }
      } catch (error) {
        // console.error('Card payment status check error:', error);
        this.paymentError = error.message || 'Ошибка проверки статуса оплаты';
      }
    },

    handleGiftModalClose() {
      this.giftModal.show = false;
      this.stopAllTimers();
      // Очищаем URL
      if (window.location.search) {
        window.history.replaceState({}, '', window.location.pathname);
      }
    },


    showSbpPaymentModal() {
      // Сбрасываем состояние СБП оплаты
      this.sbpPayment = {
        ...this.sbpPayment,
        show: true,
        status: 'pending',
        timeLeft: 300,
        timer: null,
        checkInterval: null
      };

      this.startSbpTimer();
      this.$events.emit('openSbpQr');
    },

    // Метод запуска таймера
    startSbpTimer() {
      // console.log('Starting SBP timer');
      if (this.sbpPayment.timer) {
        clearInterval(this.sbpPayment.timer);
      }

      this.sbpPayment.timer = setInterval(() => {
        if (this.sbpPayment.timeLeft > 0) {
          this.sbpPayment.timeLeft--;
        } else {
          this.handlePaymentTimeout();
        }
      }, 1000);
    },

    ///Проверка статуса платежа
    startPaymentStatusCheck() {
      if (!this.paymentData.orderId) return;
      // console.log('Starting payment check with orderId:', this.paymentData.orderId);

      const checkPaymentStatus = async () => {
        try {
          const { data } = await this.axios.get('/check/order', {
            params: { id: this.paymentData.orderId }
          });

          // console.log('Payment status response:', data);

          if (data.status === true) {
            // Останавливаем интервал проверки
            clearInterval(this.sbpPayment.checkInterval);

            // Закрываем СБП модалку если она открыта
            if (this.sbpPayment.show) {
              this.sbpPayment.show = false;
            }

            // Сохраняем orderId в localStorage
            localStorage.setItem('currentOrderId', this.paymentData.orderId);

            // Устанавливаем модальное окно успешной оплаты
            this.successModal = {
              show: true,
              orderId: this.paymentData.orderId
            };

            // Останавливаем любые активные таймеры
            this.stopAllTimers();
          }
        } catch (error) {
          console.error('Payment status check error:', error);
        }
      };

      // Запускаем интервал проверки
      this.sbpPayment.checkInterval = setInterval(checkPaymentStatus, 3000);

      // Делаем первую проверку сразу
      checkPaymentStatus();
    },

    handleSuccessModalClose() {
      const currentOrderId = this.successModal.orderId;

      this.successModal = {
        show: false,
        orderId: currentOrderId
      };

      // Очищаем URL
      if (window.location.search) {
        window.history.replaceState({}, '', window.location.pathname);
      }
    },


    handlePaymentComplete(status) {
      if (status === 'success') {
        // Закрываем СБП модалку
        this.sbpPayment.show = false;

        // Формируем данные для метрики
        const metricsData = {
          orderId: this.paymentData.orderId,
          total: this.paymentData.total,
          paymentMethod: this.paymentMethod,
          isGiftCard: this.tabActive === 2,
          utm: this.utmString || 'string',
          clientId: metrikaService.getClientId(),
          login: this.tabActive === 2 ? this.emailInput.value : this.loginInput.value,
          email: this.emailInput.value,
          promoCode: this.promoInput.value || null,
          cardId: this.tabActive === 2 ? this.selectedCardData?.id : null,
          amount: this.amountInput.value || null
        };

        // Отправляем цель в метрику
        // console.log('💰PaymentForm: Sending success payment metrics:', metricsData);
        metrikaService.sendSuccessPayment(metricsData);

        // Показываем соответствующую модалку
        if (this.tabActive === 2) {
          this.giftModal = {
            show: true,
            orderId: this.paymentData.orderId
          };
        } else {
          this.successModal = {
            show: true,
            orderId: this.paymentData.orderId
          };
        }
      }
    },


    handlePaymentTimeout(error) {
      this.stopAllTimers();
      this.sbpPayment.status = 'timeout';
      this.paymentError = error.message || 'Время ожидания оплаты истекло';
    },

    handlePaymentError(error) {
      this.paymentError = error.message || 'Произошла ошибка при оплате';
      this.sbpPayment.status = 'failed';

      if (window.innerWidth <= 991) {
        this.scrollToTop();
      }

    },


    // Обработчик закрытия модального окна
    handleSbpModalClose() {
      this.stopAllTimers();
      this.sbpPayment.show = false;
    },
    closeSbpPayment() {
      this.stopAllTimers();
    },


    stopAllTimers() {
      if (this.sbpPayment.timer) {
        clearInterval(this.sbpPayment.timer);
        this.sbpPayment.timer = null;
      }
      if (this.sbpPayment.checkInterval) {
        clearInterval(this.sbpPayment.checkInterval);
        this.sbpPayment.checkInterval = null;
      }

      this.sbpPayment = {
        show: false,
        status: 'pending',
        timeLeft: 20000,
        timer: null,
        checkInterval: null
      };

    },


    selectedCard(newValue) {
      if (!newValue) return;

      const selectedCardData = this.cards.find(card => card.id === newValue);
      if (selectedCardData) {
        this.$emit('card-selected', {
          id: selectedCardData.id,
          price: selectedCardData.price,
          cardPrice: selectedCardData.cardPrice,
          currency: selectedCardData.currency,
          country: selectedCardData.country
        });
      }
    },


    selectTab(tab) {
      this.tabActive = tab;
      this.currency = '₽';

      // Сброс состояния промокода
      this.promoInput = {
        error: '',
        value: null,
      };

      // Сброс данных платежа
      this.paymentData = {
        cashbackPercent: 0,
        cashback: 0,
        discountPercentByAmount: 0,
        discountPercentByPayMethod: 0,
        discountPercentByPromo: 0,
        discountRubByPromo: 0,
        total: 0,
        cardId: null,
        orderId: null,
        payUrl: null,
        qr: null,
        qrLink: null,
        userAmount: null,
        login: null
      };

      // Сброс суммы при переходе на вкладку гифт карт
      if (tab === 2) {
        this.amountInput = {
          error: '',
          value: null,
        };
      }

      // Сброс выбранной карты при переходе на вкладку пополнения баланса
      if (tab === 0) {
        this.selectedCardData = null;
      }
    },

    async checkLogin() {
      if (!this.validateLogin()) {
        this.loginInputLoading = false;
        this.loginInput.status = false;
        return;
      }

      if (this.loginInput.jobId) {
        clearTimeout(this.loginInput.jobId);
      }

      this.loginInputLoading = true;
      this.loginInput.error = '';
      this.loginInput.status = false;

      try {
        const { data } = await this.axios.get('/check?login=' + this.loginInput.value);

        if (this.loginInput.value) {
          if (data.status === true) {
            this.loginInput.status = true;
            this.loginInput.error = '';

            // Проверяем промокод
            if (this.promoInput.value) {
              if (!this.amountInput.value) {
                this.promoInput.error = 'Введите сумму для активации промокода';
              } else {
                // Делаем единую проверку промокода
                try {
                  const promoResponse = await this.axios.get('/calc/steam-balance', {
                    params: {
                      login: this.loginInput.value,
                      amount: this.amountInput.value,
                      payMethod: this.paymentMethod,
                      promoCode: this.promoInput.value,
                      currency: this.tabActive === 1 ? 'kzt' : 'rub'
                    }
                  });

                  if (!promoResponse.data.status ||
                    promoResponse.data.discountRubByPromo === 0 ||
                    promoResponse.data.discountPercentByPromo === 0) {
                    this.promoInput.value = null;
                    this.promoInput.status = false;
                    this.promoInput.isFromUtm = false;
                    this.promoInput.error = 'Промокод не найден или истек срок действия.';
                    // Обнуляем скидку по промокоду
                    this.paymentData.discountPercentByPromo = 0;
                    this.paymentData.discountRubByPromo = 0;
                    setTimeout(() => {
                      this.promoInput.error = '';
                    }, 6000);
                  } else {
                    this.promoInput.status = true;
                    this.paymentData = {
                      ...this.paymentData,
                      ...promoResponse.data
                    };
                  }
                } catch (promoError) {
                  this.promoInput.value = null;
                  this.promoInput.status = false;
                  this.promoInput.isFromUtm = false;
                  this.promoInput.error = 'Промокод не найден или истек срок действия.';
                }
              }
            }

            if (data.isFirst) {
              this.loginInput.error = 'first-login-message';
            } else {
              this.loginInput.error = '';
            }

          } else {
            this.loginInput.status = false;
            this.loginInput.error = data.message || 'Проблема с логином, напишите в наш чат поддержки';
          }
        }
      } catch (error) {
        console.error('Login check error:', error);
        this.loginInput.status = false;
        this.loginInput.error = 'Произошла ошибка. Повторите позже';
      } finally {
        this.loginInputLoading = false;
        this.loginInput.jobId = null;
      }
    },

    formatLabel(n) {
      return `${n} ${this.currency}`;
    },

    async generateClientId() {
      try {
        const clientId = await metrikaService.getClientId();
        // console.log('generateClientId:', clientId);
        return clientId;
      } catch (error) {
        // console.error('Error getting client ID:', error);
        return null;
      }
    },
  },

  watch: {
    $route(to) {
      const urlParams = new URLSearchParams(to.query);
      const refCode = urlParams.get('ref');

      if (refCode) {
        this.referralCode = refCode;
        localStorage.setItem('referral_code', refCode);
        // console.log("Обновлен реферальный код из URL:", refCode);
      }

      // Сохраняем UTM параметры при изменении маршрута
      const hasUtm = Object.keys(to.query).some(key => key.startsWith('utm_'));
      if (hasUtm) {
        const queryString = new URLSearchParams(to.query).toString();
        this.utmString = `?${queryString}`;
        localStorage.setItem('utmParams', this.utmString);
      }
      this.getUrlParams();

    },


    $route: {
      immediate: true,
      handler(to) {
        if (to.path === '/cards') {
          this.tabActive = 2;
          this.currency = '₽';
        } else if (to.path === '/kazakhstan') {
          this.tabActive = 1;
          this.currency = '₸';
          this.currencyType = 'kzt';
        }
        else if (to.path === '/pubg-uc') {
          this.tabActive = 3;
          this.currency = '₽';
          this.currencyType = 'rub';
        } else {
          this.tabActive = 0;
          this.currency = '₽';
          this.currencyType = 'rub';
        }

        this.$nextTick(() => {
          this.handleTabChange(to.path);
        });
      }
    },
    currency: {
      immediate: true,
      handler(newValue) {
        if (newValue === '₸') {
          this.currencyType = 'kzt';
        } else {
          this.currencyType = 'rub';
        }
      }
    },
    tabActive: {
      immediate: true,
      handler(newValue) {
        if (newValue === 1) {
          this.currency = '₸';
        } else {
          this.currency = '₽';
        }
      }
    },


    paymentError: {
      immediate: true,
      handler(newValue) {
        if (newValue && window.innerWidth <= 991) {
          this.scrollToTop();
        }
      }
    },

    paymentMethod() {
      if (this.tabActive === 2 && this.selectedCardData) {
        this.handleCardSelect(this.selectedCardData);
      } else {
        this.calculate();
      }
    },
  },
  'emailInput.value': function () {
    if (this.emailInput.touched) {
      this.validateEmail();
    }
    if (this.tabActive === 2 && this.selectedCardData) {
      this.handleCardSelect(this.selectedCardData);
    }
  },

};
</script>

<style>
.ponters {
  cursor: pointer;
}

.pr-6 {
  padding-right: 5px;
}
</style>
