const METRIKA_ID = 88268336;
const INIT_DELAY = 1000;

class MetrikaService {
    constructor() {
        this.initialized = false;
        this.clientId = null;
        this.successPaymentSent = false;
        this.eventQueue = [];
        this.initializationPromise = null;
        this.initializationAttempts = 0;
        this.maxInitializationAttempts = 3;
        // console.log('🔄 MetrikaService: Starting initialization');
        this.init();
    }

    init() {
        // Больше не используем localStorage для хранения ID
        this.initializationPromise = new Promise((resolve) => {
            setTimeout(() => {
                this.initializeYandexMetrika().then(() => {
                    this.processEventQueue();
                    resolve();
                }).catch(async () => {
                    if (this.initializationAttempts < this.maxInitializationAttempts) {
                        this.initializationAttempts++;
                        await this.init();
                    } else {
                        resolve();
                    }
                });
            }, INIT_DELAY);
        });
    }

    async initializeYandexMetrika() {
        // console.log('🔄 MetrikaService: Initializing Yandex.Metrika');

        await new Promise((resolve, reject) => {
            (function (m, e, t, r, i, k, a) {
                m[i] = m[i] || function () {
                    (m[i].a = m[i].a || []).push(arguments);
                };
                m[i].l = 1 * new Date();
                k = e.createElement(t),
                    a = e.getElementsByTagName(t)[0],
                    k.async = 1,
                    k.src = r;
                k.onload = resolve;
                k.onerror = reject;
                a.parentNode.insertBefore(k, a);
            })(window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");

            window.ym(METRIKA_ID, "init", {
                clickmap: true,
                trackLinks: true,
                accurateTrackBounce: true,
                webvisor: true,
                trackHash: true,
                triggerEvent: true
            });
        });

        await this.getAndUpdateClientId();
        this.setupClientIdObserver();
        this.initialized = true;
        // console.log('✅ MetrikaService: Initialization complete');
    }

    async getAndUpdateClientId() {
        if (!window.ym) {
            return null;
        }

        try {
            await new Promise((resolve) => {
                window.ym(METRIKA_ID, 'getClientID', (clientID) => {
                    this.clientId = clientID;
                    this.updateClientIdFields();
                    // console.log('✅ MetrikaService: Got clientId:', clientID);
                    resolve(clientID);
                });
            });
        } catch (error) {
            // console.error('Error getting client ID:', error);
            return null;
        }
    }

    updateClientIdFields() {
        if (!this.clientId) return;

        const fields = document.getElementsByName('client-id-input');
        Array.from(fields).forEach(field => {
            field.value = this.clientId;
        });
    }

    setupClientIdObserver() {
        const observer = new MutationObserver((mutations) => {
            mutations.forEach((mutation) => {
                if (mutation.addedNodes.length) {
                    const hasNewFields = Array.from(mutation.addedNodes).some(node => {
                        return node.getElementsByName && node.getElementsByName('client-id-input').length > 0;
                    });

                    if (hasNewFields) {
                        this.updateClientIdFields();
                    }
                }
            });
        });

        observer.observe(document.body, {
            childList: true,
            subtree: true
        });
    }

    async waitForInitialization() {
        if (this.initialized) return;
        await this.initializationPromise;
    }

    processEventQueue() {
        while (this.eventQueue.length > 0) {
            const event = this.eventQueue.shift();
            this._sendSuccessPaymentInternal(event);
        }
    }

    async sendSuccessPayment(orderData) {
        if (this.successPaymentSent) return;

        if (!this.initialized) {
            this.eventQueue.push(orderData);
            await this.waitForInitialization();
            return;
        }

        await this._sendSuccessPaymentInternal(orderData);
    }

    _sendSuccessPaymentInternal(orderData) {
        if (!window.ym) {
            this.eventQueue.push(orderData);
            return;
        }

        const params = {
            order_id: orderData.orderId,
            order_price: orderData.total,
            currency: 'RUB',
            payment_type: orderData.paymentMethod,
            is_gift_card: orderData.isGiftCard,
            utm: orderData.utm,
            client_id: this.clientId,
            user_login: orderData.login,
            user_email: orderData.email,
            promo_code: orderData.promoCode,
            gift_card_id: orderData.cardId,
            payment_amount: orderData.amount
        };

        try {
            window.ym(METRIKA_ID, 'reachGoal', 'successpayment', params);
            this.successPaymentSent = true;
        } catch (error) {
            this.eventQueue.push(orderData);
        }
    }

    async getClientId() {
        await this.waitForInitialization();
        if (!this.clientId) {
            // Если ID еще нет, генерируем временный
            return Date.now().toString();
        }
        return this.clientId;
    }
}

export default new MetrikaService();