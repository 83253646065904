import { siteConfig } from '@/config/site-config.js';

export const VKLinking = {
    async generateAuthUrl() {
        try {
            const codeVerifier = this.generateCodeVerifier();
            const codeChallenge = await this.generateCodeChallenge(codeVerifier);
            const state = this.generateState();

            localStorage.setItem('vk_link_data', JSON.stringify({
                code_verifier: codeVerifier,
                state: state,
                timestamp: Date.now()
            }));

            const params = new URLSearchParams({
                client_id: siteConfig.vkClientId,
                redirect_uri: `${siteConfig.baseUrl}/auth/vk-link-callback`,
                response_type: 'code',
                scope: 'email',
                state: state,
                code_challenge: codeChallenge,
                code_challenge_method: 'S256'
            });

            return {
                url: `https://id.vk.com/authorize?${params.toString()}`,
                state
            };
        } catch (error) {
            console.error('Error generating auth URL:', error);
            throw error;
        }
    },

    generateState() {
        const length = 32;
        const charset = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789-_';
        const state = new Uint8Array(length);
        crypto.getRandomValues(state);
        return Array.from(state)
            .map((x) => charset[x % charset.length])
            .join('');
    },

    generateCodeVerifier() {
        const length = 64;
        const charset = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-._~';
        const verifier = new Uint8Array(length);
        crypto.getRandomValues(verifier);
        return Array.from(verifier)
            .map((x) => charset[x % charset.length])
            .join('');
    },

    async generateCodeChallenge(verifier) {
        const encoder = new TextEncoder();
        const data = await crypto.subtle.digest('SHA-256', encoder.encode(verifier));
        return btoa(String.fromCharCode(...new Uint8Array(data)))
            .replace(/\+/g, '-')
            .replace(/\//g, '_')
            .replace(/=/g, '');
    },

    validateState(receivedState) {
        try {
            const savedData = localStorage.getItem('vk_link_data');
            // console.log('Saved link data:', savedData);

            if (!savedData) {
                // console.log('No saved link data found');
                return false;
            }

            const linkData = JSON.parse(savedData);
            // console.log('Comparing states:', {
            //     saved: linkData.state,
            //     received: receivedState
            // });

            // Проверяем срок действия (15 минут)
            const isExpired = Date.now() - linkData.timestamp > 15 * 60 * 1000;
            if (isExpired) {
                // console.log('Link data expired');
                localStorage.removeItem('vk_link_data');
                return false;
            }

            return linkData.state === receivedState;
        } catch (error) {
            console.error('Error validating state:', error);
            return false;
        }
    },

    clearLinkData() {
        localStorage.removeItem('vk_link_data');
    }
};