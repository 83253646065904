<template>
    <div v-if="show" class="modal-overlay" @click.self="onClose" @keydown.esc="onClose" tabindex="-1">
        <section class="payment-success">
            <header class="payment-success__header">
                <h1 class="payment-success__title">Оплата успешна!</h1>
                <button class="payment-success__close" @click="onClose" aria-label="Close">
                    <div class="payment-success__close-icon"> <svg width="24" height="24" viewBox="0 0 24 24"
                            fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M18 6L6 18M6 6L18 18" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                stroke-linejoin="round" />
                        </svg></div>
                </button>
            </header>
            <p class="payment-success__message">
                В течение нескольких минут код активации придет на указанную почту.
            </p>
            <p class="payment-success__join-group">Присоединяйтесь к нашей группе:</p>

            <a href="https://vk.com/steammoney" target="_blank" class="payment-success__group-link">
                <img src="/new/images/vk-new.svg" alt="" class="payment-success__group-icon" />
                <span class="payment-success__group-text">Наша группа ВК</span>
                <img src="/new/images/right_array.svg" alt="" class="payment-success__arrow-icon" />
            </a>
        </section>
    </div>
</template>

<script>

export default {
    props: {
        show: {
            type: Boolean,
            required: true
        },
        orderId: {
            type: String,
            required: true,
            default: null 
        }
    },

    mounted() {
        document.addEventListener('keydown', this.handleKeyDown);
    },

    beforeUnmount() {
        document.removeEventListener('keydown', this.handleKeyDown);

    },

    methods: {
        copyOrderId() {
            navigator.clipboard.writeText(this.orderId);
        },
        onClose() {
            this.$emit('close');
        },
        handleKeyDown(e) {
            if (this.show && e.key === 'Escape') {
                this.onClose();
            }
        }
    }
}
</script>

<style scoped>
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(43, 43, 43, 0.75);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
}

.payment-success {
    border-radius: 16px;
    background-color: #131313;
    max-width: 596px;
    width: 100%;
    color: #edeeee;
    padding: 40px 32px;
    font: 400 14px Inter, sans-serif;
    margin: 20px;
}

.payment-success__header {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin-bottom: 20px;
}

.payment-success__title {
    color: #edeeee;
    font-size: 24px;
    font-weight: 500;
    line-height: 1;
    text-align: center;
    margin: 0;
}

.payment-success__close {
    position: absolute;
    right: -16px;
    top: -16px;
    background: none;
    border: none;
    cursor: pointer;
    width: 24px;
    height: 24px;
    padding: 0;
}

.payment-success__close-icon {
    width: 24px;
    height: 24px;
    /* mask-image: url('/new/images/close_MD.svg'); */
    mask-size: cover;
    /* background-color: #fff; */
}
.payment-success__close-icon svg {
    color: #fff;
}

body.light .payment-success__close-icon svg {
    color: #131313;
}

.payment-success__message {
    color: rgba(237, 238, 238, 0.48);
    line-height: 20px;
    text-align: center;
    margin: 0 0 20px;
}

.payment-code-container {
    border-radius: 12px;
    background-color: rgba(36, 255, 111, 0.04);
    width: 100%;
    padding: 16px;
    margin-bottom: 20px;
}

.payment-code-wrapper {
    display: flex;
    flex-direction: column;
}

.payment-code-label {
    color: rgba(237, 238, 238, 0.48);
    font-size: 12px;
    line-height: 2;
    margin-bottom: 4px;
}

.payment-code-display {
    border-radius: 8px;
    background-color: rgba(237, 238, 238, 0.04);
    display: flex;
    min-height: 40px;
    align-items: center;
    color: #edeeee;
    justify-content: space-between;
    padding: 6px 6px 6px 16px;
    border: 1px solid rgba(237, 238, 238, 0.04);
}

.payment-code-text {
    font-size: 14px;
    line-height: 24px;
    flex: 1;
}

.copy-button {
    border-radius: 6px;
    background-color: rgba(237, 238, 238, 0.04);
    font-size: 12px;
    line-height: 2;
    padding: 4px 8px;
    border: 1px solid rgba(237, 238, 238, 0.04);
    color: #edeeee;
    cursor: pointer;
}

.copy-button:hover {
    background-color: rgba(237, 238, 238, 0.08);
}

.payment-success__join-group {
    color: #24ff6f;
    line-height: 24px;
    text-align: center;
    margin-top: 20px;
}

.payment-success__group-link {
    border-radius: 8px;
    background-color: rgba(237, 238, 238, 0.04);
    display: flex;
    align-items: center;
    gap: 12px;
    font-size: 16px;
    line-height: 1;
    padding: 12px 16px;
    margin: 20px auto 0;
    text-decoration: none;
    color: inherit;
    width: 235px;
}

.payment-success__group-link:hover {
    background-color: rgba(237, 238, 238, 0.08);
}

.payment-success__group-icon {
    width: 28px;
    height: 28px;
    object-fit: contain;
}

.payment-success__group-text {
    color: inherit;
}

.payment-success__arrow-icon {
    width: 24px;
    height: 24px;
    margin-left: auto;
}

body.light .payment-success {
    background-color: #fff;
    color: #131313;
}

body.light .payment-success__title {
    color: #131313;
}

body.light .payment-success__message {
    color: rgba(19, 19, 19, 0.72);
}

body.light .payment-code-container {
    background-color: rgba(0, 190, 58, 0.12);
}

body.light .payment-code-label {
    color: rgba(19, 19, 19, 0.72);
}

body.light .payment-code-display {
    background-color: rgba(19, 19, 19, 0.06);
    border-color: rgba(19, 19, 19, 0.06);
    color: #131313;
}

body.light .copy-button {
    background-color: rgba(19, 19, 19, 0.06);
    border-color: rgba(19, 19, 19, 0.06);
    color: #131313;
}

body.light .payment-success__join-group {
    color: #0db740;
}

body.light .payment-success__group-link {
    background-color: rgba(19, 19, 19, 0.06);
    color: #131313;
}

body.light .payment-success__close-icon {
    /* background-color: #131313; */
}
body.light .payment-success__arrow-icon {
    filter: invert(0%) sepia(0%) saturate(0%) hue-rotate(93deg) brightness(0%) contrast(100%);
}

@media (max-width: 991px) {
    .payment-success__close {
            position: absolute;
            right: -12px;
            top: -12px;
          
        }
    .payment-success {
        padding: 20px;
        margin: 16px;
    }
    

    .payment-success__message,
    .payment-code-container {
        max-width: 100%;
    }

    .payment-code-display {
        flex-direction: column;
        align-items: stretch;
        gap: 8px;
    }

    .payment-code-text {
        word-break: break-all;
    }
}
</style>
