<template>
    <div class="site-width">
        <div v-if="loading" class="not-found">
            <h1>Проверяем страницу...</h1>
        </div>
        <div v-else class="not-found">
            <h1>404 not found</h1>
            <div class="section-4">
                <router-link to="/" class="all">На главную</router-link>
            </div>
        </div>
    </div>
</template>

<script>
import { redirectAPI } from '@/service/api/redirect';

export default {
    name: "NotFound",

    data() {
        return {
            loading: true
        };
    },

    async created() {
        try {
            const path = window.location.pathname.substring(1);

            const data = await redirectAPI.checkRedirect(path);

            if (data.status && data.redirect) {
                window.location.href = data.redirect;
            } else {
                this.loading = false;
            }
        } catch (error) {
            console.error('Ошибка проверки редиректа:', error);
            this.loading = false;
        }
    }
};
</script>

<style scoped>
.not-found {
    min-height: calc(100vh - 200px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding-bottom: 100px;
}

.order:hover {
    background-color: #0056b3;
}
</style>