<template>
    <div class="silent-auth" :class="{ 'show-error': error }">
        <div v-if="error" class="error-popup">
            <div class="error-content">
                <div class="error-message">{{ error }}</div>
                <button class="error-button" @click="handleClose">Закрыть</button>
            </div>
        </div>
    </div>
</template>

<script>
import { useAuthStore } from '@/store/auth';

export default {
    name: 'VkCallback',

    setup() {
        const authStore = useAuthStore();
        return { authStore };
    },

    data() {
        return {
            error: null,
            isProcessing: false
        };
    },

    // Используем created вместо mounted для более быстрой обработки
    created() {
        this.initSilentAuth();
    },

    methods: {
        checkReferralCode() {
            const urlParams = new URLSearchParams(window.location.search);
            const refParam = urlParams.get('ref');

            if (refParam) {
                localStorage.setItem('referral_code', refParam);
                // console.log("AuthPopup: Сохранен реферальный код из URL:", refParam);
                this.referralCode = refParam;
            } else if (this.referralCode) {
                // console.log("AuthPopup: Используем реферальный код из localStorage:", this.referralCode);
            }
        },
        async initSilentAuth() {
            // Установка флага для предотвращения повторной обработки
            if (this.isProcessing) return;
            this.isProcessing = true;
            let refCode;

            if (!refCode) {
                refCode = localStorage.getItem('referral_code') || '';
            }


            try {
                // Получаем параметры из URL
                const urlParams = new URLSearchParams(window.location.search);
                const code = urlParams.get('code');
                const state = urlParams.get('state');
                const device_id = urlParams.get('device_id');
                const refFromUrl = urlParams.get('ref');
                const refFromStorage = localStorage.getItem('referral_code');
                const refCode = refFromUrl || refFromStorage || '';

                if (refFromUrl) {
                    localStorage.setItem('referral_code', refFromUrl);
                }

                const authData = JSON.parse(localStorage.getItem('vk_auth_data') || '{}');
                const code_verifier = authData.code_verifier;

                if (!code || !state) {
                    throw new Error('Отсутствуют необходимые параметры авторизации');
                }

                if (!authData.state || authData.state !== state) {
                    throw new Error('Недействительный state параметр');
                }

                if (device_id) {
                    localStorage.setItem('vk_device_id', device_id);
                }

                const success = await this.authStore.loginWithVk({
                    code,
                    state,
                    code_verifier,
                    device_id,
                    ref_code: refCode
                });

                if (success) {
                    localStorage.removeItem('vk_auth_data');

                    if (window.opener) {
                        window.opener.postMessage({ type: 'vk-auth-success' }, '*');
                        setTimeout(() => window.close(), 100);
                    } else {
                        window.location.href = '/';
                    }
                } else {
                    throw new Error('Не удалось выполнить вход');
                }

            } catch (error) {
                console.error('Silent auth error:', error);
                this.error = error.message || 'Произошла ошибка при авторизации';

                if (window.opener) {
                    window.opener.postMessage({
                        type: 'vk-auth-error',
                        error: this.error
                    }, '*');
                }
            } finally {
                this.isProcessing = false;
            }
        },

        handleClose() {
            if (window.opener) {
                window.close();
            } else {
                window.location.href = '/';
            }
        }
    }
};
</script>

<style scoped>
.silent-auth {
    display: none;
}

.silent-auth.show-error {
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    justify-content: center;
    align-items: center;
    z-index: 9999;
}

.error-popup {
    background: #fff;
    border-radius: 12px;
    padding: 24px;
    max-width: 400px;
    width: 90%;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
}

.error-content {
    text-align: center;
}

.error-message {
    color: #FF3B30;
    margin-bottom: 16px;
    font-size: 14px;
    line-height: 1.5;
}

.error-button {
    background: #FF3B30;
    color: white;
    border: none;
    padding: 8px 16px;
    border-radius: 6px;
    cursor: pointer;
    font-size: 14px;
    transition: opacity 0.2s;
}

.error-button:hover {
    opacity: 0.9;
}

:global(body.dark) .error-popup {
    background: #1a1a1a;
}

:global(body.dark) .error-message {
    color: #FF3B30;
}

@media (prefers-reduced-motion: reduce) {
    .silent-auth {
        transition: none;
    }
}

@media (max-width: 480px) {
    .error-popup {
        margin: 20px;
        padding: 20px;
    }
}
</style>