<template>
    <div class="social">
        <a :href="links.telegram" class="tl" target="_blank" @click.prevent="handleClick('telegram')"></a>
        <a :href="links.vk" class="vk" target="_blank" @click.prevent="handleClick('vk')"></a>
    </div>
</template>

<script>
import { siteConfig } from '../config/site-config';

export default {
    name: 'SocialLinks',

    data() {
        return {
            isMobile: false
        }
    },

    computed: {
        links() {
            return {
                telegram: this.isMobile ? siteConfig.socialMobileLinks.telegram : siteConfig.socialLinks.telegram,
                vk: this.isMobile ? siteConfig.socialMobileLinks.vk : siteConfig.socialLinks.vk
            }
        }
    },

    methods: {
        checkMobile() {
            this.isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
        },

        handleClick(type) {
            if (this.isMobile) {
                window.location.href = this.links[type];
            } else {
                window.open(this.links[type], '_blank');
            }
        }
    },

    mounted() {
        this.checkMobile();
        window.addEventListener('resize', this.checkMobile);
    },

    beforeUnmount() {
        window.removeEventListener('resize', this.checkMobile);
    }
}
</script>