<template>
    <div class="referrals-container">
        <div class="section-referals-one">
            <div class="title">Приглашай друзей и получай бонусы!</div>
            <div class="desc">
                За каждого приглашенного друга <br />
                начисляются SM баллы со всех его покупок
            </div>
            <div class="people"></div>
            <div class="title">
                Как заработать с реферальной <br />
                программой?
            </div>

            <div class="flex">
                <div class="item">
                    <div class="number">1</div>
                    <div class="name">Скопируй свою реферальную ссылку <br> и/или промокод</div>
                </div>

                <div class="item">
                    <div class="number">2</div>
                    <div class="name">Отправь своим друзьям или размести в своём сообществе</div>
                </div>

                <div class="item">
                    <div class="number">3</div>
                    <div class="name">Получай SM бонусы за все заказы приглашенного друга</div>
                </div>
            </div>
        </div>

        <div class="section-referals-two">
            <div class="title">Ваша реферальная ссылка и промокод</div>
            <p>Бонусы начисляются только за заказы по реферальной ссылке или с использованием вашего промокода</p>
        </div>

        <div class="section-referals-three">
            <div class="title">Ваша общая реферальная ссылка</div>

            <div class="desc">
                <span>+{{ settings?.ref_coins || 0 }}% </span>
                отчислений в виде SM бонусов от всех покупок приглашенного реферала
            </div>

            <div class="label">
                <label>Реферальная ссылка</label>
                <div class="input">
                    <input type="text" :value="referralLink" readonly />
                    <div class="copy" @click="copyRefLink">
                        {{ copyStatus }}
                    </div>
                </div>
            </div>
            <div class="label">
                <label>или реферальный промокод</label>
                <div class="input">
                    <input type="text" :value="refCode" name="" readonly />
                    <div class="copy" @click="copyPromoCode">
                        {{ promoCodeCopyStatus }}
                    </div>
                </div>
            </div>
        </div>

        <!-- <div class="section-referals-three bg2">
            <div class="title">Покупка гифтов на Steam Money</div>

            <div class="desc">
                <span>+{{ settings?.ref_coins || 0 }}% </span>
                бонусных рублей за первый заказ
                друга
            </div>

            <div class="label">
                <label>Реферальная ссылка клиента</label>
                <div class="input">
                    <input type="text" :value="referralLink" readonly />
                    <div class="copy" @click="copyRefLink
                        ">
                        {{ copyStatus }}
                    </div>
                </div>
            </div>
        </div> -->

        <div class="section-referals-four">
            <div class="flex">
                <template v-if="referralStore.loading">
                    <div class="item">
                        <div class="number">
                            <div class="skeleton-animation" style="width: 60px; height: 32px;"></div>
                        </div>
                        <div class="name">Приглашено человек</div>
                    </div>
                    <div class="item">
                        <div class="number">
                            <div style="display: flex; align-items: center; gap: 8px;">
                                <div class="skeleton-animation" style="width: 80px; height: 32px;"></div>
                                <div class="skeleton-animation" style="width: 24px; height: 24px; border-radius: 50%;">
                                </div>
                            </div>
                        </div>
                        <div class="name">Заработано</div>
                    </div>
                </template>
                <template v-else>
                    <div class="item">
                        <div class="number">{{ referralStore.refCount }}</div>
                        <div class="name">Приглашено человек</div>
                    </div>
                    <div class="item">
                        <div class="number">+{{ referralStore.refTotalAmount }} <img src="/images/stm-coin.svg"
                                class="theme-icon" alt="Монета" />
                        </div>
                        <div class="name">Заработано</div>
                    </div>
                </template>
                <div class="item">
                    <div class="number">
                        {{ userBalance }}
                        <img src="/images/stm-coin.svg" class="theme-icon" alt="Монета" />

                    </div>
                    <div class="name">Текущий баланс</div>
                </div>
            </div>

            <div class="title">История покупок по ссылке или с промокодом</div>
            <div class="desc">Бонусы начисляются только за заказы по реферальной ссылке или с использованием вашего
                промокода</div>

            <template v-if="referralStore.loading && referralStore.currentPage === 0">
                <div class="loading-state">Загрузка ...</div>
            </template>
            <!-- <template v-else-if="referralStore.error">
                <div class="error-state">{{ referralStore.error }}</div>
            </template> -->
            <template v-if="!referralStore.referralList?.length">
                <div class="empty-orders">
                    <div class="name">Здесь пока пусто</div>
                    <p>Тут отобразятся все транзакции приглашенных вами рефералов</p>
                </div>
            </template>


            <template v-if="referralStore.loading && referralStore.currentPage === 0">
                <!-- Скелетон для списка рефералов -->
                <div v-for="i in 3" :key="i" class="table">
                    <div class="td">
                        <label>Логин</label>
                        <div class="skeleton-animation" style="width: 120px; height: 24px;"></div>
                    </div>
                    <div class="td">
                        <label>Дата</label>
                        <div class="skeleton-animation" style="width: 100px; height: 24px;"></div>
                    </div>
                    <div class="td">
                        <label>Сумма</label>
                        <div class="skeleton-animation" style="width: 80px; height: 24px;"></div>
                    </div>
                    <div class="td last">
                        <label>Баллы</label>
                        <div class="skeleton-animation" style="display: flex; align-items: center; gap: 8px;">
                            <div class="skeleton-animation" style="width: 40px; height: 24px;"></div>
                            <div class="skeleton-animation" style="width: 24px; height: 24px; border-radius: 50%;">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="pagination">
                    <button class="pay skeleton-animation" style="width: 100%; height: 48px;">
                    </button>
                </div>
            </template>

            <template v-else>
                <div v-for="referral in referralStore.referralList" :key="referral.userId" class="table">
                    <div class="td">
                        <label>Логин</label>
                        <div class="text">{{ referral.userId || referral.login }}</div>
                    </div>
                    <div class="td">
                        <label>Дата</label>
                        <div class="text">{{ formatDate(referral.createdAt) }}</div>
                    </div>
                    <div class="td">
                        <label>Сумма</label>
                        <div class="text">{{ formatAmount(referral.amount) }}₽</div>
                    </div>
                    <div class="td last">
                        <label>Баллы</label>
                        <div>
                            +{{ referral.bonus }}
                            <img src="/images/stm-coin.svg" class="theme-icon" alt="Монета" />
                        </div>
                    </div>
                </div>
                <div v-if="referralStore.hasMore && referralStore.referralList.length >= 4" class="pagination">
                    <button class="pay" @click="handleLoadMore" :disabled="referralStore.loading">
                        {{ referralStore.loading ? 'Загрузка...' : 'Показать еще' }}
                    </button>
                </div>

            </template>


        </div>


        <div class="section-referals-five">
            <div class="title">Остались вопросы?</div>
            <div v-for="(question, index) in questions" :key="index" class="item-question"
                :class="{ 'active': activeQuestion === index }">
                <div class="question-name" @click="toggleQuestion(index)">
                    {{ question.title }}
                </div>
                <div class="answer" v-html="question.content"></div>
            </div>
        </div>


        <div class="section-referals-six">
            <div class="mobile">
                <div class="course">1 <img src="/images/stm-coin.svg" class="theme-icon" alt="Монета" />
                    = 1 ₽</div>
            </div>

            <div class="total">
                <template v-if="isBalanceLoading">
                    <div style="display: flex; align-items: center; gap: 8px;">
                        <div class="skeleton-animation" style="width: 80px; height: 32px;"></div>
                        <div class="skeleton-animation" style="width: 24px; height: 24px; border-radius: 50%;"></div>
                    </div>
                </template>
                <template v-else>
                    {{ userBalance }} <img src="/images/stm-coin.svg" class="theme-icon" alt="Монета" />

                </template>
            </div>
            <div class="button">
                <RefreshButton :is-loading="isRefreshing" @click="openGeneratePromo" />

            </div>
            <GeneratePromo ref="promoModal" />


        </div>
    </div>
</template>
<script>
import { useAuthStore } from '@/store/auth';
import { useReferralStore } from '@/store/referralStore';
import { useI18n } from 'vue-i18n';
import RefreshButton from '@/components/RefreshButton/RefreshButton.vue';
import GeneratePromo from './GeneratePromo.vue';
import settingsService from '@/service/api/service';
import { onMounted, ref } from 'vue';



export default {
    name: 'ProfileReferrals',

    components: {
        RefreshButton,
        GeneratePromo
    },

    setup() {
        const authStore = useAuthStore();
        const referralStore = useReferralStore();
        const settings = ref(null);
        const { t } = useI18n();

        onMounted(async () => {
            try {
                await settingsService.fetch();
                settings.value = settingsService.get();
            } catch (error) {
                console.error('Failed to load settings:', error);
            }
        });

        return { authStore, referralStore, settings, t };
    },

    data() {
        return {
            isRefreshing: false,
            copyTimeout: null,
            currentPage: 0,
            activeQuestion: null,
            promoCodeCopyStatus: 'Скопировать',
            promoCodeCopyTimeout: null,
            questions: [
                {
                    title: 'Коротко: Как работает реферальная система?',
                    content: 'Для приглашения друзей Вам доступны: личная реферальная ссылка и реферальный пригласительный код.<br><br> Если коротко: Вы пригласили друга, он оплатил услугу по вашей ссылке или с использованием вашего кода, вы получаете SM бонусы!<br> Для вашего приглашенного друга первая оплата по вашему реферальному коду дает ему дополнительную скидку :)'
                },
                {
                    title: 'Подробно: Как работает реферальная система?',
                    content: 'При помощи ссылок и реферального кода система сможет отслеживать приглашенных вами пользователей, и Вы будете получать отчисления с их оплат в виде SM бонусов на ваш бонусный счет. Далее Вы сможете использовать эти бонусы для применения скидок внутри нашего сайта.<br><br> Важно: Чтобы приглашенный пользователь закрепился за вами навсегда, попросите его авторизовать на сайте после перехода по вашей ссылке (через VK или Telegram) и Вы будете всегда получать бонусные отчисления со всех его следующих оплат! В ином случае, без авторизации, вы будете получать бонусные отчисления только в течении 90 дней с момента перехода по ссылке'
                },
                {
                    title: 'Как и сколько бонусов я получу за приглашенного друга?',
                    content: 'Вы будете получать процентные отчисления на бонусный счет в зависимости от услуги, которой воспользуется ваш приглашенный друг. <br><br> На примере пополнения STEAM по логину: Вы будете получать 2% на бонусный счет с пополнений по логину, а ваш друг получит скидку 2% на первое пополнение при использовании вашего промокода.<br><br> При добавлении новых сервисов и услуг на сайт мы дополним этот список, где будет информация об отчислениях.'
                },
                {
                    title: 'Я пригласил друга, но мне не начислили бонусы, что делать?',
                    content: 'Начисление бонусов происходит только за новых приглашенных пользователей. Если пользователь когда-то был на нашем сайте в течении последних 180 дней, система не может его засчитать как нового приглашенного пользователя',
                },
                {
                    title: 'Как использовать SM бонусы?',
                    content: `SM бонусы можно обменять на скидочный промокод на <a href="/profile/bonuses" class="router-link">странице бонусов и промокодов</a>. Там же отображаются созданные вами промокоды. Применяйте их при оплате услуг и получайте скидку* за использование промокода.<br><br>
    
 <div class="important-note">
        <span class="note-content">
            <i>*скидка по промокоду действует на сумму комиссии сайта и может быть не больше, чем 50% от комиссии сайта. Оставшаяся и не используемая сумма промокода сгорает при использовании этого промокода.<br><br>
            Например: комиссия сайта 120 рублей, промокод на скидку 100 рублей. 50% от 120 это 60. Ваш промокод на 100 рублей сделает максимальную скидку в 60 рублей, и оставшаяся сумма промокода в 40 рублей сгорит.
        </i></span>
    </div>`
                },
                {
                    title: 'Как получать больше SM бонусов?',
                    content: `Чтобы получать больше SM бонусов, вы можете выполнять задания доступные на <a href="/profile" class="router-link">странице вашего профиля</a> на сайте. А приглашая друзей по <a href="/profile/referrals" class="router-link">реферальной программе</a>, Вы получите еще больше SM бонусов!`
                },
            ],

        };
    },

    computed: {
        copyStatus() {
            return this.t('referrals.links.copy');
        },
        isBalanceLoading() {
            return !this.authStore.user || this.authStore.loading;
        },

        userBalance() {
            return this.authStore.userBalance;
        },
        refCode() {
            const code = this.authStore.userRefCode;
            if (code && typeof code === 'object' && code.value !== undefined) {
                return code.value;
            }
            return code || this.t('referrals.links.notFound');
        },
        referralLink() {
            const baseUrl = window.location.origin;
            return `${baseUrl}/?ref=${this.refCode}`;
        }
    },

    methods: {
        handleRouterLink(event) {
            if (event.target.classList.contains('router-link')) {
                event.preventDefault();
                this.$router.push(event.target.getAttribute('href'));
            }
        },
        async copyPromoCode() {
            try {
                await navigator.clipboard.writeText(this.refCode);
                this.promoCodeCopyStatus = 'Скопировано';

                if (this.promoCodeCopyTimeout) {
                    clearTimeout(this.promoCodeCopyTimeout);
                }

                this.promoCodeCopyTimeout = setTimeout(() => {
                    this.promoCodeCopyStatus = 'Скопировать';
                }, 2000);
            } catch (err) {
                console.error('Ошибка при копировании промокода:', err);
            }
        },
        toggleQuestion(index) {
            this.activeQuestion = this.activeQuestion === index ? null : index;
        },
        async handleLoadMore() {
            if (this.loading) return;
            this.loading = true;
            try {
                await this.referralStore.loadMore();
            } finally {
                this.loading = false;
            }
        },
        formatDate(date) {
            return new Date(date).toLocaleDateString('ru-RU', {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric'
            });
        },

        formatAmount(amount) {
            return new Intl.NumberFormat('ru-RU', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            }).format(amount);
        },

        async loadReferralData() {
              await  this.referralStore.fetchReferralStats(),
              await  this.referralStore.fetchReferralList()
        },

        async openGeneratePromo() {
            try {
                if (this.$refs.promoModal) {
                    await this.$refs.promoModal.open();
                }
            } catch (error) {
                console.error(this.t('referrals.balance.errors.modal'), error);
            }
        },

        async copyRefLink() {
            try {
                await navigator.clipboard.writeText(this.referralLink);
                this.copyStatus = this.t('referrals.links.copied');

                if (this.copyTimeout) {
                    clearTimeout(this.copyTimeout);
                }

                this.copyTimeout = setTimeout(() => {
                    this.copyStatus = this.t('referrals.links.copy');
                }, 2000);
            } catch (err) {
                console.error(this.t('referrals.balance.errors.copy'), err);
            }
        },
    },
    beforeUnmount() {
        // Очищаем таймауты при размонтировании компонента
        if (this.copyTimeout) {
            clearTimeout(this.copyTimeout);
        }
        if (this.promoCodeCopyTimeout) {
            clearTimeout(this.promoCodeCopyTimeout);
        }
        document.removeEventListener('click', this.handleRouterLink);

    },

    async mounted() {
        document.addEventListener('click', this.handleRouterLink);
        await this.loadReferralData();
    }
};
</script>

<style>
.theme-icon {
    filter: invert(0);
}

body.light .theme-icon {
    content: url('/images/stm-coin-orange.svg');
}

.skeleton-animation {
    position: relative;
    overflow: hidden;
    border-radius: 8px;
    background: rgba(255, 255, 255, 0.04);
}

.skeleton-animation::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background: linear-gradient(90deg,
            transparent 0,
            rgba(255, 255, 255, 0.1) 20%,
            rgba(255, 255, 255, 0.2) 60%,
            transparent);
    animation: shimmer 2s infinite;
}

/* Стили для светлой темы */
body.light .skeleton-animation {
    background: rgba(19, 19, 19, 0.04);
}

body.light .skeleton-animation::after {
    background: linear-gradient(90deg,
            transparent 0,
            rgba(19, 19, 19, 0.08) 20%,
            rgba(19, 19, 19, 0.12) 60%,
            transparent);
}

@keyframes shimmer {
    100% {
        transform: translateX(100%);
    }
}

.section-referals-four .td {
    flex: 1;
}

.section-referals-four .td label {
    color: var(--Text-Secondary, rgba(237, 238, 238, 0.48));
    font-size: 12px;
    display: block;
    margin-bottom: 4px;
    text-overflow: ellipsis;
}

.section-referals-four .td .text {
    display: flex;
    align-items: center;
    gap: 4px;
}

.section-referals-four .td .text img {
    width: 16px;
    height: 16px;
}

:deep(.router-link) {
    color: #24FF6F;
    text-decoration: none;
}

:deep(.router-link:hover) {
    text-decoration: underline;
}
</style>