import { createI18n } from 'vue-i18n';
import ru from '@/locales/ru.js';

const i18n = createI18n({
    legacy: false, // Используем Composition API
    locale: 'ru', // Устанавливаем локаль по умолчанию
    fallbackLocale: 'ru', // Резервная локаль
    messages: {
        ru // Подключаем русские переводы
    }
});

export default i18n;