import { defineStore } from 'pinia';
import settingsService from '@/service/api/service';

export const refCodeStore = defineStore('referral', {
    state: () => ({
        referralCode: null,
        referralExpiry: null
    }),

    actions: {
        saveReferralCode(code) {
            if (!code) return;

            const settings = settingsService.get();
            const expiryDays = settings?.ref_exp_days || 90;

            const expires = new Date();
            expires.setDate(expires.getDate() + expiryDays);

            // Update store state
            this.referralCode = code;
            this.referralExpiry = expires.getTime();

            // Save to localStorage with full details
            const referralData = {
                code: code,
                expires: expires.getTime()
            };
            localStorage.setItem('referral_data', JSON.stringify(referralData));

            // Set secure, SameSite cookie
            document.cookie = `ref_code=${encodeURIComponent(code)}; expires=${expires.toUTCString()}; path=/; SameSite=Lax${window.location.protocol === 'https:' ? '; Secure' : ''}`;

            // console.log('Referral code saved:', code);
        },

        loadReferralCode() {
            // Check URL parameters first
            const urlParams = new URLSearchParams(window.location.search);
            const urlRefCode = urlParams.get('ref');

            if (urlRefCode) {
                this.saveReferralCode(urlRefCode);
                return urlRefCode;
            }

            // Check localStorage
            const storedReferralDataStr = localStorage.getItem('referral_data');
            if (storedReferralDataStr) {
                try {
                    const storedReferralData = JSON.parse(storedReferralDataStr);
                    const currentTime = new Date().getTime();

                    if (currentTime < storedReferralData.expires) {
                        this.referralCode = storedReferralData.code;
                        this.referralExpiry = storedReferralData.expires;

                        // Ensure cookie is set from localStorage if valid
                        const expires = new Date(storedReferralData.expires);
                        document.cookie = `ref_code=${encodeURIComponent(storedReferralData.code)}; expires=${expires.toUTCString()}; path=/; SameSite=Lax${window.location.protocol === 'https:' ? '; Secure' : ''}`;

                        return storedReferralData.code;
                    } else {
                        this.clearReferralCode();
                    }
                } catch (error) {
                    console.error('Error parsing referral data:', error);
                    this.clearReferralCode();
                }
            }

            // Check cookies
            const cookieValue = document.cookie
                .split('; ')
                .find(row => row.startsWith('ref_code='));

            if (cookieValue) {
                const code = decodeURIComponent(cookieValue.split('=')[1]);
                this.referralCode = code;
                return code;
            }

            return null;
        },

        getCurrentReferralCode() {
            // First, check if there's a valid referral code in the URL
            const urlParams = new URLSearchParams(window.location.search);
            const urlRefCode = urlParams.get('ref');

            if (urlRefCode) {
                this.saveReferralCode(urlRefCode);
                return urlRefCode;
            }

            // If store has a valid referral code, return it
            if (this.hasValidReferralCode) {
                return this.referralCode;
            }

            // Otherwise, attempt to load from various sources
            const loadedCode = this.loadReferralCode();

            // console.log('Referral code retrieved:', loadedCode);
            return loadedCode;
        },

        clearReferralCode() {
            this.referralCode = null;
            this.referralExpiry = null;
            localStorage.removeItem('referral_data');
            document.cookie = 'ref_code=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
        },

        debugCookies() {
            console.log('All cookies:', document.cookie);
            const refCookie = document.cookie
                .split('; ')
                .find(row => row.startsWith('ref_code='));
            // console.log('Ref cookie found:', refCookie);
        }
    },

    getters: {
        hasValidReferralCode: (state) => {
            if (!state.referralCode || !state.referralExpiry) return false;
            return new Date().getTime() < state.referralExpiry;
        }
    }
});