<template>
    <div v-if="isVisible" class="modal-overlay" @click.self="$emit('close')">
        <section class="bonus-promo-code">
            <h2 v-if="title" class="section-title">{{ title }}</h2>
            <slot></slot>
            <button class="close-button" @click="$emit('close')" aria-label="Закрыть">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M18 6L6 18M6 6L18 18" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                        stroke-linejoin="round" />
                </svg>
            </button>
        </section>
    </div>
</template>

<script>

export default {
    name: 'UniversalPopup',
    props: {
        isVisible: {
            type: Boolean,
            default: false
        },
        title: {
            type: String,
            default: ''
        }
    },

    mounted() {
        window.addEventListener('keydown', this.handleKeyDown);
    },
    unmounted() {
        window.removeEventListener('keydown', this.handleKeyDown);
    },
    methods: {
        handleClose() {
            this.$emit('close');
        },
        handleKeyDown(e) {
            if (this.isVisible && e.key === 'Escape') {
                this.handleClose();
            }
        }
    }
}
</script>

<style scoped>
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    padding: 20px;
}

.bonus-promo-code {
    border-radius: 16px;
    background-color: #131313;
    position: relative;
    display: flex;
    max-width: 508px;
    width: 100%;
    /* Добавляем максимальную высоту и скролл */
    max-height: calc(100vh - 40px);
    /* 40px - это отступы по 20px сверху и снизу */
    overflow-y: auto;
    flex-direction: column;
    font-family: Inter, sans-serif;
    padding: 40px 32px;
}

body.light .bonus-promo-code{
    background-color: #fff;
}

.section-title {
    color: #edeeee;
    /* color: #131313; */
    font-size: 24px;
    font-weight: 500;
    line-height: 1;
    margin: 0 0 20px;
}
body.light .section-title{
    color: #131313;
}

.close-button {
    position: absolute;
    right: 16px;
    top: 16px;
    background: none;
    border: none;
    cursor: pointer;
    width: 24px;
    height: 24px;
    padding: 0;
}

.close-button-icon {
    width: 24px;
    height: 24px;
    /* mask-image: url('/new/images/close_MD.svg'); */
    mask-size: cover;
    background-color: #fff;
}

.close-button svg {
    color: #fff;
}

body.light .close-button svg {
    color: #131313;
}
body.light .close-button-icon{
    background-color: #131313;
} 

.bonus-promo-code::-webkit-scrollbar {
    width: 6px;
}

.bonus-promo-code::-webkit-scrollbar-track {
    background: rgba(255, 255, 255, 0.1);
    border-radius: 3px;
}

.bonus-promo-code::-webkit-scrollbar-thumb {
    background: rgba(255, 255, 255, 0.2);
    border-radius: 3px;
}

body.light .bonus-promo-code::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0.1);
}

body.light .bonus-promo-code::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.2);
}

:deep(.content-wrapper) {
    display: flex;
    width: 100%;
    align-items: center;
    gap: 32px;
}

:deep(.info-message) {
    display: flex;
    align-items: flex-start;
    gap: 12px;
    background-color: rgba(0, 190, 58, 0.12);
    border-radius: 16px;
    padding: 16px;
    margin-bottom: 20px;
}

:deep(.info-message.warning) {
    background-color: rgba(249, 175, 17, 0.12);
}

:deep(.info-message.error) {
    background-color: rgba(255, 76, 76, 0.12);
}

:deep(.info-icon) {
    width: 28px;
    height: 28px;
    flex-shrink: 0;
}

:deep(.info-text) {
    flex: 1;
    font-size: 12px;
    color: #131313;
    line-height: 16px;
    margin: 0;
}

:deep(.action-button) {
    background-color: #0db740;
    color: #fff;
    border: none;
    border-radius: 8px;
    font-size: 16px;
    font-weight: 600;
    padding: 16px;
    cursor: pointer;
    width: 100%;
}

:deep(.action-button:disabled) {
    opacity: 0.5;
    cursor: not-allowed;
}

@media (max-width: 991px) {
    .bonus-promo-code {
        padding: 20px;
    }
    .modal-overlay {
             padding: 10px;
             /* Уменьшаем отступы на мобильных */
         }
    .bonus-promo-code {
                      padding: 20px;
                      max-height: calc(100vh - 20px);
                      /* Уменьшаем отступы на мобильных */
                  }

    :deep(.content-wrapper) {
        flex-direction: column;
    }
}
/* Медиа-запрос для очень высоких экранов */
@media (min-height: 800px) {
    .bonus-promo-code {
        min-height: 200px;
        /* Минимальная высота для высоких экранов */
    }
}

/* Медиа-запрос для низких экранов */
@media (max-height: 600px) {
    .bonus-promo-code {
        max-height: calc(100vh - 20px);
        padding: 20px;
    }
}
</style>