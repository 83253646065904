<template>
</template>

<script>
export default {
    name: 'FaviconManager',
    data() {
        return {
            isGG: true 
        }
    },
    created() {
        this.updateFavicon();
    },
    methods: {
        updateFavicon() {
            const faviconUrl = this.isGG
                ? import.meta.env.VITE_GG_FAVICON_URL
                : import.meta.env.VITE_FAVICON_URL;

            let favicon = document.querySelector('link[rel="shortcut icon"]');
            if (favicon) {
                favicon.href = faviconUrl;
            } else {
                favicon = document.createElement('link');
                favicon.rel = 'shortcut icon';
                favicon.href = faviconUrl;
                document.head.appendChild(favicon);
            }

            let svgFavicon = document.querySelector('link[rel="icon"]');
            if (svgFavicon) {
                svgFavicon.href = faviconUrl.replace('.ico', '.svg');
            }
        }
    }
}
</script>