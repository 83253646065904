<template>
    <div class="section-lk">
        <div class="site-width">
            <h1 class="title-section">Личный кабинет</h1>
            <div class="flex-main">
                <div class="sidebar">
                    <ul>
                        <li :class="{ active: $route.name === 'profile-overview' }">
                            <router-link to="/profile">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"
                                    fill="none">
                                    <path
                                        d="M12.6666 14C12.6666 11.4227 10.5772 9.33333 7.99992 9.33333C5.42259 9.33333 3.33325 11.4227 3.33325 14M7.99992 7.33333C6.52716 7.33333 5.33325 6.13943 5.33325 4.66667C5.33325 3.19391 6.52716 2 7.99992 2C9.47268 2 10.6666 3.19391 10.6666 4.66667C10.6666 6.13943 9.47268 7.33333 7.99992 7.33333Z"
                                        stroke="#24FF6F" stroke-width="1.5" stroke-linecap="round"
                                        stroke-linejoin="round" />
                                </svg>
                                Мой профиль
                            </router-link>
                        </li>
                        <li :class="{ active: $route.name === 'profile-referrals' }">
                            <router-link to="/profile/referrals">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"
                                    fill="none">
                                    <path
                                        d="M11.3333 13.3333C11.3333 12.2287 9.84095 11.3333 8 11.3333C6.15905 11.3333 4.66667 12.2287 4.66667 13.3333M14 11.3336C14 10.5134 13.1773 9.8086 12 9.49998M2 11.3336C2 10.5134 2.82273 9.8086 4 9.49998M12 6.82405C12.4092 6.45784 12.6667 5.92565 12.6667 5.33331C12.6667 4.22874 11.7712 3.33331 10.6667 3.33331C10.1544 3.33331 9.68717 3.52588 9.33333 3.84258M4 6.82405C3.59083 6.45784 3.33333 5.92565 3.33333 5.33331C3.33333 4.22874 4.22876 3.33331 5.33333 3.33331C5.84557 3.33331 6.31283 3.52588 6.66667 3.84258M8 9.33331C6.89543 9.33331 6 8.43788 6 7.33331C6 6.22874 6.89543 5.33331 8 5.33331C9.10457 5.33331 10 6.22874 10 7.33331C10 8.43788 9.10457 9.33331 8 9.33331Z"
                                        stroke="#EDEEEE" stroke-opacity="1" stroke-width="1.5" stroke-linecap="round"
                                        stroke-linejoin="round" />
                                </svg>
                                Реферальная программа
                            </router-link>
                        </li>
                        <li :class="{ active: $route.name === 'profile-purchases' }">
                            <router-link to="/profile/purchases">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"
                                    fill="none">
                                    <path
                                        d="M6 5.33335C6 6.43792 6.89543 7.33335 8 7.33335C9.10457 7.33335 10 6.43792 10 5.33335M2 11.2002V4.80015C2 4.05341 2 3.67977 2.14532 3.39455C2.27316 3.14367 2.47698 2.93984 2.72786 2.81201C3.01308 2.66669 3.38673 2.66669 4.13346 2.66669H11.8668C12.6135 2.66669 12.9864 2.66669 13.2716 2.81201C13.5225 2.93984 13.727 3.14367 13.8548 3.39455C14 3.67949 14 4.05268 14 4.79796V11.2024C14 11.9477 14 12.3203 13.8548 12.6053C13.727 12.8562 13.5225 13.0603 13.2716 13.1882C12.9867 13.3334 12.614 13.3334 11.8687 13.3334H4.13127C3.386 13.3334 3.0128 13.3334 2.72786 13.1882C2.47698 13.0603 2.27316 12.8562 2.14532 12.6053C2 12.3201 2 11.9469 2 11.2002Z"
                                        stroke="#EDEEEE" stroke-opacity="1" stroke-width="1.5" stroke-linecap="round"
                                        stroke-linejoin="round" />
                                </svg>
                                Мои покупки
                            </router-link>
                        </li>
                        <li :class="{ active: $route.name === 'profile-bonuses' }">
                            <router-link to="/profile/bonuses">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"
                                    fill="none">
                                    <path
                                        d="M1.55664 6.89118C1.34781 6.69806 1.46125 6.34893 1.74371 6.31544L5.74609 5.84071C5.86122 5.82706 5.96121 5.75477 6.00977 5.6495L7.69792 1.98964C7.81705 1.73135 8.18425 1.7313 8.30339 1.98959L9.99154 5.64942C10.0401 5.75469 10.1394 5.82718 10.2546 5.84083L14.2572 6.31544C14.5396 6.34893 14.6527 6.69816 14.4439 6.89128L11.4852 9.62794C11.4001 9.70665 11.3622 9.8238 11.3848 9.93751L12.17 13.8906C12.2254 14.1696 11.9285 14.3858 11.6803 14.2469L8.16343 12.2777C8.06227 12.2211 7.93938 12.2214 7.83822 12.278L4.32096 14.2464C4.07275 14.3853 3.77529 14.1696 3.83073 13.8906L4.6161 9.93776C4.63869 9.82405 4.60089 9.70662 4.51578 9.62792L1.55664 6.89118Z"
                                        stroke="#EDEEEE" stroke-opacity="1" stroke-width="1.5" stroke-linecap="round"
                                        stroke-linejoin="round" />
                                </svg>
                                SM бонусы и промокоды
                            </router-link>
                        </li>
                        <li>
                            <a href="#" @click.prevent="handleLogout">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"
                                    fill="none">
                                    <path
                                        d="M4.66667 7.33331L2 9.99998M2 9.99998L4.66667 12.6666M2 9.99998H10.6667C12.5076 9.99998 14 8.5076 14 6.66665C14 4.8257 12.5076 3.33331 10.6667 3.33331H7.33333"
                                        stroke="#EDEEEE" stroke-opacity="1" stroke-width="1.5" stroke-linecap="round"
                                        stroke-linejoin="round" />
                                </svg>
                                Выйти
                            </a>
                        </li>
                    </ul>
                </div>

                <div class="container">
                    <router-view v-slot="{ Component }">
                            <component :is="Component" />
                    </router-view>
                </div>

            </div>
        </div>

    </div>
    <ScrollToTop />
</template>

<script>
import { useAuthStore } from '@/store/auth';
import { useRouter, useRoute, } from 'vue-router';
import { onMounted } from 'vue';
import ScrollToTop from '@/components/ScrollTop/ScrollTop.vue';
import { eventBus } from '@/service/eventBus';



export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'Profile',
    components: {
        ScrollToTop
    },
    setup() {
        const authStore = useAuthStore();
        const router = useRouter();
        const route = useRoute();

        onMounted(() => {
            // console.log('Profile mounted, current route:', route.name);
            if (!route.name) {
                router.push({ name: 'profile-overview' });
            }
        });

        const handleLogout = () => {
            authStore.logout();
            router.push('/');
            eventBus.emit('forceUpdateHeader');
            window.location.reload();
            //  this.$nextTick();
        };

        return {
            handleLogout,
            route
        };
    },

    watch: {
        // '$route'(to, from) {
        //     console.log('Route changed:', from.path, '->', to.path);
        //     console.log('Current theme:', document.body.classList.contains('light') ? 'light' : 'dark');
        // }
    },
    errorCaptured(err, vm, info) {
        // console.error('Error in Profile component:', err);
        // console.log('Component:', vm);
        console.log('Error info:', info);
        return false;
    }
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.3s ease;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.3s ease;
}

.fade-enter,
.fade-leave-to {
    opacity: 0;
}
</style>