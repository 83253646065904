<template>
    <div class="section-history tabs">
        <SbpQrPopup :show="sbpPayment.show" :qr="sbpPayment.qr" :qr-link="sbpPayment.qrLink"
            :amount="currentOrder?.amount" :email="currentOrder?.email" :login="currentOrder?.login"
            @close="closeSbpPayment" />

        <div class="head">
            <div class="title">Мои покупки
                <div class="question">
                    <div class="icon"></div>
                    <div class="text">Тут текст</div>
                </div>
            </div>
            <ul class="tabs__caption">
                <li :class="{ active: activeTab === 'purchases' }" @click="activeTab = 'purchases'">История покупок</li>
                <li :class="{ active: activeTab === 'transactions' }" @click="activeTab = 'transactions'">История
                    транзакций</li>
            </ul>
        </div>

        <div v-if="activeTab === 'purchases'" class="tabs__content active">
            <div class="top-selects" v-if="loading">
                <div class="skeleton-animation" style="width: 200px; height: 36px; border-radius: 8px;"></div>

                <ul class="links-tabs">
                    <li class="skeleton-animation" style="width: 120px; height: 36px; margin-right: 8px;"></li>

                    <li v-for="n in 1" :key="n" class="skeleton-animation"
                        style="width: 120px; height: 36px; margin-right: 8px; display: flex; align-items: center;">
                        <div class="skeleton-animation"
                            style="width: 24px; height: 24px; border-radius: 50%; margin-right: 8px;">
                        </div>
                        <div class="skeleton-animation" style="width: 60px; height: 16px;"></div>
                    </li>
                </ul>
            </div>

            <div class="top-selects" v-else>
                <DatePicker v-model="selectedDate" @update:modelValue="handleDateChange" />
                <ul class="links-tabs">
                    <li :class="{ active: !selectedFilter }">
                        <a href="#" @click.prevent="selectFilter(null)">Все игры</a>
                    </li>
                    <li v-for="filter in serviceFilters" :key="filter.id"
                        :class="{ active: selectedFilter === filter.id }">
                        <a href="#" @click.prevent="selectFilter(filter.id)">
                            <img src="/images/profile-steam.svg" alt="">
                            {{ filter.name }}
                        </a>
                    </li>
                </ul>
            </div>

            <div class="mobile-select-container" ref="mobileGamesSelect">
                <div class="select-name-mobile">Выбрать игру</div>
                <div class="select-trigger" @click.stop="toggleMobileMenu">
                    <div class="current-selection">
                        <img :src="steamLogo" alt="Steam">
                        <span>{{selectedFilter ? (serviceFilters.find(f => f.id === selectedFilter)?.name || 'Все игры')
                            : 'Все игры' }}</span>
                    </div>
                    <div class="select-arrow" :class="{ 'rotated': isMobileMenuOpen }">
                        <img src="/images/open-arrow.svg" alt="arrow">
                    </div>
                </div>
                <ul v-if="isMobileMenuOpen" class="select-options">
                    <li class="option-item" :class="{ 'active': !selectedFilter }"
                        @click.stop="handleMobileFilter(null)">
                        <img :src="steamLogo" alt="Steam">
                        <span>Все игры</span>
                    </li>
                    <li v-for="filter in serviceFilters" :key="filter.id" class="option-item"
                        :class="{ 'active': selectedFilter === filter.id }" @click.stop="handleMobileFilter(filter.id)">
                        <img :src="steamLogo" alt="Steam">
                        <span>{{ filter.name }}</span>
                    </li>
                </ul>
            </div>


            <div v-if="loading" class="services-block">
                <div class="clickable-area">
                    <div class="head-title">
                        <div class="title">
                            <div class="skeleton-animation" style="width: 24px; height: 24px;">
                                <img src="/images/profile-steam.svg" alt="" class="skeleton-img">
                            </div>
                            <div class="skeleton-animation" style="width: 60px; height: 24px;"></div>
                        </div>
                        <div class="date skeleton-animation" style="width: 100px; height: 24px;"></div>
                    </div>

                    <div class="skeleton-animation" style="width: 100%; height: 40px;"></div>

                    <div class="info-flex">
                        <div class="col mobile">
                            <div class="name">Дата</div>
                            <div class="info skeleton-animation" style="width: 100px; height: 24px"></div>
                        </div>

                        <div class="col">
                            <div class="name">Логин</div>
                            <div class="info skeleton-animation" style="width: 100%px; height: 24px"></div>
                        </div>

                        <div class="col">
                            <div class="name">Промокод</div>
                            <div class="info skeleton-animation" style="width: 100%; height: 24px"></div>
                        </div>

                        <div class="col">
                            <div class="name">Сумма</div>
                            <div class="info">
                                <div class="new skeleton-animation" style="width: 90px; height: 24px; "></div>
                                <div class="price skeleton-animation" style="width: 90px; height: 24px"></div>
                            </div>
                        </div>

                        <!-- <div class="last col">
                            <div class="name">Баллы</div>
                            <div class="skeleton-animation" style="width: 90px; height: 24px;"></div>
                        </div> -->
                    </div>

                    <button class="pay-skeleton-animation"
                        style="width: 100%; height: 48px; margin-top: 16px;"></button>
                </div>
            </div>

            <div v-else-if="error" class="error-state">
                {{ error }}
            </div>

            <template v-else>
                <div v-if="!orders.length" class="empty-orders">
                    <div class="name">Здесь пока пусто</div>
                    <p>У вас пока нет заказов</p>
                </div>

                <template v-else>
                    <div v-for="order in orders" :key="order.id" class="services-block">
                        <div class="clickable-area" @click="handleOrderClick(order)">
                            <div class="head-title">
                                <div class="title">
                                    <img src="/images/profile-steam.svg" alt="">
                                    Steam
                                </div>
                                <div class="date">{{ formatDate(order.created_at) }}</div>
                            </div>

                            <div class="id" @click.stop>
                                <input :value="order.id" readonly @click.stop>
                                <div class="copy" @click.stop="copyToClipboard(order.id)">
                                    <img class="copy-img" alt="">
                                </div>
                            </div>

                            <div class="info-flex">
                                <div class="col mobile">
                                    <div class="name">Дата</div>
                                    <div class="info">{{ formatDate(order.created_at) }}</div>
                                </div>

                                <div class="col">
                                    <div class="name">Логин</div>
                                    <div class="info">{{ order.login }}</div>
                                </div>

                                <div v-if="order.promo" class="col">
                                    <div class="name">Промокод</div>
                                    <div class="info">{{ order.promo }}</div>
                                </div>

                                <div class="col">
                                    <div class="name">Сумма</div>
                                    <div class="info">
                                        <!-- <div class="new">
                                            {{ formatPrice(order.user_amount) }} -->
                                        <!-- <div class="question-all">
                                                <div class="icon"></div>
                                                <div class="text">Тут текст</div>
                                            </div> -->
                                        <!-- </div> -->
                                        <div class="price">{{ formatPrice(order.amount) }}₽</div>
                                    </div>
                                </div>

                                <div class="last col">
                                    <div class="name">Баллы</div>
                                    <div>
                                        +{{ formatCashBack(order.cashback) }}
                                        <img src="/images/stm-coin.svg" class="theme-icon" alt="Монета" />
                                    </div>
                                </div>
                            </div>

                            <button class="pay" :class="{
                                'succes': order.status === 'success' || order.status === 'canceled',
                                'clickable': order.status === 'success' || order.status === 'canceled'
                            }" @click.stop="handlePayment(order)">
                                {{ formatOrderStatus(order.status) }}
                            </button>
                        </div>
                    </div>
                </template>


                <div class="pagination" v-show="orders.length >= 4">
                    <button class="pay" @click="loadMore" :disabled="loading">
                        {{ loading ? 'Загрузка...' : 'Показать еще' }}
                    </button>
                </div>

            </template>

        </div>

        <div v-else class="tabs__content active">
            <div v-if="loading">
                <div class="tab-transactions" v-for="i in 1" :key="i">
                    <div class="transactions-name skeleton-animation"
                        style="width: 200px; height: 24px; margin-bottom: 12px;">
                    </div>
                    <div class="transactions-bottom">
                        <div class="left">
                            <div class="number skeleton-animation" style="width: 240px; height: 20px;"></div>
                        </div>
                        <div class="transactions-date skeleton-animation" style="width: 100px; height: 20px;"></div>
                        <div class="transactions-price skeleton-animation"
                            style="width: 120px; height: 20px; display: flex; align-items: center;">
                            <div class="skeleton-animation" style="width: 80px; height: 20px; margin-right: 8px;"></div>
                            <div class="skeleton-animation" style="width: 24px; height: 24px; border-radius: 50%;">
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div v-else-if="error" class="error-state">
                {{ error }}
            </div>

            <div v-else>
                <div v-if="!transactions.length" class="empty-orders">
                    <div class="name">Здесь пока пусто
                        <br> У вас пока нет заказов
                    </div>
                </div>

                <template v-else>
                    <div v-for="transaction in transactions" :key="transaction.id" class="tab-transactions">
                        <div class="transactions-name">{{ transaction.type }}</div>
                        <div class="transactions-bottom">
                            <div class="left">
                                <div v-if="transaction.subject" class="number">{{ transaction.subject }}</div>
                            </div>
                            <div class="transactions-date">{{ formatDate(transaction.created_at) }}</div>
                            <div class="transactions-price"
                                :class="{ 'negative': transaction.bonus_type === 'subtraction' }">
                                {{ transaction.bonus_type === 'addition' ? '+' : '-' }}{{ Math.abs(transaction.amount)
                                }}
                                <img class="transactions-coin theme-icon"
                                    :src="transaction.bonus_type === 'subtraction' ? '/images/stm-coin-pink.svg' : '/images/stm-coin.svg'"
                                    :alt="transaction.bonus_type === 'subtraction' ? 'Списание' : 'Начисление'">
                            </div>
                        </div>
                    </div>
                    <div v-for="transaction in transactions" :key="transaction.id"
                        class="services-block tab-transactions-mobile">
                        <div class="transactions-name">{{ transaction.type }}</div>
                        <div class="info-flex">
                            <div class="left">
                                <div v-if="transaction.subject" class="number">{{ transaction.subject }}</div>
                            </div>
                            <div class="col mobile">
                                <div class="name">Дата</div>
                                <div class="info">{{ formatDate(transaction.created_at) }}</div>
                            </div>
                            <div v-if="transaction.promo" class="col">
                                <div class="name">Промокод</div>
                                <div class="info">{{ transaction.promo }}</div>
                            </div>

                            <div class="col">
                                <div class="name">Сумма</div>
                                <div class="info">
                                    <div class="price">{{ formatPrice(transaction.amount) }}₽</div>
                                </div>
                            </div>

                            <div class="last col">
                                <div class="name">Баллы</div>
                                <div class="transactions-price"
                                    :class="{ 'negative': transaction.bonus_type === 'subtraction' }">
                                    {{ transaction.bonus_type === 'addition' ? '+' : '-' }}{{
                                    Math.abs(transaction.amount)
                                    }}
                                    <img class="transactions-coin theme-icon"
                                        :src="transaction.bonus_type === 'subtraction' ? '/images/stm-coin-pink.svg' : '/images/stm-coin.svg'"
                                        :alt="transaction.bonus_type === 'subtraction' ? 'Списание' : 'Начисление'">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="pagination" v-if="transactions.length >= 4">
                        <button class="pay" @click="loadMoreTransactions" :disabled="transactionsLoading">
                            {{ transactionsLoading ? 'Загрузка...' : 'Показать еще' }}
                        </button>
                    </div>
                </template>
            </div>
        </div>
    </div>

</template>

<script>
import OrdersService from '@/service/api/orders';
import DatePicker from '@/components/DatePicker/DatePicker.vue';
import SbpQrPopup from '@/components/PaymentForm/sbp/SBPComponent.vue';

export default {
    name: 'ProfilePurchases',
    components: {
        DatePicker,
        SbpQrPopup
    },

    computed: {
        steamLogo() {
            return this.updateSteamLogo();
        },
        OrdersService() {
            return OrdersService;
        }
    },

    data() {
        return {
            serviceFilters: [],
            selectedFilter: null,
            // transactions: [
            //     {
            //         id: "6787a1e4-af6b-3d51-b00d-f465",
            //         type: "Кэшбек за заказ",
            //         bonus_type: "addition",
            //         subject: "Заказ #12345",
            //         amount: 50,
            //         created_at: "2024-02-20T15:30:00"
            //     },
            //     {
            //         id: "6787a15f-bc2d-37ee-f20d-1803",
            //         type: "Генерация промокода",
            //         bonus_type: "subtraction",
            //         subject: "Промокод WINTER24",
            //         amount: 100,
            //         created_at: "2024-02-19T12:45:00"
            //     },
            //     {
            //         id: "67878f54-af6b-3d51-b00d-f463",
            //         type: "Бонус за подписку VK",
            //         bonus_type: "addition",
            //         subject: "Социальная сеть VK",
            //         amount: 40,
            //         created_at: "2024-02-18T09:20:00"
            //     },
            //     {
            //         id: "67878f54-af6b-3d51-b00d-f464",
            //         type: "Бонус за подписку Telegram",
            //         bonus_type: "addition",
            //         subject: "Социальная сеть Telegram",
            //         amount: 40,
            //         created_at: "2024-02-17T14:15:00"
            //     },
            //     {
            //         id: "67878f54-af6b-3d51-b00d-f465",
            //         type: "Реферальное начисление",
            //         bonus_type: "addition",
            //         subject: "Заказ реферала #54321",
            //         amount: 100,
            //         created_at: "2024-02-16T11:30:00"
            //     },
            //     {
            //         id: "67878f54-af6b-3d51-b00d-f466",
            //         type: "Использование промокода",
            //         bonus_type: "subtraction",
            //         subject: "Промокод SPRING24",
            //         amount: 200,
            //         created_at: "2024-02-15T16:45:00"
            //     }
            // ],
            // orders: [
            //     {
            //         id: '7c6cbda1-a8ed-49bd-8c0e-32bbf939332f',
            //         status: 'pending',
            //         created_at: '2024-03-26T10:30:00',
            //         login: 'testuser123',
            //         promo: 'WELCOME10',
            //         user_amount: 1000,
            //         amount: 1100,
            //         cashback: 50,
            //         pay_method: 'sbp',
            //         pay_url: 'https://test.pay.url'
            //     },
            //     {
            //         id: 'abc123-test-order-id',
            //         status: 'success',
            //         created_at: '2024-03-25T15:20:00',
            //         login: 'newbalancem5@gmail.com',
            //         user_amount: 2000,
            //         amount: 2200,
            //         cashback: 100
            //     },
            //     {
            //         id: 'xyz789-failed-order',
            //         status: 'error',
            //         created_at: '2024-03-24T09:15:00',
            //         login: 'gamer789',
            //         promo: 'BONUS20',
            //         user_amount: 500,
            //         amount: 550,
            //         cashback: 25
            //     },
            //     {
            //         id: 'xyz789-failed-order',
            //         status: 'canceled',
            //         created_at: '2024-03-24T09:15:00',
            //         login: 'gamer789',
            //         promo: 'BONUS20',
            //         user_amount: 500,
            //         amount: 550,
            //         cashback: 25
            //     }
            // ],
            transaction: [],
            activeTab: 'purchases',
            orders: [],
            loading: false,
            error: null,
            selectedDate: null,
            currentPage: 0,
            hasMore: true,
            sbpPayment: {
                show: false,
                qrLink: null,
                qr: null,
            },
            currentOrder: null,
            transactions: [],
            transactionsPage: 0,
            hasMoreTransactions: true,
            transactionsLoading: false,
            isMobileMenuOpen: false,
        };
    },

    async mounted() {
        this.loading = true;

        await this.loadServiceFilters();
        await this.loadOrders();
        if (this.activeTab === 'transactions') {
            await this.loadTransactions();
        }
        document.addEventListener('click', this.handleOutsideClick);

    },
    beforeUnmount() {
        document.removeEventListener('click', this.handleOutsideClick);
    },


    watch: {
        async activeTab(newTab) {
            this.error = null;
            if (newTab === 'transactions' && this.transactions.length === 0) {
                await this.loadTransactions();
            }
            if (newTab === 'purchases' && this.orders.length === 0) {
                await this.loadOrders();
            }
            if (newTab === 'transactions') {
                this.transactionsPage = 0;
                this.hasMoreTransactions = true;
                await this.loadTransactions();
            }
        }
    },

    methods: {
        updateSteamLogo() {
            return document.body.classList.contains('light')
                ? '/images/steam-mobile-menu-logo.svg'
                : '/images/profile-steam.svg'
        },
        handleOutsideClick(event) {
            if (this.$refs.mobileGamesSelect &&
                !this.$refs.mobileGamesSelect.contains(event.target)) {
                this.isMobileMenuOpen = false;
            }
        },
        toggleMobileMenu(event) {
            event.stopPropagation();
            this.isMobileMenuOpen = !this.isMobileMenuOpen;
        },

        handleMobileFilter(filterId) {
            this.selectFilter(filterId);
            this.isMobileMenuOpen = false;
        },
            async loadMoreTransactions() {
                if (this.transactionsLoading) return;

                this.transactionsLoading = true;
                try {
                    const response = await OrdersService.getTransactions(this.transactionsPage + 1);

                    if (response && response.list?.length) {
                        this.transactionsPage++;
                        this.transactions = [...this.transactions, ...response.list];

                        this.hasMoreTransactions = this.transactionsPage < (response.total_pages - 1);
                    } else {
                        this.hasMoreTransactions = false;
                    }
                } catch (error) {
                    console.error('Ошибка загрузки дополнительных транзакций:', error);
                    this.error = 'Не удалось загрузить дополнительные транзакции';
                    this.hasMoreTransactions = false;
                } finally {
                    this.transactionsLoading = false;
                }
            }
,
        handleOrderClick(order) {
            const activeElement = document.activeElement;
            if (activeElement && (activeElement.tagName === 'INPUT' || activeElement.classList.contains('copy'))) {
                return;
            }
            const orderData = {
                id: order.id,
                status: order.status,
                created_at: order.created_at,
                login: order.login,
                user_amount: order.user_amount,
                amount: order.amount,
                cashback: order.cashback,
                pay_method: order.pay_method,
                pay_url: order.pay_url,
                service_id: order.service_id,
                type: order.type,
            };

            this.$router.push({
                name: 'order-details',
                params: { id: order.id },
                query: { data: JSON.stringify(orderData) }
            });
        },
        changePage(delta) {
            const newPage = this.currentPage + delta;
            if (newPage >= 0 && newPage < this.totalPages) {
                this.currentPage = newPage;
                this.loadOrders();
            }
        },
        goToPage(page) {
            if (page >= 0 && page < this.totalPages) {
                this.currentPage = page;
                this.loadOrders();
            }
        }
        ,
        handlePayment(order) {
            if (order.status === 'success' || order.status === 'canceled') {
                const orderData = {
                    id: order.id,
                    status: order.status,
                    created_at: order.created_at,
                    login: order.login,
                    user_amount: order.user_amount,
                    amount: order.amount,
                    cashback: order.cashback,
                    pay_method: order.pay_method,
                    pay_url: order.pay_url
                };

                this.$router.push({
                    name: 'order-details',
                    params: { id: order.id },
                    query: { data: JSON.stringify(orderData) }
                });
                return;
            }

            if (order.pay_method === 'sbp') {
                this.currentOrder = order;
                this.sbpPayment.show = true;
                this.sbpPayment.qrLink = order.pay_url;
                this.sbpPayment.orderId = order.id;
                this.sbpPayment.qr = order.qr;
            } else if (order.pay_url) {
                window.location.href = order.pay_url;
            }
        },

        closeSbpPayment() {
            this.sbpPayment.show = false;
            this.currentOrder = null;
        },

        handleDateChange(selectedDate) {
            this.selectedDate = selectedDate;
            this.filterOrdersByDate();
        },

        async filterOrdersByDate() {
            this.loading = true;
            try {
                const orders = await OrdersService.getOrders();

                if (this.selectedDate) {
                    this.orders = orders.filter(order => {
                        const orderDate = new Date(order.created_at);
                        const selectedDateObj = new Date(this.selectedDate);
                        return orderDate.toDateString() === selectedDateObj.toDateString();
                    });
                } else {
                    this.orders = orders;
                }

                this.error = null;
            } catch (error) {
                console.error('Ошибка при фильтрации заказов:', error);
                this.error = 'Не удалось отфильтровать список заказов';
                this.orders = [];
            } finally {
                this.loading = false;
            }
        },

        async loadOrders() {
            this.loading = true;
            try {
                const response = await OrdersService.getOrders(this.currentPage, this.selectedFilter);

                if (!response.list?.length) {
                    this.hasMore = false;
                    this.orders = [];
                    return;
                }

                if (this.currentPage === 0) {
                    this.orders = response.list;
                }

                this.totalPages = response.total_pages || 1;
                this.hasMore = true;
                this.error = null;
            } catch (error) {
                console.error('Ошибка загрузки заказов:', error);
                this.error = 'Не удалось загрузить список заказов';
                this.hasMore = false;
            } finally {
                this.loading = false;
            }
        },

        async loadServiceFilters() {
            try {
                this.serviceFilters = await OrdersService.getServiceFilters();
            } catch (error) {
                console.error('Ошибка загрузки фильтров:', error);
            }
        },

        async selectFilter(filterId) {
            this.loading = true;
            try {
                this.selectedFilter = filterId;
                this.currentPage = 0;
                await this.loadOrders();
            } catch (error) {
                console.error('Ошибка при применении фильтра:', error);
                this.error = 'Не удалось применить фильтр';
                this.orders = []; 
            } finally {
                this.loading = false;
            }
        },

        async loadMore() {
            if (this.loading || !this.hasMore) return;

            this.loading = true;
            try {
                this.currentPage++;
                const response = await OrdersService.getOrders(this.currentPage, this.selectedFilter);

                if (!response.list?.length) {
                    this.hasMore = false;
                    return;
                }

                this.orders = [...this.orders, ...response.list];

                this.hasMore = response.list.length > 0 && this.currentPage < (response.total_pages - 1);

            } catch (error) {
                console.error('Ошибка загрузки заказов:', error);
                this.error = 'Не удалось загрузить дополнительные заказы';
                this.hasMore = false;
            } finally {
                this.loading = false;
            }
        },

        async loadTransactions() {
            this.loading = true;
            try {
                const response = await OrdersService.getTransactions(0);

                if (response && response.list) {
                    this.transactions = response.list;
                    this.hasMoreTransactions = response.total_pages > 1;
                    this.error = null;
                }
            } catch (error) {
                console.error('Error loading transactions:', error);
                this.error = 'Не удалось загрузить историю транзакций';
                this.transactions = [];
                this.hasMoreTransactions = false;
            } finally {
                this.loading = false;
            }
        },

        formatDate(date) {
            if (!date) return '';
            return OrdersService.formatDate(date);
        },

        formatPrice(price) {
            if (!price) return '0';
            return OrdersService.formatPrice(price);
        },

        formatCashBack(cashback) {
            if (!cashback) return '0';
            return String(cashback).replace(/\*/g, '');
        },

        formatOrderStatus(status) {
            return OrdersService.formatOrderStatus(status);
        },

        async copyToClipboard(text) {
            try {
                await navigator.clipboard.writeText(text);
            } catch (err) {
                console.error('Ошибка копирования:', err);
            }
        }
    }
};
</script>

<style>
.select-trigger{
    display: none;
}


/* Мобильные стили */
@media (max-width: 768px) {
    .select-options {
        max-height: 300px;
        overflow-y: auto;
    }
        .mobile-select-container {
            position: relative;
            width: 100%;
            margin-bottom: 16px;
        }
    
        .select-name-mobile {
            color: var(--Text-Secondary, rgba(237, 238, 238, 0.48));
            font-size: 14px;
            /* margin-bottom: 8px; */
        }
    
        .select-trigger {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 12px 16px;
            background: #FFFFFF;
            border-radius: 8px;
            cursor: pointer;
            transition: background 0.2s ease;
            background: var(--Surface-Primary-Hover, rgba(237, 238, 238, 0.08));
        }
    
    
        .current-selection {
            display: flex;
            align-items: center;
            gap: 12px;
        }
    
        .current-selection img {
            width: 24px;
            height: 24px;
        }
    
        .select-arrow {
            transition: transform 0.2s ease;
        }
    
        .select-arrow.rotated {
            transform: rotate(180deg);
        }
    
        .select-options {
            list-style: none;
            padding: 0;
            margin: 0;
            position: absolute;
            top: calc(100% + 4px);
            left: 0;
            right: 0;
            background: #242424;
            border-radius: 8px;
            overflow: hidden;
            z-index: 10;
        }
    
        .option-item {
            display: flex;
            align-items: center;
            gap: 12px;
            padding: 12px 16px;
            cursor: pointer;
            transition: background 0.2s ease;
            /* background: transparent; */
        }
    
        .option-item img {
            width: 24px;
            height: 24px;
        }
    
        .option-item:hover,
        .option-item.active {
            background: var(--Surface-Primary-Hover, rgba(237, 238, 238, 0.08));
        }
    
        /* Анимация появления/исчезновения */
        .fade-enter-active,
        .fade-leave-active {
            transition: opacity 0.2s ease, transform 0.2s ease;
        }
    
        .fade-enter-from,
        .fade-leave-to {
            opacity: 0;
            transform: translateY(-10px);
        }
    
        /* Светлая тема */
        body.light .select-trigger {
            background: var(--Surface-Primary-Default, rgba(19, 19, 19, 0.06));
        }
    
        body.light .select-options {
            background: var(--Surface, #FFFFFF);
            box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
        }
    
        body.light .option-item:hover,
        body.light .option-item.active {
            background: var(--Surface-Primary-Hover, rgba(19, 19, 19, 0.08));
        }
}
.transactions-loading {
    opacity: 0.7;
    pointer-events: none;
}

.transactions-price.negative {
    color: #ff5e5e;
}

body.light .transactions-price.negative {
    color: #ff5e5e;
}

.pagination {
    margin-top: 20px;
    display: flex;
    justify-content: center;
}

.pagination .pay {
    font-weight: 400;
    width: 100%;
    /* или другое подходящее значение */
    height: 48px;
    border-radius: 8px;
    background: var(--Surface-Primary-Default, rgba(237, 238, 238, 0.04));
    border: none;
    color: var(--Text-Primary, #EDEEEE);
    font-size: 14px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.pagination .pay:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.pagination .pay:hover:not(:disabled) {
    background: var(--Surface-Primary-Hover, rgba(237, 238, 238, 0.08));
}

body.light .pagination .pay {
    background: var(--Surface-Primary-Default, rgba(19, 19, 19, 0.06));
    color: var(--Text-Primary, #131313);
}

body.light .pagination .pay:hover:not(:disabled) {
    background: var(--Surface-Accent-Hover, rgba(0, 190, 58, 0.12)) !important;
}

.select-mobile-dropdown {
    position: relative;
    width: 100%;
    background: var(--Surface-Primary-Default, rgba(237, 238, 238, 0.04));
    border-radius: 8px;
    margin-top: 4px;
    z-index: 100;
}

.select-mobile-option {
    padding: 12px 16px;
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
}

.select-mobile-option.active {}

.select-mobile-option img {
    width: 20px;
    height: 20px;
}

body.light .select-mobile-dropdown {
    background: var(--Surface-Primary-Default, rgba(19, 19, 19, 0.06));
}

body.light .select-mobile-option.active {
    background: var(--Surface-Primary-Hover, rgba(19, 19, 19, 0.08));
}

.pay.clickable {
    cursor: pointer !important;
    opacity: 1 !important;
}

.pay.clickable:hover {
    background: var(--Surface-Primary-Hover, rgba(237, 238, 238, 0.08));
}

body.light .pay.clickable:hover {
    background: var(--Surface-Accent-Hover, rgba(0, 190, 58, 0.12));
}


.links-tabs {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    align-items: center;
}

.skeleton-animation {
    position: relative;
    overflow: hidden;
    background: rgba(255, 255, 255, 0.04);
    border-radius: 8px;
}

.skeleton-animation::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background: linear-gradient(90deg,
            transparent 0,
            rgba(255, 255, 255, 0.1) 20%,
            rgba(255, 255, 255, 0.2) 60%,
            transparent);
    animation: shimmer 2s infinite;
}

body.light .skeleton-animation {
    background: rgba(19, 19, 19, 0.04);
}

body.light .skeleton-animation::after {
    background: linear-gradient(90deg,
            transparent 0,
            rgba(19, 19, 19, 0.08) 20%,
            rgba(19, 19, 19, 0.12) 60%,
            transparent);
}

@keyframes shimmer {
    100% {
        transform: translateX(100%);
    }
}

@media (max-width: 768px) {
    .top-selects {
        gap: 12px;
    }

    .links-tabs {
        flex-wrap: nowrap;
        overflow-x: auto;
        padding-bottom: 8px;
        -webkit-overflow-scrolling: touch;
    }
}
</style>