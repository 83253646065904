<template>
    <svg class="loading-indicator" width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"
        style="margin-right: 8px">
        <path
            d="M20 10C20 11.9778 19.4135 13.9112 18.3147 15.5557C17.2159 17.2002 15.6541 18.4819 13.8268 19.2388C11.9996 19.9957 9.98891 20.1937 8.0491 19.8079C6.10929 19.422 4.32746 18.4696 2.92893 17.0711C1.53041 15.6725 0.577999 13.8907 0.192147 11.9509C-0.193705 10.0111 0.00432836 8.00043 0.761205 6.17317C1.51808 4.3459 2.79981 2.78412 4.4443 1.6853C6.08879 0.58649 8.02219 -2.35852e-08 10 0V3.46442C8.70738 3.46442 7.4438 3.84772 6.36903 4.56586C5.29425 5.284 4.45657 6.30472 3.96191 7.49894C3.46725 8.69316 3.33782 10.0072 3.59 11.275C3.84217 12.5428 4.46463 13.7073 5.37865 14.6214C6.29266 15.5354 7.45719 16.1578 8.72497 16.41C9.99275 16.6622 11.3068 16.5328 12.5011 16.0381C13.6953 15.5434 14.716 14.7057 15.4341 13.631C16.1523 12.5562 16.5356 11.2926 16.5356 10H20Z"
            :fill="customFill || 'url(#paint0_angular_769_9854)'" />
        <defs>
            <radialGradient id="paint0_angular_769_9854" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                gradientTransform="translate(10 10) rotate(-90) scale(11.4604)">
                <stop offset="0.245" stop-color="#242424" stop-opacity="0" />
                <stop offset="1" stop-color="#24FF6F" />
            </radialGradient>
        </defs>
    </svg>
</template>

<script>
export default {
    name: 'LoadingIndicator',
    props: {
        color: {
            type: String,
            default: null
        }
    },
    computed: {
        customFill() {
            return this.color ? this.color : null;
        }
    }
}
</script>

<style scoped>
.loading-indicator {
    animation: spin 2s linear infinite;
    margin-right: 8px;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}
</style>