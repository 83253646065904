import axios from 'axios';

export const redirectAPI = {
    async checkRedirect(path) {
        try {
            const response = await axios.get('/redirect', {
                params: { path }
            });

            return response.data;
        } catch (error) {
            if (error.response?.status === 404) {
                return { status: false };
            }

            throw error;
        }
    }
};