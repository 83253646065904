import axios from 'axios';

export const promoApi = {
    async applyBonusPromo(code) {
        try {
            const response = await axios.post('/promo/bonus/apply', { code });
            return response.data;
        } catch (error) {
            throw error.response?.data?.message || 'Промокод не действителен или его не существует';
        }
    }
};