const getConfig = () => {
    const currentOrigin = window.location.origin;
    const isGGSite = currentOrigin === import.meta.env.VITE_GG_URL;

    return {
        apiUrl: isGGSite ? import.meta.env.VITE_GG_API_URL : import.meta.env.VITE_API_URL,

        vkClientId: isGGSite ? import.meta.env.VITE_GG_VK_CLIENT_ID : import.meta.env.VITE_VK_CLIENT_ID,

        baseUrl: currentOrigin,

        isGGSite: isGGSite,

        tgBotId: isGGSite ? import.meta.env.VITE_GG_TG_BOT_ID : import.meta.env.VITE_TG_BOT_ID,

        socialLinks: {
            vk: isGGSite ? import.meta.env.VITE_SOCIAL_GG_VK_URL : import.meta.env.VITE_SOCIAL_VK_URL,
            telegram: isGGSite ? import.meta.env.VITE_SOCIAL_GG_TG_URL : import.meta.env.VITE_SOCIAL_TG_URL,
        },

        socialMobileLinks: {
            vk: isGGSite ? import.meta.env.VITE_SOCIAL_GG_VK_MOBILE_URL : import.meta.env.VITE_SOCIAL_VK_MOBILE_URL,
            telegram: isGGSite ? import.meta.env.VITE_SOCIAL_GG_TG_MOBILE_URL : import.meta.env.VITE_SOCIAL_TG_MOBILE_URL,
        },
        
        socialBotLinks: {
            telegram: isGGSite ? import.meta.env.VITE_SOCIAL_GG_TG_BOT_URL : import.meta.env.VITE_SOCIAL_TG_BOT_URL
        },
    };
};

export const siteConfig = getConfig();